@import "../../../../scss/mixins.scss";

.route__admin__globalIntegrationsJP {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 10px;

    &__tabs {
        width: 100%;
        height: auto;
        margin-bottom: 20px;

        display: flex;
        align-items: center;
        gap: 20px;

        &__tab {
            padding: 10px 18px;

            color: #808191;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            border: 1px solid transparent;
            border-radius: 5px;

            transition: border-color 0.3s ease, background-color 0.3s ease, color 0.3s ease;
            user-select: none;
            cursor: pointer;

            &:hover {
                border-color: #6C5DD3;
            }
            &--active {
                background-color: #6C5DD3;
                border-color: #6C5DD3;
                color: #fff;
            }
        }
    }
    &__profile {
        width: 100%;
        height: auto;

        &__kpi {
            width: 100%;
            height: 120px;
            margin-bottom: 30px;
    
            display: grid;
            grid-template-rows: 1fr;
            grid-template-columns: repeat(5, 1fr);
    
            &__item {
                width: 100%;
                height: 100%;
    
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
    
                border: 1px solid #373A43;
    
                padding: 25px 40px;
                padding-right: 20px;

                body.light & {
                    border-color: #E2E2E2;
                }
                @media screen and (max-width: 1300px) {
                    padding: 25px 15px;
                    
                }
                &__top {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
    
                    &__left {
                        display: flex;
                        align-items: center;
                        gap: 15px;
    
                        & > img {
                            width: 20px;
                            height: 20px;
                        }
                        & > p {
        
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 16px;
                            
                            color: #FFFFFF;

                            body.light & {
                                color: black;
                            }
                        }
                    }
                }
                &__bottom {
                    width: 100%;
                    height: auto;
    
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
    
                    &__left {
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 28px;
                        line-height: 42px;
                        letter-spacing: 1px;
                        
                        color: #FFFFFF;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 10px;

                        body.light & {
                            color: black;
                        }
                        &>img{
                            width: 20px;
    
                        }
                        &>span{
                            font-size: 20px;
                        }
                        @media screen and (max-width:1300px) {
                            font-size: 25px;
                        }
                    }
                    &__right {
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 28px;
                        line-height: 42px;
                        letter-spacing: 1px;
                        
                        color: #FFFFFF;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 10px;

                        body.light & {
                            color: black;
                        }
                        &>img{
                            width: 20px;
    
                        }
                        &>span{
                            font-size: 20px;
                        }
                        @media screen and (max-width:1300px) {
                            font-size: 25px;
                        }
                    }
                }
            }
        }
    }
    &__services {
        width: 100%;
        height: auto;

        &__buttons {
            width: auto;
            height: auto;
            margin-bottom: 20px;
    
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 30px;
        }
        &__add {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: default;
            background-color: rgba(35, 38, 48, 0.7);

            &__wrap {
                width: 500px;
                height: auto;
                max-height: 100%;
                overflow: auto;
                position: relative;
                padding: 40px;
                color: #fff;
                background-color: #2C2E38;

                body.light & {
                    background-color: #fff;
                    color: black;
                }
                &__head {
                    width: 100%;
                    height: auto;
                    margin-bottom: 30px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    &__left {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 22px;
                        letter-spacing: 1px;
                        color: #FFFFFF;

                        body.light & {
                            color: black;
                        }
                    }
                    &__right {
                        width: 30px;
                        height: 30px;
                        
                        cursor: pointer;
                        @include button-hover-animation(34px);

                        body.light & {
                            filter: invert(1) hue-rotate(180deg);
                        }
                    }
                }
                &__input {
                    width: 100%;
                    height: auto;
                    margin-bottom: 20px;
    
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
    
                    & > p {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                        
                        color: #FFFFFF;
                    }
                    & > input  {
                        width: 100%;
                        height: 54px;
                        padding: 0 20px;
    
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 19px;
    
                        outline: none;
                        color: white;
                        background: #373A43;
                        border-radius: 5px;
                        border: 1px solid transparent;
    
                        transition: border 0.3s ease;
    
                        &:focus {
                            border: 1px solid #6C5DD3;
                        }
                    }
                    & .component__customComponent__dropbox {
                        border: 1px solid transparent;
                        transition: border 0.3s ease;
                    }
                    &--error > input, &--error > textarea, &--error .component__customComponent__dropbox {
                        border: 1px solid #FF754C;
                    }
                    &__dropItem {
                        width: 100%;
                        height: auto;
    
                        display: flex;
                        align-items: center;
                        gap: 10px;
    
                        & > img {
                            width: auto;
                            height: auto;
                            max-height: 48px;
                            max-width: 48px;
                        }
                        &__risks {
                            width: auto !important;
                            display: flex;
                            align-items: center;
                            gap: 5px;
                            width: 100%;
                            height: 2px;
    
                            &__risk {
                                width: 20px;
                                height: 100%;
                                border-radius: 999px;
                            }
                        }
                    }
                }
                &__buttons {
                    width: 100%;
                    height: auto;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    gap: 20px;
    
                    &__btn {
                        width: auto;
                        height: 43px;
                        padding: 0 40px;
    
                        display: flex;
                        justify-content: center;
                        align-items: center;
    
                        color: #fff;
                        background-color: $button-primary;
                        border-radius: 5px;
                        cursor: pointer;
    
                        transition: background-color 0.3s ease;
    
                        &:hover {
                            background-color: $button-primary-hover;
                        }
                        &--secondary {
                            background-color: $button-secondary;
    
                            &:hover {
                                background-color: $button-secondary-hover;
                            }
                        }
                    }
                }
                &__infoP {
                    margin-top: 20px;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #FF450D;
                    opacity: 0;
                    transition: opacity 0.3s ease;
                }
            }
        }
    }
    &__orders {
        width: 100%;
        height: auto;

        .customComponents__table__data__groupNewline > p {
            width: 100% !important;
        }
        .customComponents__table__data {
            overflow: hidden;
        }
        &__pills {
            width: 100%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;

            & > strong {
                font-weight: normal;
                color: white;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;

                body.light & {
                    color: black;
                }
            }
            & > span {
                align-items: center;
                color: white;
                background-color: #434295;
                border-radius: 5px;
                display: flex;
                font-size: 12px;
                height: 20px;
                justify-content: center;
                padding: 10px;
                width: auto;
            }
        }
    }
}