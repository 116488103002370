@import "../../scss/mixins.scss";

.component__contentWrapper {
    width: 100%;
    height: 100%;
    overflow: auto;

    &.cloned-element {
        display: flex !important;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: $breakpoint-medium) {
    .component__contentWrapper {
        padding-left: 0px;
    }
}