@import "../../../scss/mixins.scss";

.route__user__campaigns {
    width: 100%;
    min-height: 100%;
    height: auto;
    position: relative;

    display: grid;
    grid-template-columns: 1fr 40px;
    grid-template-rows: auto;
    column-gap: 20px;

    &__notice {
        margin: 15px 0;
        margin-bottom: 0px;
        padding: 10px;
        border-radius: 5px;

        display: flex;
        align-items: center;
        background-color: #373351;

        body.light & {
            background-color: #c9c4e4;
        }
        & > p {
            color: white;
            font-family: Inter;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;

            body.light & {
                color: black;
            }
        }
    }
    &__sidebar {
        position: sticky;
        top: 0;
        right: 0;
        width: 48px;
        height: calc(100vh - 200px);
        margin-top: 73px;
        padding: 5px 0;

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 6px;

        background-color: #232630;
        border: 1px solid #373A43;
        z-index: 49;

        body.light & {
            background-color: #fff;
            border-color: #E2E2E2;
        }
        &__item {
            width: 40px;
            height: 40px;
            border: 1px solid transparent;
            position: relative;
            overflow: visible;

            display: flex;
            justify-content: center;
            align-items: center;

            border-radius: 5px;

            cursor: not-allowed;
            pointer-events: none;
            transition: border 0.3s ease, background-color 0.3s ease;

            &:hover {
                background-color: #0D0F11 !important;

                body.light & {
                    background-color: #F5F5F5 !important;
                }
            }
            & > img {
                width: 18px;
                height: 18px;

                body.light & {
                    filter: invert(1) hue-rotate(180deg);
                }
            }
            &--active {
                background-color: #1B1D21;
                cursor: pointer;
                pointer-events: all;

                body.light & {
                    background-color: #E4E4E4;
                }
            }
            &--toggle {
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0px;
                    left: 50%;
                    width: 0%;
                    height: 2px;
                    transform: translateX(-50%);

                    background-color: $button-primary;
                    transition: width 0.3s ease;
                }
                &:hover::after {
                    width: 50%;
                }
            }
            &--toggleActive {
                &::after {
                    width: 100% !important;
                }
            }
            & .customComponents__buttonWithDropdown {
                position: absolute;
                top: -100%;
                left: -100%;
                background-color: transparent;
                z-index: 5;
            }
            &--enabled {
                
                body.light & {
                    background-color: #6C5DD3 !important;

                    & img {
                        filter: none;
                    }
                }
                &:hover {
                    background-color: #6C5DD3 !important;

                    body.light & {
                        background-color: #6C5DD3 !important;
                        
                        & img {
                            filter: none;
                        }
                    }
                }
            }
        }
        &--active &__item {
            background-color: #1B1D21;
            cursor: pointer;
            pointer-events: all;

            body.light & {
                background-color: #e4e4e4;
            }
        }
    }
    &__content{
        width: 100%;
        height: 100%;
        overflow: auto;

        &__filters {
            display: grid;
            grid-template-columns: 2fr 1fr 1fr;
            grid-template-rows: auto;
            grid-column-gap: 16px;
            grid-row-gap: 20px;

            &__pills {
                grid-row: 2;
                grid-column: 1 / span all;

                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                flex-wrap: wrap;

                & > p {
                    padding: 5px 10px;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16.94px;
                    text-align: left;
                    
                    color: white;
                    background-color: $button-primary;
                    border-radius: 5px;
                    cursor: pointer;
                    transition: background-color 0.3s ease;

                    & > span {
                        padding: 0 5px;
                    }
                    & > img {
                        width: 13px;
                        height: 13px;
                    }
                    &:hover {
                        background-color: $button-primary-hover;
                    }
                }
            }
        }
        & .component__filters__filterColumn, & .route__user__campaigns__content__dropdown {
            position: fixed;
            top: -200%;
            left: 0;
            width: 0;
            height: 0;
        }
    }
    &__filters {
        position: sticky;
        top: 0;
        background-color: #232630;
        width: 100%;
        height: 94px;
        display: grid;
        grid-template-columns: 3fr 2fr 3fr;
        grid-template-rows: 100%;
        grid-column-gap: 30px;
        column-gap: 30px;
        padding: 20px 0;
        z-index: 21;

        &--extended {
            grid-template-columns: 2fr 2fr 2fr 2fr;
        }
    }
    &__kpi {
        width: 100%;
        min-height: 120px;

        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: repeat(5, 1fr);

        &__item {
            width: 100%;
            height: 100%;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;

            border: 1px solid #373A43;

            padding: 25px 40px;
            padding-right: 20px;
            @media screen and (max-width: 1300px) {
                padding: 25px 15px;
                
            }

            body.light & {
                border-color: #adadad;
            }
            &__top {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                &__left {
                    display: flex;
                    align-items: center;
                    gap: 15px;

                    & > img {
                        width: 20px;
                        height: 20px;
                    }
                    & > p {
    
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 16px;
                        
                        color: #FFFFFF;

                        body.light & {
                            color: black;
                        }
                    }
                }
            }
            &__bottom {
                width: 100%;
                height: auto;

                display: flex;
                justify-content: space-between;
                align-items: center;

                &__left {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 28px;
                    line-height: 42px;
                    letter-spacing: 1px;
                    
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 10px;

                    &--invert {
                        flex-direction: column;
                        align-items: flex-start;
                    }
                    &>img{
                        width: 20px;

                    }
                    &>span{
                        font-size: 20px;
                    }
                    @media screen and (max-width:1300px) {
                        font-size: 25px;
                    }
                    &__item {
                        &>img{
                            width: 20px;
                            margin-right: 10px;
                        }
                        &>span{
                            font-size: 20px;
                        }
                        @media screen and (max-width:1300px) {
                            font-size: 25px;
                        }
                    }
                }
                &__right {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 28px;
                    line-height: 42px;
                    letter-spacing: 1px;
                    
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 10px;
                    &>img{
                        width: 20px;

                    }
                    &>span{
                        font-size: 20px;
                    }
                    @media screen and (max-width:1300px) {
                        font-size: 25px;
                    }

                    &--btn {
                        width: auto;
                        height: auto;
                        padding: 5px;
                    
                        color: #fff;
                        background-color: #6C5DD3;
                        font-family: Inter;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        border-radius: 5px;
                    
                        position: relative;
                        cursor: pointer;
                        user-select: none;
                        transition: background-color 0.3s ease;

                        &:hover {
                            background-color: #5A49CE;
                        }
                    }
                }
            }
        }
    }
    &__head {
        z-index: 20;

        width: 100%;
        height: auto;
        padding: 15px 0;

        display: flex;
        justify-content: space-between;
        align-items: center;

        background-color: #232630;
         
        &__left {
            width: auto;
            height: auto;

            display: flex;
            justify-content: flex-start;
            align-items: center;

            &__showAll {
                display: flex;
                align-items: center;
                gap: 10px;
                color: white;
            }
            &__tabs {
                width: auto;
                height: auto;

                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;

                &__tab {
                    width: auto;
                    height: 60px;
                    padding: 9px 17px;

                    display: flex;
                    align-items: center;

                    color: #FFF;
                    font-size: 14px;
                    font-family: Inter;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    border-radius: 5px; 
                    border: 1px solid transparent;
                    cursor: pointer;
                    
                    transition: background-color 0.3s ease, border 0.3s ease;

                    &--active {
                        background-color: #6C5DD3;
                        border-color: #6C5DD3;
                        cursor: default;
                    }
                    &:hover {
                        border-color: #6C5DD3;
                    }
                }
            }
            &__selected {
                width: auto;
                max-width: 0;
                height: auto;
                padding: 10px 0;
                margin-left: 0px;
                overflow: hidden;
                word-break: keep-all;
                white-space: nowrap;

                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;

                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                
                color: #FFFFFF;
                background: transparent;
                border: 1px solid transparent;
                border-radius: 5px;

                transition: padding 0.3s ease, max-width 0.3s ease, border 0.3s ease, margin 0.3s ease, background 0.3s ease;

                &__btn {
                    width: 18px;
                    height: 18px;
                    background-size: 100%;
                    position: relative;
                    cursor: pointer;

                    @include button-hover-animation(22px);
                }
                &--active {
                    max-width: 300px;
                    padding: 10px 18px;
                    border-color: #6C5DD3;
                    background-color: #232630;
                    margin-left: 10px;
                }
            }
        }
        &__right {
            width: auto;
            height: auto;

            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 30px;

            &__btn {
                width: auto;
                height: auto;
                padding: 10px 16px;

                display: flex;
                justify-content: center;
                align-items: center;
                gap: 8px;

                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                
                color: #FFFFFF;
                background-color: $button-primary;
                border-radius: 5px;
                cursor: pointer;

                transition: background-color 0.3s ease;

                &:hover {
                    background-color: $button-primary-hover;
                }
                & > img {
                    width: 20px;
                    height: 20px;
                }
            };
        }
    }
    &__tableWrap {
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 100%;
        padding-bottom: 5px;
        overflow-y: auto;

        & .customComponents__table__data {
            & > p {
                display: flex;
                align-items: center;
            }
        }
        & .customComponents__table__head__item--border:nth-child(3)::after, & .customComponents__table__data--border > :nth-child(3)::after {
            background-color: transparent !important;
            border: none !important;
            box-shadow: -4px 0px 3px 1px rgba(0, 0, 0, 0.25);
        }
        &__column {
            display: flex;
            align-items: center;

            &__arrow {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
                padding: 5px;
                margin-left: 2px;

                border-radius: 999px;
                border: 1px solid transparent;

                transition: border 0.3s ease;
                cursor: pointer;

                @include button-hover-animation(22px);
                &.cloned-element {
                    border: none !important;
                }
            }
        }
        &--sticky .customComponents__table {
            & .customComponents__table__head {
                padding: 0;

                &__item {
                    &:nth-child(1), &:nth-child(2), &:nth-child(3) {
                        width: 100%;
                        position: sticky;
                        left: 0;
                        margin-left: -5px;
                        padding-right: 25px;
                        padding-left: 5px;
    
                        & .route__user__campaigns__tableWrap__campaignWithImg__headingPadded {
                            background-color: #242731;

                            body.light & {
                                background-color: #fff;
                            }
                        }
    
                        z-index: 2;
                    }
                    &:nth-child(1) {
                        z-index: 4;
                        min-width: unset;

                        & .route__user__campaigns__tableWrap__campaignWithImg__headingPadded {
                            padding-left: 40px;
                        }
                    }
                    &:nth-child(2) {
                        z-index: 3;
                        left: 105px;
                    }
                    &:nth-child(3) {
                        left: 220px;
                    }
                }
            }
            & .customComponents__table__data {
                padding-left: 0 !important;
                padding-top: 15px !important;
                padding-bottom: 15px !important;

                & > * {
                    &:nth-child(1), &:nth-child(2), &:nth-child(3) {
                        min-width: 100%;
                        height: 100%;
                        position: sticky;
                        left: 0;
    
                        z-index: 2;
    
                        & .route__user__campaigns__tableWrap__campaignWithImg__padded {
                            background-color: #2c2e38;

                            body.light & {
                                background-color: #fff;
                            }
                        }
                    }
                    &:nth-child(1) {
                        z-index: 4;
                        width: 100%;
                        height: 100%;
                        min-width: unset;

                        & > p {
                            padding-left: 40px;
                        }
                    }
                    &:nth-child(2) {
                        z-index: 3;
                        left: 105px;
                    }
                    &:nth-child(3) {
                        left: 220px;
                    }
                }

                &0 {
                    & > * {
                        &:nth-child(1), &:nth-child(2), &:nth-child(3) {
                            & .route__user__campaigns__tableWrap__campaignWithImg__padded {
                                background-color: #242731;

                                body.light & {
                                    background-color: #F5F5F5;
                                }
                            }
                        }
                    }
                }
                &--footer {
                    margin-top: -4px;

                    & > * {
                        &:nth-child(1), &:nth-child(2), &:nth-child(3) {
                            & > * {
                                & .route__user__campaigns__tableWrap__campaignWithImg__padded {
                                    background-color: #4B4282;

                                    body.light & {
                                        background-color: #b9aef0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &__paginate {
            width: 100%;
            height: 1px;
        }
        &__campaignWithImg {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 16px;
            
            &__padded {
                width: calc(100% + 45px);
                height: calc(100% + 30px);
                position: absolute;
                top: -15px;
                left: -10px;
                z-index: -1;

                &__fill {
                    width: 100%;
                    height: 100%;
                    position: relative;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    & .component__checkbox {
                        margin-left: 10px;
                    }   
                }
            }
            &__headingPadded {
                width: calc(100% + 40px) !important;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                margin-left: -10px;
                padding-left: 10px;
                z-index: -1;

                &__border {
                    padding: 6px 0;
                    border-top: 1px solid transparent;
                    border-bottom: 1px solid #4267B2;
                }
                &__withInfo {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    margin-right: 18px;

                    transition: margin-right 0.3s ease;
                    
                    & > img {
                        width: 0;
                        height: 0;

                        transition: width 0.3s ease, height 0.3s ease;

                        body.light & {
                            filter: invert(1) hue-rotate(180deg);
                        }
                    }
                    &:hover {
                        margin-right: 0;

                        & > img {
                            width: 18px;
                            height: 18px;
                        }
                    }
                }
            }
            & > img {
                max-width: 56px;
                max-height: 56px;
            }
            & > p {
                width: 95%;
                display: flex;
                flex-direction: column;
                gap: 5px;

                & > span:last-child {
                    color: #808191;
                    font-size: 12px;
                    font-family: Inter;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;

                    display: flex;
                    align-items: center;
                    gap: 5px;

                    & > img {
                        opacity: 0;
                        transition: opacity 0.3s ease;
                    }
                }
                & > span:first-child {
                    color: #FFF;
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                    transition: color 0.3s ease;

                    body.light & {
                        color: black;
                    }
                }
            }
            &:hover, body.light &:hover {
                cursor: pointer;
                
                & > p {
                    & > span:first-child {
                        color: #9390FF;
                    }
                    & > span:last-child {
                        & > img {
                            opacity: 1;
                        }
                    }
                }
            }
            &__hoverID {
                gap: 0 !important;
                transition: gap 0.3s ease;

                & > span:last-child {
                    max-height: 0;
                    overflow: hidden;

                    transition: max-height 0.3s ease;
                }
                &:hover {
                    gap: 5px !important;

                    & > span:last-child {
                        max-height: 50px;
                    }
                }
            }
        }
        &__delivery {
            display: flex;
            align-items: center;
            gap: 10px;

            & > span:first-child {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: #808191;
            }
            &--active > span:first-child {
                background-color: #7FBA7A;
            }
            &--warning > span:first-child {
                background-color: #f0ca32;
            }
            &--error > span:first-child {
                background-color: #df2020;
            }
        }
        &__values {
            display: flex;
            justify-content: center;
            flex-direction: column;
            gap: 5px;

            &__value {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 5px;

                & > img {
                    width: 12px;
                    height: 12px;
                }
                &--warning {
                    color: gray;
                }
            }
        }
    }
    &__urls {
        width: 100%;
        height: 100%;
        background: rgba(35, 38, 48, 0.7);

        display: flex;
        justify-content: center;
        align-items: center;
        cursor: default;

        &__wrap {
            width: 600px;
            height: auto;
            max-height: 100%;
            overflow: auto;
            position: relative;

            padding: 40px;
            color: #fff;
            background-color: #2C2E38;
            box-shadow: 0 0 4px 2px rgba(0,0,0,0.3);
            border-radius: 5px;

            &__utm {
                width: 100%;
                height: auto;
                padding: 12px 24px;
                margin-bottom: 20px;

                font-family: Inter;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: center;
                
                color: white;
                background-color: #373351;
            }
            &__head {
                width: 100%;
                height: auto;
                margin-bottom: 30px;
                
                display: flex;
                justify-content: space-between;
                align-items: center;

                &__left {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                    letter-spacing: 1px;
                    
                    color: #FFFFFF;
                }
                &__right {
                    width: 30px;
                    height: 30px;
                    position: relative;
                    cursor: pointer;

                    @include button-hover-animation(34px);
                }
            }
            &__error {
                color: #fb8484;
            }
            &__urls {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                gap: 20px;

                &__url {
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-direction: column;

                    padding-bottom: 10px;
                    border-bottom: 1px solid gray;

                    &:last-child {
                        padding-bottom: 0;
                        border-bottom: none;
                    }
                    &__name {
                        display: flex;
                        gap: 5px;
                        display: flex;
                        align-items: center;
                        margin-bottom: 10px;
                        flex-wrap: wrap;

                        & > span {
                            max-width: 100%;
                        }
                    }
                    &__tag {
                        width: auto;
                        height: auto;
                        padding: 2px 5px;
                        border-radius: 5px;

                        border: 1px solid #6C5DD3;
                    }
                    &__textWrap {
                        margin-left: 10px;
                        padding-left: 10px;
                        border-left: 1px solid white;

                        display: flex;
                        flex-direction: column;
                        gap: 10px;

                        &__name {
                            padding-top: 10px;

                            display: flex;
                            align-items: center;
                            gap: 10px;

                            &__button {
                                width: auto;
                                height: auto;
                                padding: 5px 10px;

                                border-radius: 5px;
                                background-color: #6C5DD3;

                                cursor: pointer;
                                transition: background-color 0.3s ease;

                                &:hover {
                                    background-color: #5A49CE;
                                }
                            }
                        }
                        &__link {
                            margin-left: 10px;
                            padding: 5px;

                            overflow-x: auto;
                            background-color: #1B1D21;
                            border-radius: 5px;
                            user-select: all;
                        }
                    }
                }
            }
        }
    }
    &__add {
        width: 100%;
        height: 100%;
        background: rgba(35, 38, 48, 0.7);

        display: flex;
        justify-content: center;
        align-items: center;
        cursor: default;

        &__wrap {
            width: 500px;
            height: auto;
            max-height: 100%;
            overflow: auto;
            position: relative;

            padding: 40px;
            color: #fff;
            background-color: #2C2E38;

            body.light & {
                background-color: #fff;
                color: black;
            }
            &__spinner {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                pointer-events: none;

                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 2;
                
                background-color: rgba(38, 41, 53, 0.8);

                transition: opacity 0.3s ease;

                &--active {
                    opacity: 1;
                    pointer-events: all;
                }
            }
            &__head {
                width: 100%;
                height: auto;
                margin-bottom: 30px;
                
                display: flex;
                justify-content: space-between;
                align-items: center;

                &__left {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                    letter-spacing: 1px;
                    
                    color: #FFFFFF;

                    body.light & {
                        color: black;
                    }
                }
                &__right {
                    width: 30px;
                    height: 30px;
                    position: relative;
                    cursor: pointer;

                    @include button-hover-animation(34px);

                    body.light & {
                        filter: invert(1) hue-rotate(180deg);
                    }
                }
            }
            &__tab {
                width: 100%;
                height: auto;
                margin-bottom: 10px;
                padding: 0px 10px;
                
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: auto 0px;

                box-shadow: 0 0 4px 1px #424655;
                background-color: #22242c;

                overflow: hidden;
                transition: grid-template-rows 0.3s ease, padding 0.3s ease;

                body.light & {
                    color: black;
                    background-color: #fff;
                    box-shadow: 0 0 3px 1px rgba(0,0,0,0.25);

                    &:focus {
                        border: 1px solid #6C5DD3;
                    }
                }
                &__name {
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    margin: 5px 0;

                    & > span:last-child {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                    }
                }
            }
            &__input {
                width: 100%;
                height: auto;
                margin-bottom: 20px;

                display: flex;
                flex-direction: column;
                gap: 15px;

                & > p {
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    
                    color: #FFFFFF;

                    & > button {
                        margin-left: 10px;
                        padding: 5px;

                        border: 1px solid $button-primary;
                        border-radius: 5px;
                        outline: none;
                        background-color: transparent;
                        color: white;
                        cursor: pointer;

                        transition: background-color 0.3s ease, border 0.3s ease;

                        &:hover {
                            background-color: $button-primary-hover;
                            border: 1px solid $button-primary;
                        }
                    }
                    body.light & {
                        color: black;
                    }
                }
                & > input  {
                    width: 100%;
                    height: 54px;
                    padding: 0 20px;

                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 19px;

                    outline: none;
                    color: white;
                    background: #373A43;
                    border-radius: 5px;
                    border: 1px solid transparent;

                    transition: border 0.3s ease;

                    body.light & {
                        color: black;
                        background-color: #fff;
                        border-color: #E2E2E2;

                        &:focus {
                            border: 1px solid #6C5DD3;
                        }
                    }
                    &:focus {
                        border: 1px solid #6C5DD3;
                    }
                }
                & .component__customComponent__dropbox {
                    border: 1px solid transparent;
                    transition: border 0.3s ease;

                    body.light & {
                        border-color: #E2E2E2;
                    }
                }
                &--text {
                    & > textarea {
                        width: 100%;
                        height: auto;
                        min-height: 100px;
                        padding: 18px 20px;
                        resize: vertical;

                        font-family: "Inter";
                        font-style: normal;
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 19px;

                        outline: none;
                        color: white;
                        background: #373a43;
                        border-radius: 5px;
                        border: 1px solid transparent;

                        transition: border 0.3s ease;

                        body.light & {
                            color: black;
                            background-color: #e9e8e8;
    
                            &:focus {
                                border: 1px solid #6C5DD3;
                            }
                        }
                        &:focus {
                            border: 1px solid #6c5dd3;
                        }
                    }
                }
                &--error > input, &--error > textarea, &--error .component__customComponent__dropbox {
                    border: 1px solid #FF754C;
                    
                    body.light & {
                        border: 1px solid #FF754C;
                    }
                }
                &__dropItem {
                    width: 100%;
                    height: auto;

                    display: flex;
                    align-items: center;
                    gap: 10px;

                    & > img {
                        width: auto;
                        height: auto;
                        max-height: 48px;
                        max-width: 48px;
                    }
                    &__risks {
                        width: auto !important;
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        width: 100%;
                        height: 2px;

                        &__risk {
                            width: 20px;
                            height: 100%;
                            border-radius: 999px;
                        }
                    }
                }
            }
            &__buttons {
                width: 100%;
                height: auto;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 20px;

                &__btn {
                    width: auto;
                    height: 43px;
                    padding: 0 40px;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    color: #fff;
                    background-color: $button-primary;
                    border-radius: 5px;
                    cursor: pointer;

                    transition: background-color 0.3s ease;

                    &:hover {
                        background-color: $button-primary-hover;
                    }
                    &--secondary {
                        background-color: $button-secondary;

                        &:hover {
                            background-color: $button-secondary-hover;
                        }
                    }
                }
            }
            &__infoP {
                margin-top: 20px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #FF450D;
                opacity: 0;
                transition: opacity 0.3s ease;
            }
        }
    }
    &__order {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.modals__transfer{
    width: 100%;
    height: 100%;
    background-color: #1b1d2169;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;

    &__container{
        width: 500px;
        height: auto;
        background-color: #2C2E38;
        padding: 20px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px;

        &__infoP {
            color: #FFF;
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;

            color: #ff8585;
        }
        &__buttons{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 20px;

            &>div{
                padding: 10px 40px;
                width: auto;
                cursor: pointer;
                border: none;
                color: white;
                font-family: Inter;
                font-size: 18px;

                border-radius: 5px;
                transition: background-color 0.3s ease;

                &:first-child{
                    background-color: #6C5DD3;

                    &:hover {
                        background-color: #5A49CE;
                    }
                }
                &:last-child{
                    background-color: #1B1D21;

                    &:hover {
                        background-color: #0D0F11;
                    }
                }
            }
        }
    }
}