@import "../../../scss/mixins.scss";

.modals__shareCampaign {
    all: initial;
    display: block;
    width: 100%;
    height: 100%;
    cursor: default;
    position: relative;
    background-color: rgba(35, 38, 48, 0.8);

    &__wrap {
        position: absolute;
        top: 0;
        right: -100%;
        width: calc(100% - 84px);
        height: 100vh;
        color: white;
        background-color: #2c2e38;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
        display: grid;
        grid-template-rows: max-content 1fr;
        grid-template-columns: 1fr;

        body.light & {
            background-color: #fff;
        }
        &__header {
            width: 100%;
            height: 102px;
            padding: 0 50px;
            padding-bottom: 0px;
            padding-bottom: 25px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            border-bottom: 1px solid #373a43;

            body.light & {
                border-color: #E2E2E2;
            }
            &__left {
                color: #fff;
                font-family: Inter;
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                body.light & {
                    color: black;
                }
            }
            &__right {
                width: 30px;
                height: 30px;
                cursor: pointer;
                position: relative;

                @include button-hover-animation(34px);

                body.light & {
                    filter: invert(1) hue-rotate(180deg);
                }
            }
        }
        &__content {
            width: 100%;
            height: 100%;
            padding: 25px 50px;

            color: white;
            overflow: auto;

            body.light & {
                color: black;
            }
        }
    }
}