@import "../../../scss//mixins.scss";

.component__filters__filterByDate {
    width: 100%;
    height: 54px;
    padding: 0 12px;
    position: relative;

    background: #2C2E38;
    border: 1px solid #373A43;
    border-radius: 5px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    body.light & {
        border-color: #E2E2E2;
        background-color: #FFFFFF;
    }
    &__dropdown {
        position: absolute;
        top: calc(100% + 10px);
        right: 0;
        width: 245px;
        height: auto;
        z-index: 99;
        overflow: hidden;

        background: #2C2E38;
        box-shadow: none;
        border-radius: 5px 0px 0px 5px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        transition: box-shadow 0.3s ease, max-height 0.3s ease, width 0.3s ease, border 0.3s ease;

        body.light & {
            background-color: #fff;
        }
        &--active {
            box-shadow: 0px 0px 19px 5px rgba(0, 0, 0, 0.25);
        }
        &--custom {
            width: calc(270px * 2);
        }
        &--custom &__left {
            border-right: 1px solid #373A43;
        }
        &__left {
            min-width: 270px;
            width: max-content;

            &__item {
                width: 100%;
                height: auto;
                padding: 20px;
    
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                
                color: #FFFFFF;
                cursor: pointer;

                transition: background-color 0.3s ease;

                body.light & {
                    color: black;
                }
                &--active, &:hover {
                    background-color: #383B46;

                    body.light & {
                        background-color: #F5F5F5;
                    }
                }
            }
        }
        &__right {
            width: 245px;
            height: 100%;
            align-self: flex-start;
            padding: 20px 16px;

            &__input {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;

                margin-bottom: 15px;

                & > p {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;

                    margin-bottom: 13px;
                    
                    color: #FFFFFF;
                    
                    body.light & {
                        color: #000;
                    }
                }
                & > input {
                    width: 100%;
                    height: 32px;
                    padding: 0 12px;

                    color: white;
                    background: #373A43;
                    border-radius: 5px;
                    border: 1px solid #373A43;
                    outline: none;

                    body.light & {
                        border-color: #E2E2E2;
                        background-color: #fff;
                        color: black;
                    }
                }
            }
            &__btn {
                width: 105px;
                height: 30px;

                display: flex;
                justify-content: center;
                align-items: center;

                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;

                margin-left: auto;

                color: white;
                background-color: $button-primary;
                border-radius: 5px;

                transition: background-color 0.3s ease;
                cursor: pointer;

                &:hover {
                    background-color: $button-primary-hover;
                }
            }
        }
    }
    &__left {
        height: 100%;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        padding: 8px 0;

        &__top {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;

            color: #FFFFFF;

            body.light & {
                color: black;
            }
        }
        &__bottom {

            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            
            color: #FFFFFF;

            body.light & {
                color: black;
            }
        }
    }
    &__right {
        width: 44px;
        height: 44px;

        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 4px;
        background-color: $button-primary;
        cursor: pointer;

        transition: background-color 0.3s ease;

        &:hover {
            background-color: $button-primary-hover;
        }
    }
}