@import "../../../scss/mixins.scss";

.modals__campaignTrackEventsModal {
    all: initial;
    display: block;

    width: 100%;
    height: 100%;
    cursor: default;
    position: relative;
    background-color: rgba(35, 38, 48, 0.8);

    &__wrap {
        position: absolute;
        top: 0;
        right: -100%;
        width: calc(100% - 84px);
        height: 100vh;

        background-color: #2c2e38;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);

        &__header {
            width: 100%;
            height: 102px;
            padding: 0 50px;
            padding-bottom: 25px;

            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            border-bottom: 1px solid #373a43;

            &__left {
                color: #fff;
                font-family: Inter;
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                & > span {
                    color: $button-primary;
                }
            }
            &__right {
                width: 30px;
                height: 30px;
                cursor: pointer;

                @include button-hover-animation(34px);
            }
        }
        &__content {
            width: 100%;
            height: 100%;

            padding: 25px 50px;

            display: grid;
            grid-template-columns: 1fr auto;
            grid-template-rows: auto;
            overflow: hidden;
            max-height: calc(100% - 102px);
            
            transition: all 0.3s ease;
            

            &__left {
                width: 100%;
                height: 100%;
                overflow: scroll;

                
                &__tableWrap {
                    width: auto;
                    max-width: 100%;
                    height: auto;
                    padding-bottom: 5px;
                    overflow-y: auto;
                    filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.25));

                    &__note {
                        margin-bottom: 20px;
                        color: #808191;
                    }
                    & > h3 {
                        color: white;
                        margin-bottom: 10px;

                        body.light & {
                            color: black;
                        }
                    }
                    &__pills {
                        width: 100%;
                        height: auto;
    
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        gap: 10px;
    
                        &__pill {
                            width: auto;
                            height: 20px;
                            padding: 10px;
                            font-size: 12px;
    
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            color: white;
                            background-color: #434295;
                            border-radius: 5px;
                        }
                    }
                }
                & .customComponents__table__data {
                    transition: background-color 0.3s ease;
                }
                &__img {
                    width: 18px;
                    height: 18px;
                }
                &__search {
                    width: 100%;
                    height: auto;
                    box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.2) !important;

                    margin-bottom: 20px;

                    display: grid;
                    grid-template-columns: 1fr auto;
                    grid-template-rows: auto;
                }
                &__paginate {
                    opacity: 0;
                    width: 1px;
                    height: 1px;
                }
            }

            &__right {
                width: 0;
                height: auto;
                min-height: 800px;
                max-height: 100%;
                padding-left: 0;
                overflow: hidden;
                justify-self: flex-end;

                position: sticky;
                top: 0;
                @media screen and (max-height: 950px) {
                    min-height: 800px;
                }
                @media screen and (max-height: 930px) {
                    min-height: 700px;
                }
                @media screen and (max-height: 830px) {
                    min-height: 600px;
                }
                @media screen and (max-height: 730px) {
                    min-height: 550px;
                }
                @media screen and (max-height: 680px) {
                    min-height: 500px;
                }
                @media screen and (max-height: 630px) {
                    min-height: 450px;
                }

                transition:
                    width 0.3s ease,
                    padding-left 0.3s ease,
                    border-left 0.3s ease,
                    margin-left 0.3s ease,
                    box-shadow 0.3s ease;

                &--active {
                    width: 800px;
                    padding-left: 20px;
                    margin-left: 20px;
                    border-left: 1px solid gray;

                    &::after {
                        content: "";
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: calc(100% - 20px);
                        height: 100%;
                        pointer-events: none;
                        box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.2) !important;
                    }
                }
                &__wrap {
                    width: 100%;
                    height: max-content;
                    min-height: 100%;
                    padding: 30px;

                    display: grid;
                    grid-template-rows: auto auto 1fr;
                    grid-template-columns: 1fr;
                    border-radius: 5px;
                    background-color: #232630;

                    &__head {
                        margin-bottom: 30px;
                        position: relative;

                        &__top {
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            margin-bottom: 14px;

                            color: #fff;
                            font-family: Inter;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            letter-spacing: 1px;
                        }
                        &__bottom {
                            color: #808191;
                            font-family: Inter;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                        &__close {
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: 30px;
                            height: 30px;

                            cursor: pointer;

                            @include button-hover-animation(34px, "absolute");
                        }
                    }
                    &__tabs {
                        width: 100%;
                        height: auto;
                        margin-bottom: 20px;

                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        flex-wrap: wrap;
                        gap: 10px;

                        &__tab {
                            width: auto;
                            height: auto;
                            padding: 10px 18px;

                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;

                            border-radius: 5px;
                            border: 1px solid transparent;

                            cursor: pointer;
                            transition:
                                background-color 0.3s ease,
                                border-color 0.3s ease;

                            & > svg {
                                width: 16px;
                                height: 16px;
                            }
                            & > span {
                                color: #808191;
                                font-family: Inter;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;

                                transition: color 0.3s ease;
                            }
                            & > svg g,
                            & > svg path {
                                transition: fill 0.3s ease;
                            }
                            &:hover {
                                border-color: #6c5dd3;
                            }
                            &--active {
                                background-color: #6c5dd3;

                                & > span {
                                    color: white;
                                }
                                & > svg g,
                                & > svg path {
                                    fill: white;
                                }
                            }
                        }
                    }
                    &__tabWrap {
                        width: 100%;
                        height: 100%;
                        position: relative;
                        overflow: auto;

                        & > * {
                            height: auto;
                        }
                        & .customComponents__table__spinner {
                            display: none !important;
                            position: relative;
                        }
                        &__item {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 150%;

                            &__products {
                                display: grid;
                                grid-template-columns: 40px 1fr repeat(2, max-content);
                                grid-template-rows: max-content;
                                grid-auto-rows: max-content;
                                column-gap: 20px;
                                row-gap: 20px;

                                &__head {
                                    color: #FFF;
                                    font-family: Inter;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                }
                                &__image {
                                    width: 40px;
                                    height: 40px;

                                    border-radius: 5px;
                                    background: #373351; 
                                    overflow: hidden;

                                    & > img {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                                &__text {
                                    display: flex;
                                    align-items: center;

                                    color: #FFF;
                                    font-family: Inter;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                }
                                &__total1 {
                                    grid-column: 3;

                                    color: #FFF;
                                    font-family: Inter;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                }
                                &__total2 {
                                    grid-column: 4;

                                    color: #FFF;
                                    font-family: Inter;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;

                                    text-align: right;
                                }
                                &__line {
                                    grid-column: 1 / -1;
                                    width: 100%;
                                    height: 1px;
                                    background-color: #484F5C;
                                }
                            }
                            &__numberTable {
                                width: 100%;
                                height: auto;

                                display: flex;
                                justify-content: flex-start;
                                align-items: flex-start;

                                & > * {
                                    left: 0 !important;
                                }
                            }
                            &__journey {
                                width: 100%;
                                height: 100%;
                                overflow: auto;

                                display: flex;
                                flex-direction: column;
                                gap: 16px;

                                &__infoP {
                                    color: #f96666;
                                }
                                &__item {
                                    width: 100%;
                                    height: auto;

                                    display: grid;
                                    grid-template-columns: auto 1fr;
                                    grid-template-rows: auto;
                                    column-gap: 16px;

                                    &__left {
                                        width: 40px;
                                        height: 100%;

                                        display: grid;
                                        grid-template-rows: auto 1fr;
                                        grid-template-columns: 1fr;


                                        & > img {
                                            width: 100%;
                                            height: auto;
                                            border-radius: 50%;
                                        }
                                        &__line {
                                            width: 0px;
                                            height: calc(100% + 4px);
                                            border-left: 1px solid gray;
                                            border-right: 1px solid gray;
                                            margin: 0 auto;
                                            margin-top: 4px;
                                            z-index: 2;
                                            
                                            &:before {
                                                content: "\25bc";
                                                color: white;
                                                left: -6px;
                                                top: 20px;
                                                transform: rotate(180deg);
                                                position: relative;
                                            }
                                            &:after {
                                                content: "\25bc";
                                                color: white;
                                                transform: rotate(180deg);
                                                left: -19px;
                                                top: 80%;
                                                position: relative;
                                            }
                                        }
                                    }
                                    &__right {
                                        width: 100%;
                                        height: auto;
                                        padding: 20px;

                                        display: flex;
                                        flex-direction: column;
                                        flex-wrap: wrap;
                                        gap: 12px;

                                        border-radius: 5px;
                                        border: 1px solid transparent;
                                        background: #2c2e38;
                                        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);

                                        &__image {
                                            width: 100%;
                                            height: auto;

                                            & > img {
                                                width: auto;
                                                max-width: 100%;
                                                height: auto;
                                                max-height: 200px;

                                                cursor: pointer;
                                            }
                                        }
                                        &__textBlock {
                                            width: 100%;
                                            height: auto;

                                            display: flex;
                                            flex-direction: column;
                                            gap: 1px;

                                            & > p {
                                                color: #fff;
                                                font-family: Inter;
                                                font-size: 14px;
                                                font-style: normal;
                                                font-weight: 600;
                                                line-height: 140%; /* 19.6px */
                                                letter-spacing: 0.7px;
                                            }
                                            & > span {
                                                color: #808191;
                                                font-family: Inter;
                                                font-size: 14px;
                                                font-style: normal;
                                                font-weight: 400;
                                                line-height: 140%; /* 19.6px */
                                                letter-spacing: 0.7px;
                                            }
                                            &--active > p {
                                                color: $button-primary;
                                            }
                                        }
                                        &__line {
                                            width: 100%;
                                            height: 1px;
                                            background-color: gray;
                                        }
                                        &--converted {
                                            border: 1px solid green;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}