.route__socialMediaManager {
    width: 100%;
    height: 100%;

    &__filters {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 20px;
    }
    &__kpi {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: repeat(auto-fit, 309px);

        &__item {
            padding: 24px 40px;
            width: 100%;
            height: auto;

            display: flex;
            flex-direction: column;
            gap: 10px;

            border: 1px solid #373A43;
            color: white;

            body.light & {
                border-color: #E2E2E2;
            }
            &__top {
                font-family: Inter;
                font-size: 14px;
                font-weight: 600;
                line-height: 16px;
                letter-spacing: 0em;
                text-align: left;
                
                body.light & {
                    color: black;
                }
            }
            &__bottom {
                font-family: Inter;
                font-size: 28px;
                font-weight: 600;
                line-height: 34px;
                letter-spacing: 1px;
                text-align: left;
                
                body.light & {
                    color: black;
                }
            }
        }
    }
}