.route__user__creativeDesigner {
    color: white;

    body.light & {
        color: black;
    }
    &__kpi {
        display: grid;
        margin-bottom: 20px;

        grid-template-columns: repeat(auto-fit, 300px);
        grid-template-rows: auto;
        grid-auto-rows: auto;
        row-gap: 10px;

        &__item {
            padding: 24px 40px;
            outline: 1px solid #373A43;

            display: flex;
            flex-direction: column;
            gap: 10px;

            body.light & {
                outline-color: #adadad;
            }
            & > p:first-child {
                font-family: Inter;
                font-size: 14px;
                font-weight: 600;
                line-height: 16px;
                text-align: left;                
            }
            & > p:last-child {
                display: flex;
                align-items: center;

                font-family: Poppins;
                font-size: 28px;
                font-weight: 700;
                line-height: 42px;
                letter-spacing: 1px;
                text-align: left;        
            }
        }
    }
    &__job {
        width: 100%;
        height: auto;

        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 10px;

        & > p {
            width: 100%;
            min-width: 0;
            height: auto;

            display: grid;
            grid-template-columns: max-content 1fr;
            grid-template-rows: auto;
            column-gap: 5px;

            & > span:first-child {
                width: 110px;

                font-weight: bold;
                color: #6C5DD3;

                align-self: center;
            }
            & > span:last-child {
                width: 100%;
                word-break: break-word;
                line-height: 1.5em;
            }
        }
        &__pills {
            width: 100%;
            height: auto;

            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;

            &__pill {
                background-color: #434295;
                padding: 5px 10px;
                border-radius: 5px;
                display: flex;
                align-items: center;
                grid-gap: 10px;
                gap: 10px;
            }
        }
    }
}