@import "../../../../scss/globals.scss";

.route__reports__marketingOffers {
    width: 100%;
    height: 100%;
    position: relative;

    &__progress {
        width: calc(100% - 86px);
        height: 5px;

        position: fixed;
        top: 0;
        left: 86px;

        overflow: hidden;
        z-index: 999999;

        &__item {
            height: 100%;
            background-color: #6C5DD3;

            transition: width 1s linear;
        }
    }
    &__table {
        & .customComponents__table__data__group {
            justify-content: flex-start;
            gap: 10px;

            & > p {
                margin: 0;
            }
        }
        & .customComponents__table__data {
            transition: all 0.3s ease !important;

            &--footer {
                transition: none !important;
            }
        }
        & .customComponents__table__head {
            .components__filters__filterBySearch {
                background: transparent !important;
                border-color: transparent !important;
                width: calc(100% - 4px) !important;

                & > input {
                    background: transparent !important;
                    border-color: transparent !important;

                    &:focus {
                        border-color: $button-primary-hover !important;
                    }
                }
            }
            & > p:first-child > div {
                width: calc(100% - -9px);
            }
        }
    }
    &__columns {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        align-items: center;
        gap: 20px;
        margin-bottom: 20px;
        &__export {
            width: 24px;
            height: 24px;

            background-size: 100% 100%;
            cursor: pointer;

            @include button-hover-animation(32px);

            &.cloned-element {
                background-image: none !important;
            }
            body.light & {
                filter: invert(1);
            }
        }
        &__groupBy {
            width: auto;
            height: 40px;
            padding: 0 10px;

            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;

            border-radius: 5px;
            border: 1px solid #6C5DD3;

            & > p {
                color: #FFF;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                body.light & {
                    color: black;
                }
            }

            &__btn {
                padding: 4px;

                color: #808191;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                border-radius: 5px;

                border: 1px solid transparent;
                transition: border 0.3s ease, background-color 0.3s ease, color 0.3s ease;
                cursor: pointer;

                &:hover {
                    border: 1px solid #6C5DD3;
                }
                &--active {
                    background: #6C5DD3;

                    color: #FFF;
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
            &--red {
                border-color: #D35D5D;
            }
            &--red &__btn {
                &:hover {
                    border-color: #D35D5D;
                }
                &--active {
                    background-color: #D35D5D;
                }
            }
        }
    }
    &__calculator {
        & .genericModal__wrap__input > p {
            line-height: 30px;
            font-size: 18px;
        }
        & input {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 19px;

            outline: none;
            color: white;
            background: #373A43;
            border-radius: 5px;
            border: 1px solid gray;
            opacity: 0.5;

            transition: border 0.3s ease, opacity 0.3s ease;

            &:focus {
                border: 1px solid #6C5DD3;
            }
            &:hover, &:focus {
                opacity: 1;
            }
        }
    }
    &__filters {
        width: 100%;
        height: auto;

        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
        column-gap: 30px;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 20px;

        &--additional {
            grid-template-columns: 2fr 1fr 1fr 1fr;
        }
        &__userFilter {
            position: relative;
            width: 100%;
            height: 100%;

            &__image {
                width: 24px;
                height: 24px;

                position: absolute !important;
                top: 50%;
                right: 50px;
                transform: translateY(-50%);
                z-index: 2;
                cursor: pointer;

                @include button-hover-animation(32px);

                & > img {
                    width: 24px;
                    height: 24px;
                }
            }
            &__progress {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2px;
                z-index: 2;

                &__item {
                    width: 0;
                    height: 100%;
                    background-color: #6c5dd3;

                    transition: width 0.3s linear;
                }
            }
        }
        &__btn {
            width: 100%;
            height: 100%;

            display: flex;
            justify-content: center;
            align-items: center;

            font-family: Inter;
            font-size: 16px;
            font-weight: 500;
            line-height: 19.36px;
            text-align: left;            

            border: 1px solid $button-primary;
            color: white;
            background-color: transparent;
            border-radius: 5px;

            cursor: pointer;
            transition: background-color 0.3s ease;

            body.light & {
                color: black;
            }
            &.cloned-element {
                border-color: transparent !important;
            }
            &:hover {
                background-color: $button-primary-hover;
            }
        }
    }
    &__kpi {
        width: 100%;
        height: auto;
        margin-bottom: 20px;

        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: auto;
        grid-auto-rows: auto;
        row-gap: 20px;

        &__item {
            width: 100%;
            height: 120px;

            display: flex;
            flex-direction: column;
            justify-content: space-between;

            padding: 24px 40px;
            border: 1px solid #373A43;

            body.light & {
                border-color: #adadad;
            }
            &__top {
                color: #FFF;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;

                display: flex;
                justify-content: space-between;
                align-items: center;

                body.light & {
                    color: black;
                }
            }
            &__bottom {
                color: #FFF;
                font-family: Inter;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 1px;

                display: flex;
                justify-content: space-between;
                align-items: center;
                
                flex-wrap: wrap;

                & span {
                    margin: 3px 0;
                }
                body.light & {
                    color: black;
                }
            }
        }
    }
    &__chart {
        width: 100%;
        height: auto;
        position: relative;
        margin-bottom: 20px;

        &__spinner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            display: flex;
            justify-content: center;
            align-items: center;

            background-color: rgba(35, 38, 48, 0.8);

            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s ease;

            body.light & {
                background-color: rgba(35, 38, 48, 0.2);
            }
            &--active {
                opacity: 1;
                pointer-events: all;
            }
        }
    }

}

.route__reports__marketingOffers__filterByIntegration {
    width: 100%;
    height: 100%;
    position: relative;

    cursor: default;
    background-color: rgba(35, 38, 48, 0.8);

    &__perCampaign {
        .customComponents__table__head {
            position: sticky !important;
            top: 0px !important;
        }
    }
    &__wrap {
        position: absolute;
        width: 600px;
        height: 100%;
        top: 0;
        right: -100%;
        padding: 20px;

        display: grid;
        grid-template-rows: auto 1fr;
        grid-template-columns: 1fr;

        background-color: #2C2E38;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);

        body.light & {
            background-color: #fff;
        }
        &__top {
            width: 100%;
            height: auto;
            padding-bottom: 25px;

            display: flex;
            justify-content: space-between;
            align-items: center;

            border-bottom: 1px solid #373A43;

            body.light & {
                border-color: #E2E2E2;
            }
            &__left {
                color: #FFF;
                font-family: Inter;
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                body.light & {
                    color: black;
                }
            }
            &__right {
                background-color: $button-primary;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 3px;

                cursor: pointer;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: $button-primary-hover;
                }
            }
        }
        &__content {
            width: 100%;
            height: 100%;
            overflow: auto;
            color: white;
            padding: 15px 0;

            display: flex;
            flex-direction: column;
            gap: 20px;

            body.light & {
                color: black;
            }
            &__clickable {
                cursor: pointer;

                transition: color 0.3s ease;

                &:hover {
                    color: #9390FF;
                }
            }
            &__buttons {
                width: 100%;
                height: auto;

                display: grid;
                grid-template-rows: auto;
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;
            }
            &__integrations {
                width: 100%;

                display: flex;
                flex-direction: column;
                flex-wrap: wrap;

                &__integration {
                    width: 100%;
                    height: auto;

                    padding: 10px 0;

                    display: grid;
                    grid-template-columns: max-content 1fr;
                    grid-template-rows: auto;
                    gap: 10px;

                    border-bottom: 1px solid #373A43;

                    &:last-child {
                        border-bottom: none;
                    }
                    &__left {
                        height: 100%;

                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    &__right {
                        & > p:first-child {
                            color: #6C5DD3;
                        }
                    }
                }
            }
            &__tableProfileItem {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 5px;

                & > img {
                    width: 24px;
                    height: 24px;
                }
            }
            &__hoverItem {
                cursor: pointer;

                transition: color 0.3s ease;
                &:hover {
                    color: #9390FF;
                }
            }
        }
    }
}