@import "../../../../scss/mixins.scss";

.routes__reports__custom {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    gap: 20px;

    color: black;
    body.dark & {
        color: white;
    }
    &__head {
        display: flex;
        align-items: center;
        gap: 20px;
    }
}

.route__reports__custom__view {
    width: 100%;
    height: auto;

    color: black;
    body.dark & {
        color: white;
    }
    & > h3 {
        margin-bottom: 20px;
    }
    &__filters {
        width: 100%;
        height: auto;
        margin-bottom: 20px;

        display: grid;
        grid-template-columns: repeat(3, 1fr) 150px;
        grid-template-rows: auto;
        gap: 20px;

        &__btn {
            width: 100%;
            height: 100%;

            display: flex;
            justify-content: center;
            align-items: center;

            font-family: Inter;
            font-size: 16px;
            font-weight: 500;
            line-height: 19.36px;
            text-align: left;            

            border: 1px solid $button-primary;
            color: white;
            background-color: transparent;
            border-radius: 5px;

            cursor: pointer;
            transition: background-color 0.3s ease;

            body.light & {
                color: black;
            }
            &.cloned-element {
                border-color: transparent !important;
            }
            &:hover {
                background-color: $button-primary-hover;
            }
        }
    }
}