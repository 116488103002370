.component__filters__filterByUserAccount {
    width: auto;
    height: 54px;
    padding: 5px 20px;
    position: relative;

    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr;
    column-gap: 20px;
    justify-content: space-between;
    align-items: center;

    border-radius: 5px;
    border: 1px solid #373A43;
    background: #2C2E38;

    body.light & {
        background-color: #fff;
        border-color: #E2E2E2;
    }
    &--hasData {
        cursor: pointer;
        transition: border 0.3s ease;

        &:hover, body.light &:hover {
            border-color: #6C5DD3;
        }
    }
    &__left {
        width: 100%;
        height: 100%;
        overflow: hidden;

        display: flex;
        flex-direction: column;
        justify-content: center;

        &__top {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #FFFFFF;

            body.light & {
                color: black;
            }
        }
        &__bottom {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;

            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #FFFFFF;

            body.light & {
                color: black;
            }
            & > img {
                width: 16px;
                height: 16px;
            }
        }
    }
    &__right {
        height: 100%;

        display: flex;
        align-items: center;

        transition: transform 0.3s ease;

        &--active {
            transform: rotate(180deg);
        }
    }
    &__dropdown {
        cursor: default;
        position: absolute;
        top: calc(100% + 10px);
        left: 0;
        width: 100%;
        min-width: calc(245px * 3);
        height: auto;
        z-index: 99;
        overflow: hidden;
        background: #2C2E38;
        box-shadow: none;
        border-radius: 5px 0px 0px 5px;
        display: grid;
        grid-template-columns: 100% 0px 0px;
        grid-template-rows: auto;
        grid-auto-flow: column;
        justify-content: space-between;
        align-items: flex-start;
        transition: box-shadow 0.3s ease, max-height 0.3s ease, width 0.3s ease, border 0.3s ease, grid-template-columns 0.3s ease;

        body.light & {
            background-color: #fff;
            border-radius: #E2E2E2;
        }
        &--active {
            box-shadow: 0px 0px 19px 5px rgba(0, 0, 0, 0.25)
        }
        &--userSelected {
            grid-template-columns: 245px 100% 0px;
        }
        &--integrationSelected {
            grid-template-columns: 245px 245px 100%;
        }
        &__left, &__middle, &__right {
            overflow: auto;
            width: 100%;
            min-height: 100%;
            transition: width 0.3s ease;

            &--active {
                width: 245px;
            }
            &__item {
                width: 100%;
                min-width: 0;
                height: auto;
                padding: 20px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #FFFFFF;
                cursor: pointer;
                transition: background-color 0.3s ease;

                body.light & {
                    color: black;
                }
                &:hover, &--active {
                    background-color: #383B46;

                    body.light & {
                        background-color: #F5F5F5;
                    }
                }
            }
        }
        &__left, &__middle, &__right {
            max-height: 300px;
            overflow-y: auto;
        }
        &__left, &__middle {
            border-right: 1px solid gray;

            body.light & {
                border-color: #E2E2E2;
            }
        }
        &__middle {
            &__item {
                display: flex;
                align-items: center;
                gap: 10px;

                & > img {
                    width: 16px;
                    height: 16px;
                }
            }
        }
        &__right {
            &__item {
                & > span:first-child {
                    font-size: 14px;
                    line-height: 16px;
                    color: #6C5DD3;   
                }
            }
        }
    }
}