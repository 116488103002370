@import "../../../scss/mixins.scss";

.route__admin__dataChange {
    width: 100%;
    height: auto;

    &__tableIntegration {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        gap: 5px;

        & > img {
            width: 16px;
            height: 16px;
            border-radius: 50%;
        }
    }
    &__kpi {
        width: 100%;
        height: auto;
        margin: 20px 0;

        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: auto;
        grid-auto-rows: auto;
        column-gap: 20px;
        row-gap: 20px;

        border-radius: 5px;
        border: 1px solid #373A43;

        body.light & {
            border-color: #E2E2E2;
        }
        &__item {
            padding: 24px;

            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            gap: 12px;

            &:not(:last-child) {
                border-right: 1px solid #373A43;

                body.light & {
                    border-color: #E2E2E2;
                }
            }
            &__top {
                font-family: Inter;
                font-size: 14px;
                font-weight: 600;
                line-height: 16px;
                text-align: left;
                color: white;

                body.light & {
                    color: black;
                }
            }
            &__bottom {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;

                font-family: Poppins;
                font-size: 18px;
                font-weight: 600;
                line-height: 42px;
                letter-spacing: 1px;
                text-align: left;

                color: white;

                body.light & {
                    color: black;
                }
                &__btn {
                    width: auto;
                    height: auto;
                    padding: 10px 18px;

                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16.94px;
                    text-align: left;
                    
                    border-radius: 5px;
                    border: 1px solid #6C5DD3;
                    background-color: transparent;
                    cursor: pointer;
                    transition: background-color 0.3s ease;

                    &:hover, &--active {
                        background-color: $button-primary-hover;
                        color: white;
                    }
                }
            }
        }
    }
}

.route__admin__dataChange__modal {
    width: 100%;
    height: 100%;
    position: relative;

    cursor: default;
    background-color: rgba(35, 38, 48, 0.8);

    &__wrap {
        position: absolute;
        width: 450px;
        height: 100%;
        top: 0;
        right: -100%;
        padding: 20px;

        display: grid;
        grid-template-rows: auto 1fr;
        grid-template-columns: 1fr;

        background-color: #2C2E38;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);

        body.light & {
            background-color: #fff;
        }
        &__top {
            width: 100%;
            height: auto;
            padding-bottom: 25px;

            display: flex;
            justify-content: space-between;
            align-items: center;

            border-bottom: 1px solid #373A43;

            body.light & {
                border-color: #E2E2E2;
            }
            &__left {
                color: #FFF;
                font-family: Inter;
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                body.light & {
                    color: black;
                }
            }
            &__right {
                background-color: $button-primary;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 3px;

                cursor: pointer;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: $button-primary-hover;
                }
            }
        }
        &__content {
            width: 100%;
            height: 100%;
            overflow: auto;
            color: white;

            padding: 15px 0;

            body.light & {
                color: black;
            }
        }
    }
}