.route__reports_facebookExport {
    &__headers {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        &>p{
            color: white;
            &>input{
                border: none;
            }
        }
    }
}
