.route__admin__botDetection {
    width: 100%;
    height: auto;
    min-height: 100%;

    color: black;
    overflow-y: auto;

    body.dark & {
        color: white;
    }
    &__filters {
        display: grid;
        grid-template-columns: repeat(2, 1fr) 200px;
        column-gap: 20px;
        margin-bottom: 20px;
    }
    &__data {
        & > p {
            color: gray;

            & > span {
                color: black;

                body.dark & {
                    color: white;
                }
            }
        }
    }
}