.route__admin__globalSharing {
    width: 100%;
    height: auto;

    color: white;

    body.light & {
        color: black;
    }
    &__notice {
        margin: 15px 0;
        margin-bottom: 0px;
        padding: 10px;
        border-radius: 5px;

        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        background-color: #373351;

        body.light & {
            background-color: #c9c4e4;
        }
        & > p {
            color: white;
            font-family: Inter;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;

            body.light & {
                color: black;
            }
        }
    }
}