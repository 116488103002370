.route__landing{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    background-color: #0E0C15;
    padding-top: 118px;
    overflow: hidden;
    &__wrapper{
        width: 100%;
        max-width: 1920px;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &__first{
            width: 100%;
            max-width: 1920px;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 46px;
            background-position: center;
            background-repeat: no-repeat;
            // background-size:100% auto;
            &__top{
                width: 100%;
                max-width: 1183px;
                height: auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                &__headline{
                    color: #FFF;
                    text-align: center;
                    font-size: 50px;
                    font-family: Lato;
                    font-weight: 700;
                    line-height: 72px; 
                    width: 100%;
                    max-width: 838px;
                }
                &__message{
                    color: #CAC6DD;
                    text-align: center;
                    font-size: 20px;
                    font-family: Lato;
                    font-weight: 600; 
                    margin-top: 20px;
                }
                &__getStarted{
                    padding: 15px 47px;
                    border-radius: 5px;
                    background: #6C5DD3; 
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 40px;
                    cursor: pointer;
                    transition: 0.3s ease-in-out;
                    &:hover{
                        background: #9390FF; 
                    }
                    p{
                        color: #FFF;
                        font-size: 18px;
                        font-family: Inter;
                        text-transform: uppercase; 
                    }
                }
                &__main{
                    margin-top: 60px;
                    &__small{
                        display: none;
                    }
                }
            }
            &__bot{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: 120px;
                &__headline{
                    color: #FFF;
                    text-align: center;
                    font-size: 28px;
                    font-family: Lato;
                    font-weight: 600; 
                    max-width: 1169px;
                }
                &__message{
                    color: #CAC6DD;
                    text-align: center;
                    font-size: 18px;
                    font-family: Lato; 
                    margin-top: 30px;
                }
                &__cards{
                    margin-top: 63px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 20px;
                    &__left{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        gap: 20px;
                    }
                    &__right{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        gap: 20px;
                    }
                }
            }
        }
        &__second{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            width: 100%;
            max-width: 1920px;
            margin-top: 120px;
            overflow: visible;
            position: relative;
            scroll-margin: 180px;

            &__top{
                display: flex;
                width: 100%;
                height: auto;
                margin: 0 auto;

                &__left{
                    width: calc(50% - 30px);
                    position: relative;

                    &__bg{
                        position: absolute;
                        z-index: 1;
                        right: -154px;
                        top: 0;
                        transform: translateY(-40%);
                        &__small{
                            display: none;
                        }
                    }
                    img{
                        width: 100%;
                        height: auto;
                        z-index: 2;
                    }
                }
                &__right{
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    justify-content: start;
                    width: calc(50% - 30px);
                    max-width: 577px;
                    margin-left: 60px;
                    &__headline{
                        color: #FFF;
                        font-size: 50px;
                        font-family: Lato;
                        font-weight: 700;
                        line-height: 60px; 
                        margin-bottom: 24px;
                    }
                    &__message{
                        color: #757185;
                        font-size: 18px;
                        font-family: Lato;
                        line-height: 24px;
                        margin-bottom: 24px;
                    }
                    &__item{
                        display: flex;
                        align-items: center;
                        color: #FFF;
                        font-size: 16px;
                        font-family: Inter;
                        line-height: 24px; 
                        margin-bottom: 24px;
                        img{
                            margin-right: 20px;
                        }
                        &:last-of-type{
                            margin-bottom: 0px;
                        }
                    }
                }
            }
            &__bot{
                display: flex;
                width: 100%;
                max-width: 1216px;
                height: auto;
                margin: 0 auto;
                margin-top: 56px;
                justify-content: space-between;
                z-index: 10;
                &__left{
                    display: flex;
                    justify-content: center;
                    gap: 20px;
                }
                &__right{
                    display: flex;
                    justify-content: center;
                    gap: 20px;
                }
            }
        }
        &__third{
            display: flex;
            justify-content: start;
            align-content: center;
            width: 100%;
            max-width: 1216px;
            margin: 0px auto;
            padding-top: 90px;
            &__left{
                display: flex;
                flex-direction: column;
                width: 100%;
                max-width: 647px;
                &__headline{
                    color: #FFF;
                    font-size: 50px;
                    font-family: Lato;
                    font-weight: 700;
                    line-height: 60px; 
                }
                &__message{
                    color: #757185;
                    font-size: 16px;
                    font-family: Lato;
                    line-height: 24px; 
                    margin-top: 24px;
                }
                &__item{
                    color: #FFF;
                    font-size: 16px;
                    font-family: Inter;
                    line-height: 24px; 
                    margin-top: 24px;
                    display: flex;
                    align-items: center;
                    z-index: 5;
                    img{
                        margin-right: 20px;
                    }
                }
            }
            &__right{
                width: 100%;
                max-width: 405px;
                margin-left: auto;
                padding-top: 144px;
                background-position:right;
                background-repeat: no-repeat;
                background-size:contain;
                &__message{
                    color: #757185;
                    font-size: 16px;
                    font-family: Lato;
                    line-height: 24px; 
                    z-index: 5;
                    position: relative;
                }
                &__img{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    img{
                        position: absolute;
                        z-index: 1;
                        top: -160px;
                        left: -570px;
                    }
                }
            }
        }
        &__fourth{
            width: 100%;
            max-width: 1216px;
            display: flex;
            flex-direction: column;
            margin: 0 auto;
            margin-top: 370px;
            padding: 0px 20px;
            &__top{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                &__left{
                    width: 100%;
                    max-width: 585px;
                    display: flex;
                    flex-direction: column;
                    margin-right: 50px;
                    &__headline{
                        color: #FFF;
                        font-size: 50px;
                        font-family: Lato;
                        font-weight: 700;
                        line-height: 60px; 
                    }
                    &__message{
                        color: #757185;
                        font-size: 18px;
                        font-family: Lato;
                        line-height: 24px; 
                        margin-top: 24px;
                    }
                    &__item{
                        display: flex;
                        align-items: center;
                        color: #FFF;
                        font-size: 16px;
                        font-family: Inter;
                        line-height: 24px; 
                        margin-top: 24px;
                        word-break: normal;
                        img{
                            margin-right: 20px;
                            width: 24px;
                            height: 24px;
                        }
                        span{
                            display: block;
                            word-break: normal;
                            width: calc(100% - 44px);
                        }
                    }
                }
            }
            &__bot{
                display: flex;
                justify-content: space-between;
                margin-top: 60px;
                &__left{
                    display: flex;
                    gap: 20px;
                }
                &__right{
                    display: flex;
                    gap: 20px;
                }
            }
        }
        &__payment{
            width: 100%;
            max-width: 1216px;
            display: flex;
            flex-direction: column;
            margin: 0 auto;
            margin-top: 80px;
            scroll-margin: 180px;
            &__message{
                color: #ADA8C3;
                text-align: center;
                font-size: 12px;
                font-family: Lato;
                line-height: 16px;
                letter-spacing: 1.8px;
                text-transform: uppercase; 
                margin-bottom: 16px;
            }
            &__headline{
                color: #FFF;
                text-align: center;
                font-size: 50px;
                font-family: Lato;
                font-weight: 700;
                line-height: 60px; 
                margin-bottom: 80px;
            }
            &__cards{
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
        &__started{
            width: 100%;
            max-width: 804px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            padding: 120px 20px 0px 20px;
            position: relative;
            margin-bottom: 140px;
            &__background{
                position: absolute;
                width: 711px;
                height: 711px;
                opacity: 0.7;
                z-index: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    z-index: 1;
                }
            }
            &__message{
                color: #ADA8C3;
                text-align: center;
                font-size: 12px;
                font-family: Lato;
                line-height: 16px;
                letter-spacing: 1.8px;
                text-transform: uppercase; 
                margin-bottom: 16px;
                z-index: 2;
            }
            &__headline{
                color: #FFF;
                text-align: center;
                font-size: 50px;
                font-family: Lato;
                font-weight: 700;
                line-height: 72px; 
                margin-bottom: 20px;
                z-index: 2;
            }
            &__now{
                color: #CAC6DD;
                text-align: center;
                font-size: 20px;
                font-family: Lato;
                font-weight: 600; 
                z-index: 2;
                margin-bottom: 43px;
            }
            &__button{
                padding: 15px 47px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                background: #6C5DD3;
                z-index: 2;
                cursor: pointer;
                transition: 0.3s ease-in-out;
                &:hover{
                    background: #9390FF; 
                }
                p{
                    color: #FFF;
                    font-size: 18px;
                    font-family: Inter;
                    text-transform: uppercase; 
                }
            }
        }
    }
}

.sectionCard__item{
    width: 289px;
    height: 315px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 27px;
    background-position: center;
    background-repeat: no-repeat;
    background-size:cover;
    position: relative;
    &__headline{
        color: #FFF;
        font-size: 28px;
        font-family: Lato;
        font-weight: 600;
        line-height: 36px; 
        margin-bottom: 16px;
    }
    &__message{
        color: #ADA8C3;
        font-size: 14px;
        font-family: Inter;
        line-height: 20px; 
    }
    img{
        position: absolute;
        bottom: 24px;
        right: 24px;
    }
}

.second__sectionBot__item{
    width: 289px;
    height: 186px;
    padding-top: 30px;
    border-top: 2px solid;
    border-color: #ADA8C3;
    transition: 0.3s ease;
    &__headline{
        color: #FFF;
        font-size: 24px;
        font-family: Lato;
        line-height: 32px; 
        margin-bottom: 48px;
        max-height: 32px;
    }
    &__message{
        color: #ADA8C3;
        font-size: 16px;
        font-family: Inter;
        line-height: 24px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
                line-clamp: 1;
        -webkit-box-orient: vertical;
        height: auto;
        max-height: 24px;
        transition: 0.3s ease;
    }
    &:hover{
        border-color: #AC6AFF;
        .second__sectionBot__item__message{
            max-height: 72px;
            -webkit-line-clamp:40;
                line-clamp: 40;
        }
    }
}

.payment__cards__item{
    display: flex;
    flex-direction: column;
    padding: 40px 24px;
    width: 392px;
    height: 697px; 
    border: 2px solid ;
    background: #0E0C15;
    z-index: 5;

    border-radius: 20px;
    border-color: #363049;
    position: relative;
    transition: 0.3s ease-in-out;
    &:hover{
        border-color: unset;
        .payment__cards__item__border{
            opacity: 1;
        }
    }
    .payment__cards__item__border{
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: 0.3s ease;
    }
    &__headline{
        font-size: 32px;
        font-family: Inter;
        line-height: 48px; 
    }
    &__message{
        color: rgba(255, 255, 255, 0.50);
        font-size: 16px;
        font-family: Inter;
        line-height: 24px; 
        margin-top: 14px;
    }
    &__price{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 40px;
        &__main{
            color: #FFF;
            font-size: 60px;
            font-family: Inter;
            font-weight: 500;
            span{
                color: #FFF;
                font-size: 40px;
                font-family: Lato;
                line-height: 60px;
            }
            &__monthly{
                font-size: 30px !important;
            }
        }
        &__discount{
            border-radius: 5px;
            background: #7ADB78;
            width: 83px;
            height: 36px;
            flex-shrink: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #FFF;
            font-size: 20px;
            font-family: Inter;
            font-weight: 500;
        }
    }
    &__getstarted{
        z-index: 3;
        margin-top: 42px;
        display: flex;
        width: 324px;
        height: 44px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0; 
        border: 1px solid #AC6AFF;
        border-radius: 5px;
        background: #0F0D17; 
        color: #FFF;
        font-size: 14px;
        font-family: Inter;
        line-height: 16px;
        letter-spacing: 0.6px;
        text-transform: uppercase; 
        cursor: pointer;
        transition: 0.3s ease;
        &:hover{
            background: #AC6AFF; 
        }
    }
    &__desc{
        padding-top: 20px;
        color: #FFF;
        font-size: 16px;
        font-family: Inter;
        line-height: 24px; 
        border-top: 1px solid #363049;
        margin-top: 20px;
        display: flex;
        align-items: center;
        img {
            margin-right: 20px;
        }
    }
}

.payment__cards__item--green{
    
    &:hover{
        border-image: linear-gradient(45deg, #B2EE7D, #353049) 1;
        border-radius: 50px;
    }
}

@media (max-width: 1270px) {
    .route__landing__wrapper__first__bot{
        padding: 0px 20px;
    }
    .route__landing__wrapper__first__bot__cards{
        flex-direction: column;
    }
    .route__landing__wrapper__first__top__headline{
        padding: 0px 20px;
    }
    .route__landing__wrapper__second__top{
        flex-direction: column;
        align-items: center;
        padding: 0px 20px;
    }
    .route__landing__wrapper__second__top__left{
        width: 100%;
        height: auto;
    }
    .route__landing__wrapper__second__top__left__bg{
        position: unset;
        transform: unset;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        &__big{
            display: none;
        }
        &__small{
            display: block;
            min-width: 300px;
            width: 100%;
            height: auto;
            max-width: 640px;
        }
    }
    .route__landing__wrapper__second__top__right{
        padding-top: 20px;
        margin-left: 0px;
        width: 100%;
        height: auto;
        max-width: 100%;
        word-break: normal; 
        p{
            span{
                display: block;
                word-break: normal;
                width: calc(100% - 44px);
            }
        }
    }
    .route__landing__wrapper__second__top__right__headline{
        text-align: center;
    }
    .route__landing__wrapper__second__bot{
        flex-direction: column;
    }
    .route__landing__wrapper__second__bot__left{
        justify-content: space-evenly;
    }
    .route__landing__wrapper__second__bot__right{
        justify-content: space-evenly;
    }
    .route__landing__wrapper__third{
        flex-direction: column;
        align-items: center;
        padding: 0px 20px;
    }
    .route__landing__wrapper__third__right__img{
        display: none;
    }
    .route__landing__wrapper__third__right{
        max-width: 100%;
        padding-top: 20px;
    }
    .route__landing__wrapper__third__left{
        width: 100%;
        height: auto;
        max-width: 100%;
    }
    .route__landing__wrapper__third__left__headline{
        text-align: center;
    }
    .route__landing__wrapper__fourth__top__left__headline{
        text-align: center;
    }
    .route__landing__wrapper__fourth__bot{
        flex-direction: column;
        align-items: center;
    }
    .route__landing__wrapper__payment{
        margin-top: 20px;
    }
    .route__landing__wrapper__payment__cards{
        flex-direction: column;
    }
    .route__landing__wrapper__payment__headline{
        margin-bottom: 20px;
    }
    .payment__cards__item{
        margin-bottom: 20px;
    }
    .route__landing__wrapper__fourth__top{
        flex-direction: column;
    }
    .route__landing__wrapper__fourth__top__left{
        width: 100%;
        height: auto;
        max-width: 100%;
        margin-right: 0px;
        margin-bottom: 40px;
    }
    .route__landing__wrapper__fourth__top__right{
        width: 100%;
        height: auto;
        max-width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 100%;
            max-width: 640px;
            height: auto;
        }
    }
    .route__landing__wrapper__first{
        background-position: top;
    }
    .route__landing__wrapper__first__bot__cards{
        margin-bottom: 40px;
    }
    .route__landing__wrapper__second{
        margin-top: 0px;
    }
    .route__landing__wrapper__fourth{
        margin-top: 20px;
    }
}

@media (max-width: 988px) {
    .route__landing__wrapper__first__top__main{
        width: 100%;
        height: auto;
    }
    .route__landing__wrapper__first__top__main__big{
        display: none;
    }
    .route__landing__wrapper__first__top__main__small{
        display: block;
        width: 100%;
        height: auto;
    }
    .payment__cards__item{
        max-width: 100%;
        height: auto;
    }
    .payment__cards__item__getstarted{
        max-width: 100%;
    }
    .payment__cards__item{
        &:hover {
            border-color: #363049;
            .payment__cards__item__border {
                display: none;
            }
        }
    }
    
}

@media (max-width: 768px) {
    .route__landing__wrapper__first__bot{
        margin-top: 40px;
    }
    .route__landing__wrapper__first__bot__cards__left{
        flex-direction: column;
    }
    .route__landing__wrapper__first__bot__cards__right{
        flex-direction: column;
    }
    .route__landing__wrapper__second__bot__left{
        flex-direction: column;
        align-items: center;
    }
    .route__landing__wrapper__second__bot__right{
        flex-direction: column;
        align-items: center;
    }
    .route__landing__wrapper__fourth__bot__left{
        flex-direction: column;
        align-items: center;
    }
    .route__landing__wrapper__fourth__bot__right{
        flex-direction: column;
        align-items: center;
    }
    .route__landing__wrapper__payment{
        padding: 0px 20px;
    }
    .payment__cards__item__price__main{
        font-size: 33px;
    }
}


