.route__automations {
    width: 100%;
    height: auto;


    display: grid;
    grid-template-columns: repeat(auto-fit, 280px);
    grid-template-rows: max-content;
    grid-auto-rows: max-content;
    gap: 40px;

    color: white;

    body.light & {
        color: black;
    }
    &__item {
        width: 100%;
        height: auto;
        padding: 20px;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;

        background-color: #242731;
        box-shadow: 0px 0px 25px 2px rgba(0, 0, 0, 0.3);
        border: 1px solid transparent;
        border-radius: 5px;

        cursor: pointer;
        transition: border 0.3s ease;

        body.light & {
            background-color: #f8f9fa;
        }
        &:hover {
            border: 1px solid #6C5DD3;
        }
        & > * {
            text-align: center;
        }
    }
}