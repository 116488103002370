.route__reports__designersReport {
    width: 100%;
    height: auto;
    color: wheat;

    body.dark & {
        color: black;
    }
    &__kpi {
        width: 100%;
        height: auto;
        margin-bottom: 32px;

        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: auto;
        grid-auto-rows: auto;
        row-gap: 20px;

        &__item {
            width: 100%;
            height: 120px;

            display: flex;
            flex-direction: column;
            justify-content: space-between;

            padding: 24px 40px;
            border: 1px solid #373A43;

            body.light & {
                border-color: #adadad;
            }
            &__top {
                color: #FFF;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;

                body.light & {
                    color: black;
                }
            }
            &__bottom {
                color: #FFF;
                font-family: Inter;
                font-size: 28px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 1px;

                body.light & {
                    color: black;
                }
            }
        }
    }
}