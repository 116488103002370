@import "../../../../scss/globals.scss";

.route__admin__globalIntegrationsHJ {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 10px;

    &__buttons {
        width: 100%;
        height: auto;
        margin-bottom: 20px;

        display: flex;
        justify-content: space-between;

        &__left {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            &__selected {
                width: auto;
                max-width: 0;
                height: auto;
                padding: 10px 0;
                overflow: hidden;
                word-break: keep-all;
                white-space: nowrap;

                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;

                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;

                color: #ffffff;
                background: #232630;
                border: 1px solid transparent;
                border-radius: 5px;

                body.light & {
                    background-color: #f8f9fa;
                    color: black;
                }
                transition:
                    padding 0.3s ease,
                    max-width 0.3s ease,
                    border 0.3s ease;

                &__btn {
                    width: 18px;
                    height: 18px;
                    background-size: 100%;
                    position: relative;
                    cursor: pointer;

                    @include button-hover-animation(22px);

                    body.light & {
                        filter: invert(1) hue-rotate(180deg);
                    }
                }
                &--active {
                    max-width: 300px;
                    padding: 10px 18px;
                    border-color: #6c5dd3;
                }
            }
        }
        &__right {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;

            &__btn {
                width: auto;
                height: auto;
                padding: 10px 16px;

                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 8px;

                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;

                color: #ffffff;
                background-color: $button-primary;
                border-radius: 5px;
                cursor: pointer;

                transition: background-color 0.3s ease;

                &:hover {
                    background-color: $button-primary-hover;
                }
                & > img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}