@import "../../../scss/mixins.scss";

.route__admin__apiTokens {
    width: 100%;
    height: auto;

    &__tableWrap {
        width: auto;
        max-width: 100%;
        height: auto;
        padding-bottom: 5px;
        overflow-y: auto;
        filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.25));

        &__pills {
            display: flex;
            align-items: center;
            gap: 10px;
            flex-wrap: wrap;
    
            & > p {
                color: #FFF;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
            }
            & > span {
                align-items: center;
                background-color: #434295;
                color: white;
                border-radius: 5px;
                display: flex;
                font-size: 12px;
                height: 20px;
                justify-content: center;
                margin: 0 5px;
                padding: 10px;
                width: auto;
            }
        }
    }
    &__btns {
        width: 100%;
        height: auto;
        margin-bottom: 20px;

        display: flex;
        justify-content: space-between;

        &__left {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            &__selected {
                width: auto;
                max-width: 0;
                height: auto;
                padding: 10px 0;
                overflow: hidden;
                word-break: keep-all;
                white-space: nowrap;

                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;

                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;

                color: #ffffff;
                background: #232630;
                border: 1px solid transparent;
                border-radius: 5px;

                transition:
                    padding 0.3s ease,
                    max-width 0.3s ease,
                    border 0.3s ease;

                body.light & {
                    color: black;
                    background-color: transparent;

                    & > div {
                        filter: invert(1) hue-rotate(180deg);
                    }
                }
                &__btn {
                    width: 18px;
                    height: 18px;
                    background-size: 100%;
                    position: relative;
                    cursor: pointer;

                    @include button-hover-animation(22px);
                }
                &--active {
                    max-width: 300px;
                    padding: 10px 18px;
                    border-color: #6c5dd3;
                }
            }
        }
        &__right {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;

            &__btn {
                width: auto;
                height: auto;
                padding: 10px 16px;

                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 8px;

                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;

                color: #ffffff;
                background-color: $button-primary;
                border-radius: 5px;
                cursor: pointer;

                transition: background-color 0.3s ease;

                &:hover {
                    background-color: $button-primary-hover;
                }
                & > img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}