@import "../../../scss/mixins.scss";

.route__user__offers {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;

    &__filters {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;

        gap: 20px;
    }
    &__tableWrap {
        width: auto;
        max-width: 100%;
        height: auto;
        padding-bottom: 5px;
        overflow-y: auto;
        filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.25));

        &__country {
            display: flex;
            align-items: center;
            gap: 10px;

            & > img {
                width: 24px;
                height: auto;
            }
        }
        & .customComponents__table__data {
            transition: background-color 0.3s ease;
        }
    }
    &__btns {
        width: 100%;
        height: auto;
        margin-bottom: 20px;
        margin-top: 20px;

        display: flex;
        justify-content: space-between;

        &__left {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 20px;

            &__item {
                display: flex;
                align-items: center;
                gap: 5px;

                color: #FFF;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                body.light & {
                    color: black;
                }
            }
            &__selected {
                width: auto;
                max-width: 0;
                height: auto;
                padding: 10px 0;
                overflow: hidden;
                word-break: keep-all;
                white-space: nowrap;

                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;

                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;

                color: #ffffff;
                background: #232630;
                border: 1px solid transparent;
                border-radius: 5px;

                transition:
                    padding 0.3s ease,
                    max-width 0.3s ease,
                    border 0.3s ease;

                body.light & {
                    background-color: #f8f9fa;
                    color: black;
                }
                &__btn {
                    width: 18px;
                    height: 18px;
                    background-size: 100%;
                    position: relative;
                    cursor: pointer;

                    @include button-hover-animation(22px);

                    body.light & {
                        filter: invert(1) hue-rotate(180deg);
                    }
                }
                &--active {
                    max-width: 300px;
                    padding: 10px 18px;
                    border-color: #6c5dd3;
                }
            }
        }
        &__right {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;

            &__settings {
                width: 40px;
                height: 40px;
                margin-left: 10px;

                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                @include button-hover-animation(32px);

                & > img {
                    width: 24px;
                    height: 24px;
                }
            }
            &__btn {
                width: auto;
                height: auto;
                padding: 10px 16px;

                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 8px;

                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;

                color: #ffffff;
                background-color: $button-primary;
                border-radius: 5px;
                cursor: pointer;

                transition: background-color 0.3s ease;

                &:hover {
                    background-color: $button-primary-hover;
                }
                & > img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
    &__add {
        width: 100%;
        height: 100%;
        background: rgba(35, 38, 48, 0.7);

        display: flex;
        justify-content: center;
        align-items: center;
        cursor: default;

        &__wrap {
            width: 500px;
            height: auto;
            max-height: 100%;
            overflow: auto;

            padding: 40px;
            color: #fff;
            background-color: #2c2e38;

            body.light & {
                background-color: #fff;
                color: black;
            }
            &__head {
                width: 100%;
                height: auto;
                margin-bottom: 30px;

                display: flex;
                justify-content: space-between;
                align-items: center;

                &__left {
                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                    letter-spacing: 1px;

                    color: #ffffff;

                    body.light & {
                        color: black;
                    }
                }
                &__right {
                    width: 30px;
                    height: 30px;
                    position: relative;
                    cursor: pointer;

                    @include button-hover-animation(34px);

                    body.light & {
                        filter: invert(1) hue-rotate(180deg);
                    }
                }
            }
            &__input {
                width: 100%;
                height: auto;
                margin-bottom: 20px;

                display: flex;
                flex-direction: column;
                gap: 15px;

                &__sources {
                    width: 100%;
                    height: auto;

                    display: flex;
                    align-items: center;
                    gap: 10px;

                    &__source {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 5px;
                    }
                }
                & > p {
                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;

                    color: #ffffff;

                    body.light & {
                        color: black;
                    }
                }
                & > input {
                    width: 100%;
                    height: 54px;
                    padding: 0 20px;

                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 19px;

                    outline: none;
                    color: white;
                    background: #373a43;
                    border-radius: 5px;
                    border: 1px solid transparent;

                    transition: border 0.3s ease;

                    body.light & {
                        background-color: #fff;
                        border-color: #E2E2E2;
                        color: black;
                    }
                    &:focus, body.light &:focus {
                        border: 1px solid #6c5dd3;
                    }
                }
                &--error > input,
                &--error > textarea {
                    border: 1px solid #ff754c;

                    body.light & {
                        border: 1px solid #ff754c;
                    }
                }
                &--text {
                    & > textarea {
                        width: 100%;
                        height: auto;
                        min-height: 100px;
                        padding: 18px 20px;
                        resize: vertical;

                        font-family: "Inter";
                        font-style: normal;
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 19px;

                        outline: none;
                        color: white;
                        background: #373a43;
                        border-radius: 5px;
                        border: 1px solid transparent;

                        transition: border 0.3s ease;

                        &:focus {
                            border: 1px solid #6c5dd3;
                        }
                        body.light & {
                            color: black;
                            background-color: #e9e8e8;
    
                            &:focus {
                                border: 1px solid #6C5DD3;
                            }
                        }
                    }
                }
                &--split {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: auto;
                    grid-auto-rows: auto;
                    gap: 0;
                    row-gap: 15px;

                    & > p {
                        grid-column: 1 / span all;
                    }
                    & > input {
                        width: calc(100% - 15px);

                        &:last-child {
                            justify-self: end;
                        }
                    }
                }
                &--checkbox {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 10px;
                }
                &__responsible {
                    display: grid;
                    grid-template-columns: 1fr 150px 36px;
                    column-gap: 10px;

                    &__image {
                        display: flex;
                        align-items: center;
                        gap: 5px;

                        & > img {
                            width: 16px;
                            height: auto;
                        }
                    }
                    & .styledComponent__button {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        
                        & > img {
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
            }
            &__buttons {
                width: 100%;
                height: auto;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 20px;

                &__btn {
                    width: auto;
                    height: 43px;
                    padding: 0 40px;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    color: #fff;
                    background-color: $button-primary;
                    border-radius: 5px;
                    cursor: pointer;

                    transition: background-color 0.3s ease;

                    &:hover {
                        background-color: $button-primary-hover;
                    }
                    &--secondary {
                        background-color: $button-secondary;

                        &:hover {
                            background-color: $button-secondary-hover;
                        }
                    }
                }
            }
        }
        &__infoP {
            margin-top: 20px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #ff450d;
            opacity: 0;
            transition: opacity 0.3s ease;
        }
    }
    &__gallery {
        &__wrap {
            &.genericModal__wrap {
                min-width: 800px;
                max-width: 90%;
                width: calc(390px * 4 - 20px);
            }
            &__head {
                &__middle {
                    margin-left: auto;
                    margin-right: 20px;
                }
            }
            &__images {
                width: auto;
                height: auto;

                display: grid;
                grid-template-columns: repeat(auto-fit, 350px);
                grid-template-rows: minmax(fit-content, 200px);
                grid-auto-rows: minmax(fit-content, 200px);
                column-gap: 20px;
                row-gap: 20px;

                &__img {
                    width: 100%;
                    height: 100%;
                    padding: 20px;

                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: column;
                    gap: 10px;

                    border-radius: 10px;
                    background: #373A43;
                    box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.25); 

                    &__name {
                        color: #FFF;
                        font-family: Inter;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                        -webkit-line-clamp: 1;
                        overflow: hidden;
                        width: 100%;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        margin-bottom: 3px;
                    }
                    &__date {
                        color: #B2B3BD;
                        font-family: Inter;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                        -webkit-line-clamp: 1;
                        overflow: hidden;
                        width: 100%;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        margin-bottom: auto;
                    }
                    & > img {
                        min-width: 64px;
                        max-width: 100%;
                        max-height: calc(100% - 53px);
                        min-height: 64px;

                        cursor: pointer;
                        margin: auto 0;
                    }
                    &__buttons {
                        width: 100%;
                        height: auto;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        gap: 10px;
                    }
                }
            }
        }
    }
}


.route__user__offers__settings {
    width: 100%;
    height: 100%;
    position: relative;

    cursor: default;
    background-color: rgba(35, 38, 48, 0.8);

    &__wrap {
        position: absolute;
        width: 450px;
        height: 100%;
        top: 0;
        right: -100%;
        padding: 20px;

        display: grid;
        grid-template-rows: auto 1fr;
        grid-template-columns: 1fr;

        background-color: #2C2E38;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);

        body.light & {
            background-color: #fff;
        }
        &__top {
            width: 100%;
            height: auto;
            padding-bottom: 25px;

            display: flex;
            justify-content: space-between;
            align-items: center;

            border-bottom: 1px solid #373A43;

            body.light & {
                border-color: #E2E2E2;
            }
            &__left {
                color: #FFF;
                font-family: Inter;
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                body.light & {
                    color: black;
                }
            }
            &__right {
                background-color: $button-primary;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 3px;

                cursor: pointer;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: $button-primary-hover;
                }
            }
        }
        &__content {
            width: 100%;
            height: 100%;
            overflow: auto;
            color: white;

            padding: 15px 0;

            body.light & {
                color: black;
            }
            &__checkbox {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
            }
            &__split {
                display: grid;
                grid-template-rows: auto;
                grid-template-columns: 1fr 180px;
                align-items: center;
                gap: 10px;
                margin-top: 20px;

                & > .component__filters__filterColumn {
                    padding: 10px 5px;
                    height: 37px;
                }
                & > .route__user__offers__settings__wrap__content__input {
                    margin: 0;
                }
            }
            &__input {
                width: 100%;
                height: auto;
                padding: 10px 5px;
                border-radius: 5px;
                margin-top: 10px;

                display: flex;
                justify-content: center;
                align-items: center;
                
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                
                color: #FFF;
                background-color: $button-primary;

                transition: background-color 0.3s ease;
                cursor: pointer;

                &:hover {
                    background-color: $button-primary-hover;
                }
            }
            &__info {
                margin-top: 20px;

                color: #B2B3BD;
                font-family: Inter;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                text-align: center;
            }
        }
    }
    &__add {
        &__profileDropdown {
            display: flex;
            align-items: center;
            gap: 10px;

            & > img {
                width: 20px;
                height: 20px;

                border-radius: 50%;
            }
        }
    }
}

.route__user__offers__selectUsers {
    width: 100%;
    height: 100%;
    position: relative;

    cursor: default;
    background-color: rgba(35, 38, 48, 0.8);

    &__wrap {
        position: absolute;
        width: 450px;
        height: 100%;
        top: 0;
        right: -100%;
        padding: 20px;

        display: grid;
        grid-template-rows: auto 1fr;
        grid-template-columns: 1fr;

        background-color: #2C2E38;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);

        body.light & {
            background-color: #fff;
        }
        &__top {
            width: 100%;
            height: auto;
            padding-bottom: 25px;

            display: flex;
            justify-content: space-between;
            align-items: center;

            border-bottom: 1px solid #373A43;

            body.light & {
                border-color: #E2E2E2;
            }
            &__left {
                color: #FFF;
                font-family: Inter;
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                body.light & {
                    color: black;
                }
            }
            &__right {
                background-color: $button-primary;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 3px;

                cursor: pointer;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: $button-primary-hover;
                }
            }
        }
        &__content {
            width: 100%;
            height: 100%;
            overflow: auto;
            color: white;

            padding: 15px 0;

            body.light & {
                color: black;
            }
            &__search {
                margin-bottom: 20px;
            }
            &__buttons {
                display: grid;
                grid-template-rows: auto;
                grid-template-columns: repeat(2, 1fr);
                gap: 10px;
                margin-bottom: 20px;

                & > * {
                    width: 100%;

                    display: flex;
                    align-items: center;
                    gap: 10px;
                }
            }
            &__users {
                display: flex;
                flex-direction: column;
                gap: 10px;

                &__user {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                }
            }
            &__presets {
                width: 100%;
                height: auto;
                margin-bottom: 30px;
                padding: 12px 0;

                border-top: 1px solid #373A43;
                border-bottom: 1px solid #373A43;

                body.light & {
                    border-color: #E2E2E2;
                }
                &__text {
                    margin-bottom: 20px;

                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    &__left {
                        color: #FFF;
                        font-family: Inter;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 140%;
                        letter-spacing: 0.7px;

                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 10px;
                    }
                    &__right {
                        &__button {
                            width: 100%;
                            height: 44px;
                            padding: 0 16px;
        
                            display: flex;
                            align-items: center;
                            gap: 8px;
        
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 17px;
                            
                            color: #FFFFFF;
                            background-color: $button-secondary;
                            border-radius: 5px;
                            cursor: pointer;
        
                            transition: background-color 0.3s ease;
        
                            & > img {
                                width: 24px;
                                height: 24px;
                            }
                            &:hover {
                                background-color: $button-secondary-hover;
                            }
                        }
                    }
                }
                &__items {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    &__item {
                        width: 100%;
                        height: auto;

                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        background-color: $button-secondary;
                        border-radius: 5px;
                        font-size: 12px;
                        padding: 10px;

                        cursor: pointer;
                        transition: background-color 0.3s ease;

                        &:hover {
                            background-color: $button-secondary-hover;
                        }
                        &__name {
                            color: #FFF;
                            font-family: Inter;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 140%;
                            letter-spacing: 0.7px;
                        }
                        &__close {
                            width: 20px;
                            height: 20px;

                            background-size: 100% 100%;

                            @include button-hover-animation(30px);
                        }
                    }
                }
            }
        }
    }
}

.route__user__offers__datasets {
    position: relative;
    width: 100%;
    height: auto;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(700px, 1fr));
    grid-template-rows: max-content;
    grid-auto-rows: max-content;
    gap: 20px;

    &__filters {
        width: 100%;
        height: auto;

        grid-column: 1 / -1;

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        column-gap: 20px;
    }
    &__item {
        padding: 10px;
        background-color: #242731;
        box-shadow: 0 0 4px 2px rgba(0,0,0,0.3);

        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;

        body.light & {
            background-color: #fff;
            color: black;
        }
        & > p {
            display: flex;
            align-items: center;
            gap: 5px;
            flex-wrap: wrap;

            & span:nth-child(2) {
                color: gray;
            }
        }
        &__withImage {
            display: flex;
            align-items: center;
            gap: 5px;

            & > img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
            }
        }
        &__line {
            width: 100%;
            height: 1px;
            background-color: gray;

            margin-top: auto;
        }
        &__buttons {
            width: 100%;
            height: auto;

            display: flex;
            flex-wrap: wrap;
            gap: 10px;

            & > button {
                height: 30px;

                display: flex;
                align-items: center;
                gap: 5px;

                & > img {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
}