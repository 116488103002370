@import "../../scss/mixins.scss";

.route__landingHeader{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    padding-top: 40px;
    background-color: #0E0C15;
    position: fixed;
    top: 0;
    z-index: 99;

    &__wrapper{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #302C46;
        padding-left: 20px;
        padding-right: 20px;

        &__inner{
            width: 100%;
            max-width: 1216px;
            min-height: 77px;
            height: auto;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            &__logo{
                &:hover{
                    cursor: pointer;
                }
            }
            &__nav{
                display: flex;
                width: auto;
                gap: 50px;
                height: 49px;
                align-items: center;
                margin-left: 50px;

                a{
                    text-decoration: none;
                    color: rgba(255, 255, 255, 0.50);
                    font-size: 14px;
                    font-family: Inter;
                    line-height: 20px;
                    text-transform: uppercase;
                    transition: 0.3s ease-in-out;

                    &:hover{
                        cursor: pointer;
                        color: #FFF;
                    }
                }
            }
            &__right{
                display: flex;
                width: auto;
                gap: 56px;
                height: 49px;
                align-items: center;
                a{
                    text-decoration: none;
                    color: rgba(255, 255, 255, 0.50);
                    font-size: 14px;
                    font-family: Inter;
                    line-height: 16px;
                    letter-spacing: 0.6px;
                    text-transform: uppercase; 
                    transition: 0.3s ease-in-out;
                    &:hover{
                        cursor: pointer;
                        color: #FFF;
                    }
                }
                &__login{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 13px 38px;
                    border-radius: 5px;
                    background: #6C5DD3;
                    cursor: pointer;
                    transition: 0.3s ease-in-out;
                    &:hover{
                        background: #9390FF; 
                    }
                    p{
                        color: #FFF;
                        font-size: 16px;
                        font-family: Inter;
                        text-transform: uppercase; 
                    }
                }
            }
            &__mobile{
                display: none;
            }
        }
    }
}

@media (max-width: $breakpoint-large) {
    .route__landingHeader{
        &__wrapper{
            &__inner{
                &__nav{
                    margin-left: 0px;
                    gap: 18px;
                }
                &__right{
                    gap: 18px;
                }
            }
        }
    }
}

@media (max-width: $breakpoint-medium) {
    .route__landingHeader{
        padding-top: 30px;
        &__wrapper{
            border-bottom: unset;
            &__inner{
                min-height: 60px;
                align-items: center;
                &__nav{
                    margin-left: 0px;
                    gap: 18px;
                }
                &__right{
                    gap: 18px;
                }
                &--hideOnMobile{
                    display: none;
                }
                &__mobile{
                    display: block;
                    
                    &__box{
                        width: 30px;
                        height: 30px;
                        display: flex;
                        justify-content: space-evenly;
                        align-items: center;
                        flex-direction: column;
                        overflow: hidden;
                        &:hover{
                            cursor: pointer;
                        }
                        &__line{
                            width: 100%;
                            max-width: 30px;
                            height: 2px;
                            background-color: #FFF;
                            border-radius: 5px;
                            transition: 0.3s ease-in-out;
                            &--first{
                                transform : rotate(0deg);
                                width : 100%;
                                position : relative;
                            }
                            &--middle{
                                opacity: 1;
                            }
                            &--last{
                                transform : rotate(0deg);
                                width : 100%;
                                position : relative;
                            }
                        }
                        .inner__mobile__box__line--first--opened{
                            transform :rotate(45deg);
                            width : 35px;
                            position : absolute;
                        }
                        .inner__mobile__box__line--middle--opened{
                            opacity: 0;
                        }
                        .inner__mobile__box__line--last--opened{
                            transform : rotate(-45deg);
                            width : 35px;
                            position : absolute;
                        }
                    }
                    &__content{
                        position: absolute;
                        background-color: white;
                        width: 100%;
                        top: 82px;
                        left: 0;
                        max-height: 0px;
                        padding: 0px;
                        overflow: hidden;
                        background: #0E0C15; 
                        display: flex;
                        flex-direction: column;
                        border-bottom-left-radius: 10px;
                        border-bottom-right-radius: 10px;
                        transition: padding 0.3s ease, max-height 0.3s ease;
                        a{
                            color: rgba(255, 255, 255, 0.50);
                            font-size: 14px;
                            font-family: Inter;
                            line-height: 20px;
                            text-transform: uppercase; 
                            text-decoration: none;
                            margin-bottom: 40px;
                            cursor: pointer;
                            transition: 0.3s ease-in-out;
                            &:hover{
                                color: #FFF;
                            }
                        }
                        &--new{
                            margin-top: 45px;
                        }
                        &__login{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 13px 38px;
                            border-radius: 5px;
                            background: #6C5DD3;
                            cursor: pointer;
                            transition: 0.3s ease-in-out;
                            max-width: 120px;
                            &:hover{
                                background: #9390FF; 
                            }
                            p{
                                color: #FFF;
                                font-size: 16px;
                                font-family: Inter;
                                text-transform: uppercase; 
                            }
                        }
                        
                    }
                    .route__landingHeader__wrapper__inner__mobile__content--opened{
                        max-height : 700px;
                        padding : 40px 20px 20px 20px;
                    }
                    .route__landingHeader__wrapper__inner__mobile__box--opened{
                        justify-content: center;
                    }
                }
            }
        }
    }
}