.modals__selectUsersModal {
    &__dropdown {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 1fr max-content;
        align-items: center;
        gap: 10px;
    }
    &__users {
        width: 100%;

        margin-top: 20px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;

        &__user {
            width: 100%;

            display: grid;
            grid-template-columns: 1fr max-content;
            grid-template-rows: auto;
            align-items: center;
        }
    }
}