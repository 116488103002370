@import "../../../scss/globals.scss";

.modals__campaignsDashboard {
    all: initial;
    display: block;
    width: 100%;
    height: 100%;
    cursor: default;
    position: relative;
    background-color: rgba(35, 38, 48, 0.8);

    &__wrap {
        position: absolute;
        top: 0;
        right: -100%;
        width: calc(100% - 84px);
        height: 100vh;
        background-color: #2c2e38;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);

        display: grid;
        grid-template-rows: max-content 1fr;
        grid-template-columns: 1fr;

        body.light & {
            background-color: #f8f9fa;
        }
        &__header {
            width: 100%;
            height: 102px;
            padding: 0 50px;
            padding-bottom: 0px;
            padding-bottom: 25px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            border-bottom: 1px solid #373a43;

            body.light & {
                border-color: #E2E2E2;
            }
            &__left {
                color: #fff;
                font-family: Inter;
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                body.light & {
                    color: black;
                }
            }
            &__right {
                width: 30px;
                height: 30px;
                cursor: pointer;
                position: relative;

                @include button-hover-animation(34px);

                body.light & {
                    filter: invert(1) hue-rotate(180deg);
                }
            }
        }
        &__content {
            width: 100%;
            height: 100%;
            padding: 25px 50px;

            overflow: auto;

            display: grid;
            grid-template-columns: auto;
            grid-template-rows: max-content max-content 1fr;

            &__filters {
                display: grid;
                grid-template-rows: auto;
                grid-template-columns: 1fr 1fr;
                column-gap: 20px;
                margin-bottom: 10px;
            }
            &__tabs {
                width: 100%;
                height: auto;
                margin-bottom: 20px;
        
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 20px;
        
                &__tab {
                    padding: 10px 18px;

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;
        
                    color: #808191;
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
        
                    border: 1px solid transparent;
                    border-radius: 5px;
        
                    transition: border-color 0.3s ease, background-color 0.3s ease, color 0.3s ease;
                    user-select: none;
                    cursor: pointer;
        
                    &:hover {
                        border-color: #6C5DD3;
                    }
                    &--active {
                        background-color: #6C5DD3;
                        border-color: #6C5DD3;
                        color: #fff;
                    }
                }
            }
            &__activeContent {
                width: 100%;
                height: 100%;

                overflow: auto;
                padding: 10px 0;

                &__chart {
                    width: 100%;
                    height: 100%;
                    max-height: 100%;
                    position: relative;

                    &__spinner {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
            
                        display: flex;
                        justify-content: center;
                        align-items: center;
            
                        background-color: #2c2e384f;
            
                        opacity: 0;
                        pointer-events: none;
                        transition: opacity 0.3s ease;
            
                        &--active {
                            opacity: 1;
                            pointer-events: all;
                        }
                    }
                }
            }
        }
    }
}