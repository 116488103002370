@import "../../../scss/mixins.scss";

.route__admin__siteEvents {
    width: 100%;
    height: auto;

    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        &__left {
            &__selected {
                width: auto;
                max-width: 0;
                height: auto;
                padding: 10px 0;
                overflow: hidden;
                word-break: keep-all;
                white-space: nowrap;

                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;

                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                
                color: #FFFFFF;
                background: #232630;
                border: 1px solid transparent;
                border-radius: 5px;

                transition: padding 0.3s ease, max-width 0.3s ease, border 0.3s ease;

                body.light & {
                    background-color: #f8f9fa;
                    color: black;
                }
                &__btn {
                    width: 18px;
                    height: 18px;
                    background-size: 100%;
                    position: relative;
                    cursor: pointer;

                    @include button-hover-animation(22px);

                    body.light & {
                        filter: invert(1) hue-rotate(180deg);
                    }
                }
                &--active {
                    max-width: 300px;
                    padding: 10px 18px;
                    border-color: #6C5DD3;
                }
            }
        }
    }
    &__eventValues {
        &__add {
            width: 100%;
            margin-bottom: 20px;

            display: grid;
            grid-template-columns: repeat(2, 1fr) max-content;
            grid-template-rows: auto;
            column-gap: 20px;

            & > * {
                height: 100%;
            }
        }
        &__table {
            margin-bottom: 20px;
        }
    }
    &__pills {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;

        & > strong {
            font-weight: normal;
            color: white;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;

            body.light & {
                color: black;
            }
        }
        & > span {
            align-items: center;
            background-color: #434295;
            color: white;
            border-radius: 5px;
            display: flex;
            font-size: 12px;
            height: 20px;
            justify-content: center;
            padding: 10px;
            width: auto;
        }
    }
}