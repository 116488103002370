@keyframes dashboard-progress-marque-anim {
    0% {left: -100%}
    100% {left: 100%}
}

.route__reports__realtime {
    width: 100%;
    height: auto;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    gap: 20px;

    overflow-y: hidden;

    &__filters {
        width: 100%;
        height: auto;
        grid-column: 1 / 4;

        display: flex;
        justify-content: space-between;
        align-items: center;

        &__left, &__right {
            width: max-content;
            height: auto;

            display: flex;
            align-items: center;
            gap: 10px;
        }
        &__right {
            justify-content: flex-end;
        }
    }
    &__progress {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 5px;

        &--marque > div {
            width: 30% !important;

            animation-name: dashboard-progress-marque-anim;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            animation-fill-mode: both;
        }
        & > div {
            position: absolute;
            top: 0;
            left: 0;
            width: 0%;
            height: 100%;

            background-color: #6C5DD3;

            transition: width 0.3s linear, left 0.3s linear;
        }
    }
    &__row {
        width: 100%;
        min-width: 0;
        height: auto;

        display: flex;
        flex-direction: column;
        gap: 20px;

        &__item {
            width: 100%;
            height: max-content;
            padding: 10px;

            display: flex;
            flex-direction: column;
            gap: 10px;

            background-color: #2c2e38;
            color: white;
            box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16), 0 0px 3px 0 rgba(0, 0, 0, 0.12);

            body.light & {
                color: black;
                background-color: white;
            }
            &__box {
                width: max-content;
                height: auto;
                margin: 0 auto;

                display: flex;
                justify-content: center;
                align-items: center;
                padding: 20px;
                border: 1px solid #6C5DD3;
                border-radius: 5px;
            }
            &__stat {
                color: rgb(175, 173, 173);
                
                body.light & {
                    color: rgb(99, 99, 99);
                }
                & > span {
                    color: #a395ff;
                    font-weight: bold;
                }
            }
        }
    }
}