@import "../../../scss/mixins.scss";

.modal__previewImage {
    all: initial;
    width: 100%;
    height: 100%;
    background: rgba(35, 38, 48, 0.7);

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;

    &__wrap {
        width: 500px;
        height: auto;
        max-height: 100%;
        overflow: auto;
        position: relative;

        padding: 40px;
        color: #fff;
        background-color: #2C2E38;
        box-shadow: 0 0 8px 2px rgba(0,0,0,0.3);

        body.light & {
            color: black;
            background-color: #fff;
        }
        &__head {
            width: 100%;
            height: auto;
            margin-bottom: 30px;
            
            display: flex;
            justify-content: space-between;
            align-items: center;
    
            &__left {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                letter-spacing: 1px;
                
                color: #FFFFFF;

                body.light & {
                    color: black;
                }
            }
            &__right {
                width: 30px;
                height: 30px;
                position: relative;
                cursor: pointer;
    
                @include button-hover-animation(34px);

                body.light & {
                    filter: invert(1) hue-rotate(180deg);
                }
            }
        }
        &__img {
            width: 100%;
            height: auto;
    
            & > img {
                width: auto;
                max-width: 100%;
            }
        }
    }
}