@import "../../../scss/mixins.scss";

.route__user__creatives {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    gap: 10px;
}
.route__user__creatives__st {
    display: flex;
    flex-direction: column;
    color: white;

    &__filters {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 1fr 350px 200px 350px auto;
        gap: 20px;
        align-items: center;
        margin-bottom: 20px;

        & .styledComponent__button  {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;

            & > img {
                width: 20px;
                height: 20px;
                transform: rotate(45deg);
            }
        }
    }
    &__content {
        position: relative;
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: repeat(auto-fit, 250px);
        grid-template-rows: max-content;
        grid-auto-rows: max-content;
        grid-column-gap: 20px;
        column-gap: 20px;
        grid-row-gap: 20px;
        row-gap: 20px;

        &__spinner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            min-height: 100vh;
            height: 100%;

            background-color: rgba(0,0,0,0.3);

            opacity: 0;
            pointer-events: none;

            transition: opacity 0.3s ease;

            & > * {
                pointer-events: none;
            }
        }
        &__item {
            width: 100%;
            height: 100%;
            padding: 20px;

            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            gap: 10px;

            border-radius: 10px;
            background: #373A43;
            box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.25);
            border: 1px solid transparent;
            cursor: pointer;
            transition: box-shadow 0.3s ease, border-color 0.3s ease;

            body.light & {
                background-color: #fff;
                border-color: #E2E2E2;
            }
            &:hover {
                box-shadow: 0px 0px 36px 0px rgba(108, 93, 211, 0.25);
                border-color: gray;
            }
            &__ctaCopies {
                width: 100%;
                height: auto;
                margin: 10px 0;

                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 10px;

                &__copy {
                    display: flex;
                    gap: 5px;

                    font-size: 14px;
                    max-width: 100%;

                    & > span {
                        width: 100%;
                        word-break: break-word;
                    }
                }
            }
            &__header {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                font-size: 14px;
                color: gray;
            }
            &__name {
                color: #FFF;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                -webkit-line-clamp: 1;
                overflow: hidden;
                width: 100%;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-bottom: 3px;

                body.light & {
                    color: black;
                }
            }
            &__date {
                color: #B2B3BD;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                -webkit-line-clamp: 1;
                overflow: hidden;
                width: 100%;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-bottom: auto;

                display: flex;
                align-items: center;
                gap: 10px;

                & > span {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                }
            }
            & > img {
                min-width: 64px;
                max-width: 100%;
                max-height: calc(100% - 53px);
                min-height: 64px;

                margin: auto 0;
            }
            &__buttons {
                width: 100%;
                height: auto;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 10px;

                & > img {
                    margin-right: auto;
                }
                &__btn {
                    width: 24px;
                    height: 24px;

                    @include button-hover-animation(32px);
                    background-size: 100% 100%;
                    cursor: pointer;

                    body.light & {
                        filter: invert(1) hue-rotate(180deg);
                    }
                    &.cloned-element {
                        background-image: none !important;
                    }
                }
            }
        }
        &__infoP {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #FF450D;
        }
    }
    &__addCreative {

        & .genericModal__wrap__input--split {
            display: grid;
            grid-template-rows: auto;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 20px;

            & > p {
                grid-column: 1 / span 2;
            }
        }
        &__pills {
            width: 100%;
            height: auto;

            display: flex;
            gap: 10px;
            flex-wrap: wrap;

            &__pill {
                background-color: $button-secondary;
                padding: 5px 10px;
                border-radius: 5px;
                cursor: pointer;

                display: flex;
                align-items: center;
                gap: 10px;
                transition: background-color 0.3s ease;

                & > img {
                    height: 12px;
                }
                &:hover {
                    background-color: $button-secondary-hover;
                }
            }
        }
        &__uploadBtn {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            margin-bottom: 20px;

            & > img {
                width: 20px;
                height: 20px;
            }
        }
        &__list {
            width: 100%;
            height: auto;
            
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-bottom: 20px;

            &__item {
                width: 100%;
                height: auto;
                display: grid;
                grid-template-columns: 1fr auto;
                grid-template-rows: auto;
                align-items: center;
            }
        }
        &__uploadFiles {
            width: 100%;
            height: auto;
            margin-bottom: 20px;

            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto;
            grid-auto-rows: auto;
            grid-auto-flow: column;
            gap: 20px;

            &__file {
                width: 100%;
                height: auto;
                padding: 10px;

                display: grid;
                grid-template-columns: 150px 1fr;
                grid-template-rows: auto;
                gap: 20px;

                border: 1px solid #484F5C;
                border-radius: 5px;

                & > img {
                    width: auto;
                    max-width: 100%;
                    height: auto;
                    max-height: 150px;

                    justify-self: center;
                    align-self: center;
                }
                &__countries {
                    width: 100%;
                    height: auto;

                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-end;
                    gap: 10px;

                    &__country {
                        width: max-content;
                        height: max-content;
                        background-color: #1B1D21;
                        padding: 5px 10px;
                        border-radius: 5px;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        grid-gap: 10px;
                        gap: 10px;

                        transition: background-color 0.3s ease;

                        &--active {
                            background-color: #434295;
                        }
                    }
                }
            }
        }
    }
}
.route__user__creatives__st {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 300px;
    column-gap: 20px;
    grid-template-rows: max-content;

    &__sideFilters {
        position: sticky;
        top: 0;
        right: 0;
        width: 300px;
        height: 100vh;
        padding: 10px;
        
        background-color: #232630;
        border: 1px solid #373A43;

        display: flex;
        flex-direction: column;
        gap: 10px;

        body.light & {
            background-color: #fff;
            border-color: #E2E2E2;
        }
        & .styledComponent__button  {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;

            & > img {
                width: 20px;
                height: 20px;
                transform: rotate(45deg);
            }
        }
        &__split {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: max-content;
            grid-auto-rows: max-content;
            gap: 5px;
            column-gap: 10px;

            & > p {
                grid-column: 1 / span 2;
                font-size: 13px;
                color: black;

                body.dark & {
                    color: white;
                }
            }
        }
        &__radio {
            display: flex;
            align-items: center;
            gap: 5px;
        }
    }
    &__content {
        gap: 16px;

        &__item {
            background-color: transparent;
            box-shadow: 0 0 0 0 transparent;
            border-radius: 5px;
            border: 1px solid #484F5C;
            padding: 16px;
            gap: 16px;

            &__image {
                position: relative;
                width: 100%;
                height: auto;
                min-height: 100px;
                max-height: 200px;
                border-radius: 5px;
                overflow: hidden;

                display: flex;
                justify-content: center;
                align-items: center;

                &__flag {
                    position: absolute;
                    bottom: 8px;
                    right: 8px;

                    display: flex;
                    justify-content: flex-start;

                    &__circle {
                        width: 38px;
                        height: 38px;

                        display: flex;
                        justify-content: center;
                        align-items: center;
                        
                        border-radius: 50%;
                        border: 4px solid #232630B2;
                        overflow: hidden;

                        & > img {
                            width: 110%;
                            height: 110%
                        }
                    }
                    &__count {
                        width: max-content;
                        height: max-content;
                        padding: 2px 4px;

                        font-family: Inter;
                        font-size: 10px;
                        font-weight: 700;
                        line-height: 16.67px;
                        text-align: left;                    
                        
                        color: #FFFFFF;
                        background-color: #242731B2;
                        border-radius: 2px;
                        transform: translateY(-50%);
                    }
                }
                & > img {
                    width: auto;
                    height: auto;
                    min-height: 100%;
                    max-width: 100%;
                }
                & > video {
                    width: 100%;
                    height: 100%
                }
            };
            &__performance {
                width: 100%;
                height: auto;
                display: grid;

                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: repeat(2, max-content);

                background-color: #2D313E;
                box-sizing: border-box;
                margin-top: auto;

                & > p {
                    width: 100%;
                    height: 100%;
                    padding: 7px;

                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16.94px;
                    text-align: left;                    

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    border: 1px solid #BED1E640;

                    &:nth-child(-n+3) {
                        border-bottom: none;
                    }
                }
            }
            &__buttons {
                &__user {
                    width: 36px;
                    height: 36PX;
                    border-radius: 50%;
                    margin-right: auto;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16px;
                    text-align: left;                    

                    background: #3F8CFF21;
                    color: #3F8CFF;
                }
            }
        }
    }
}
.route__user__creatives__as {
    width: 100%;
    height: auto;
    position: relative;

    &__spinner {
        position: fixed;
        top: 0;
        left: 84px;
        width: calc(100% - 84px);
        height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: rgba(35, 38, 48, 0.7);
        z-index: 999;
        opacity: 0;
        pointer-events: none;

        transition: background-color 0.3s ease;
    }
    &__filters {
        width: 100%;
        height: auto;
        padding: 20px;
        margin-bottom: 50px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;

        background-color: #373a43;
        box-shadow: 0 0 4px 2px rgba(55,58,67,0.3);
        border-radius: 5px;

        body.light & {
            background-color: #c2c3c4;
            color: black;
        }
        &__history {
            color: white;

            body.light & {
                color: black;
            }
        }
        &__item {
            width: 100%;
            display: grid;
            grid-template-rows: auto;
            grid-template-columns: 200px 1fr 200px;
            gap: 20px;
            color: white;

            body.light & {
                color: black;
            }
            & > * {
                height: 100%;
            }
        }
    }
    &__content {
        position: relative;
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: repeat(auto-fit, 500px);
        grid-template-rows: auto;
        gap: 20px;
        margin-bottom: 20px;

        color: white;

        body.light & {
            color: black;
        }
        & > * {
            height: 100%;
        }
        &__item {
            width: 100%;
            height: auto;
            padding: 20px;

            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
            gap: 10px;

            border-radius: 10px;
            background: #373A43;
            box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.25); 

            body.light & {
                background-color: #fff;
                border-color: #E2E2E2;
            }
            &__head {
                width: 100%;
                height: auto;
                margin-bottom: 10px;

                display: grid;
                grid-template-columns: max-content 1fr 48px;
                justify-content: flex-start;
                align-items: center;
                gap: 20px;

                &__left {
                    width: auto;
                    height: auto;

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;

                    & > img {
                        width: 48px;
                        height: 48px;
                    }
                }
                &__right {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    gap: 5px;
                    justify-content: center;

                    & > p {
                        word-break: break-all;
                    }
                    &__date {
                        color: #B2B3BD;
                        font-family: Inter;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                        -webkit-line-clamp: 1;
                        overflow: hidden;
                        width: 100%;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        margin-bottom: auto;
                    }
                }
                &__btn {
                    width: 28px;
                    height: 28px;

                    margin-left: auto;
                    cursor: pointer;
                    @include button-hover-animation(36px);

                    & > img {
                        width: 32px;
                        height: 32px;
                    }
                }
            }
            &__text,
            &__title {
                width: 100%;

                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;

                word-break: break-all;

                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                overflow: hidden;
                width: 100%;
                margin-bottom: 3px;
            }
            &__title {
                font-weight: bold;
                font-size: 18px;
            }
            &__content {
                width: 100%;
                height: auto;

                & > img,
                & > video {
                    width: 100%;
                    height: auto;
                    max-height: 300px;
                }
            }
            &__reactions {
                width: 100%;

                display: grid;
                grid-template-rows: auto;
                grid-template-columns: repeat(7, 1fr);

                &__item {
                    width: 100%;
                    height: 100%;
                    padding-right: 5px;

                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 5px;

                    & > img {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
            &__countries {
                width: 100%;
                color: #B2B3BD;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                width: 100%;
            }
        }
    }
}

.route__user__creatives__as__viewHistory {
    width: 100%;
    height: 100%;
    position: relative;

    cursor: default;
    background-color: rgba(35, 38, 48, 0.8);

    &__wrap {
        position: absolute;
        width: calc(100% - 84px);
        height: 100%;
        top: 0;
        right: -100%;
        padding: 20px;

        display: grid;
        grid-template-rows: auto 1fr;
        grid-template-columns: 1fr;

        background-color: #2C2E38;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);

        body.light & {
            background-color: #fff;
        }
        &__top {
            width: 100%;
            height: auto;
            padding-bottom: 25px;

            display: flex;
            justify-content: space-between;
            align-items: center;

            border-bottom: 1px solid #373A43;

            body.light & {
                border-color: #E2E2E2;
            }
            &__left {
                color: #FFF;
                font-family: Inter;
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                body.light & {
                    color: black;
                }
            }
            &__right {
                background-color: $button-primary;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 3px;

                cursor: pointer;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: $button-primary-hover;
                }
            }
        }
        &__content {
            width: 100%;
            height: 100%;
            overflow: auto;
            color: white;

            padding: 15px 0;

            body.light & {
                color: black;
            }
        }
    }
    &__pills {
        width: 100%;
        height: auto;

        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 10px;

        & > p {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 5px;

            & span:first-child {
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
            }
            & span:not(:first-child) {
                align-items: center;
                background-color: #434295;
                color: white;
                border-radius: 5px;
                display: flex;
                font-size: 12px;
                height: 20px;
                justify-content: center;
                padding: 10px;
                width: auto;
            }
        }
    }
}

.route__user__creatives__st__modal {
    width: 100%;
    height: 100%;
    position: relative;

    cursor: default;
    background-color: rgba(35, 38, 48, 0.8);

    &__wrap {
        position: absolute;
        width: calc(100% - 86px);
        height: 100%;
        top: 0;
        right: -100%;
        padding: 20px;

        display: grid;
        grid-template-rows: auto 1fr;
        grid-template-columns: 1fr;

        background-color: #2C2E38;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);

        body.light & {
            background-color: #fff;
        }
        &__top {
            width: 100%;
            height: auto;
            padding-bottom: 25px;

            display: flex;
            justify-content: space-between;
            align-items: center;

            border-bottom: 1px solid #373A43;

            body.light & {
                border-color: #E2E2E2;
            }
            &__left {
                color: #FFF;
                font-family: Inter;
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                body.light & {
                    color: black;
                }
            }
            &__right {
                background-color: $button-primary;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 3px;

                cursor: pointer;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: $button-primary-hover;
                }
            }
        }
        &__content {
            width: 100%;
            height: 100%;
            color: white;

            padding: 15px 0;
            display: grid;

            grid-template-rows: auto 1fr;
            grid-template-columns: 1fr;

            &__wrap {
                display: grid;
                grid-template-rows: 1fr;
                grid-template-columns: 300px 1fr;
                column-gap: 20px;

                &__left {
                    position: relative;
                    width: 100%;
                    height: auto;
                    max-width: 300px;
                    display: flex;
                    flex-direction: column;
                    padding: 5px;

                    &__content {
                        position: relative;
                        width: max-content;
                        max-width: 100%;
                        height: auto;
                        max-height: 100%;

                        display: flex;
                        justify-content: center;
                        align-self: center;

                        & > img, & > video {
                            width: auto;
                            max-width: 100%;
                            height: auto;
                            max-height: 300px;
                        }
                        &__flag {
                            position: absolute;
                            bottom: 8px;
                            right: 8px;
        
                            display: flex;
                            justify-content: flex-start;
        
                            &__circle {
                                width: 38px;
                                height: 38px;
        
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                
                                border-radius: 50%;
                                border: 4px solid #232630B2;
                                overflow: hidden;
        
                                & > img {
                                    width: 110%;
                                    height: 110%
                                }
                            }
                        }
                    }
                    &__buttons {
                        margin: 16px 0;                      

                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-wrap: wrap;
                        gap: 16px;

                        &__btn {
                            width: 22px;
                            height: 22px;
                            color: black;

                            display: flex;
                            justify-content: center;
                            align-items: center;

                            font-family: Inter;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 18px;
                            text-align: left;

                            border-radius: 50%;
                            cursor: pointer;

                            transition: background-color 0.3s ease, color 0.3s ease;

                            body.dark & {
                                color: white;
                            }
                            &--active {
                                background-color: #575B68;
                                color: white;
                            }
                        }
                    }
                }
                &__right {
                    width: 100%;
                    height: auto;
                    overflow-y: auto;
                    padding: 5px;

                    &__kpi {
                        width: auto;
                        max-width: 100%;
                        height: auto;
                        margin-bottom: 20px;

                        display: grid;
                        grid-template-columns: repeat(auto-fit, minmax(200px, max-content));
                        grid-template-rows: max-content;
                        gap: 1px;
                        

                        &__item {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;

                            outline: 1px solid #4e525c;

                            body.light & {
                                outline-color: #adadad;
                            }
                            & > p:first-child {
                                border-bottom: 1px solid #4e525c;
                            }
                            & > * {
                                padding: 16px;

                                color: #FFF;
                                font-family: Inter;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 16px;

                                body.light & {
                                    color: black;
                                }
                            }
                            &__country {
                                width: 23px;
                                height: 23px;

                                margin-right: 8px;

                                display: flex;
                                justify-content: center;
                                align-items: center;

                                overflow: hidden;
                                border-radius: 50%;

                                & > img {
                                    width: 110%;
                                    height: 110%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.route__user__creatives__requests {
    color: white;

    body.light & {
        color: black;
    }
    &__kpi {
        width: 100%;
        height: auto;
        margin-bottom: 32px;

        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: auto;
        grid-auto-rows: auto;
        row-gap: 20px;

        &__item {
            width: 100%;
            height: 120px;

            display: flex;
            flex-direction: column;
            justify-content: space-between;

            padding: 24px 40px;
            border: 1px solid #373A43;

            body.light & {
                border-color: #adadad;
            }
            &__top {
                color: #FFF;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;

                body.light & {
                    color: black;
                }
            }
            &__bottom {
                color: #FFF;
                font-family: Inter;
                font-size: 28px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 1px;

                body.light & {
                    color: black;
                }
            }
        }
    }
}

.route__user__creatives__fb {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    gap: 20px;

    &__filters {
        width: 100%;
        height: auto;

        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: max-content;
        gap: 20px;
    }
}