@import "../../scss/mixins.scss";

.route__dataDeletionPolicy {
    width: 100%;
    height: auto;
    max-width: 1000px;
    margin: 50px auto;
    padding: 20px;
    padding-top: 118px;

    color: white;
    font-family: "Inter";

    body.light & {
        color: black;
    }
    & > img {
        width: auto;
        height: auto;

        max-width: 100%;
        margin-top: 10px;
        box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.5);
    }
    & > h1 {
        margin-bottom: 20px;
    }
    & > ul {
        margin: 20px 0;
        margin-left: 30px;
    }
    & > p {
        margin-bottom: 5px;

        & > a {
            color: $button-primary;
        }
    }
    &__underline {
        color: gray;
        text-decoration: underline;
        margin-top: 40px;
    }
}