@import "../../scss/mixins.scss";

.component__sidebar {
    width: 84px;
    height: 100%;
    padding: 38px 20px;
    border-right: 1px solid #373A43;

    display: flex;
    flex-direction: column;

    overflow-y: auto;
    overflow-x: hidden;

    transition: 0.3s ease;

    position: absolute;
    top: 0;
    left: 0;

    z-index: 50;
    background-color: #232630;

    body.light & {
        background-color: #F8F9FA;
        border-right: 1px solid #E2E2E2;
    }

    &__head {
        width: 100%;
        height: max-content;
        max-height: 0;

        padding: 0;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 14px;

        overflow: hidden;
        transition: padding 0.3s ease, max-height 0.3s ease;
  
        &--active {
            padding: 30px 0;
            padding-top: 0;
            max-height: 300px;
        }
        &__clickable {
            position: relative;
            padding-bottom: 1px;
            cursor: pointer;

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0%;
                height: 1px;

                background-color: #6c5dd3;
                transition: width 0.3s linear;
            }
            &:hover::after {
                width: 100%;
            }
        }
        & &__notificationIcon {
            & > p {
                width: 100% !important;

                display: grid;
                grid-template-columns: max-content 1fr;
                grid-template-rows: auto;
            }
            & > p > span {
                display: flex;
                justify-content: space-between;

                & > span:last-child {
                    display: block;
                    width: 24px;
                    height: 24px;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    color: white;
                    background-color: #ff450d;
                    border-radius: 50%;
                }
            }
        }
        &__balance {
            width: 100%;
            min-width: 46px;

            display: flex;
            justify-content: space-between;
            align-items: center;
            
            & > p {
                width: auto;
                height: 100%;

                display: flex;
                justify-content: center;
                align-items: center;
                gap: 20px;

                font-family: Inter;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                text-align: left;
                color: #808191;

                & > img {
                    width: 20px;
                    height: 20px;
                }
            }
            & > span {
                height: 100%;

                display: flex;
                align-items: center;

                font-family: Inter;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                text-align: left;
            }
        }
        &__account {
            width: 100%;
            height: auto;

            display: grid;
            grid-template-rows: 1fr;
            grid-template-columns: max-content auto;
            column-gap: 14px;
            padding: 5px;
            position: relative;
            background: #2c2e38;
            border: 1px solid #373a43;
            border-radius: 5px;

            align-items: center;

            body.light & {
                border-color: #E2E2E2;
                background-color: #FFFFFF;
                color: black;
            }
            &:hover {
                cursor: pointer;
            }

            &__user {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: auto;

                &__top {
                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;

                    color: #ffffff;
                }
                &__bottom {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;

                    & > img {
                        width: 16px;
                        height: 16px;
                    }
                    & > p {
                        font-family: "Inter";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;

                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        line-clamp: 1;
                        -webkit-box-orient: vertical;

                        width: 140px;

                        color: #808191;
                    }
                }
            }
            &__arrow {
                width: 13px;
                height: 22px;
                position: relative;

                background-size: 100% 100%;
                background-position: center;
                cursor: pointer;

                transition: 0.3s ease-in-out;

                @include button-hover-animation(26px);
            }
            &__dropdown {
                display: flex;
                background: #2c2e38;
                box-shadow: none;
                border-radius: 5px;
                width: 315px;
                position: fixed;
                top: 160px;
                left: 19px;
                z-index: 99;
                height: auto;
                max-height: 0;
                overflow: hidden;
                // margin-left: -50%;

                transition:
                    max-height 0.3s ease,
                    box-shadow 0.3s ease,
                    all 0.3s ease;
                gap: 1px;

                body.light & {
                    background-color: #FFFFFF;
                }
                p {
                    padding: 0 20px;
                    display: flex;
                    align-items: center;
                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #ffffff;
                    position: relative;

                    body.light & {
                        color: black;
                    }
                    img {
                        margin-right: 10px;
                        width: 20px;
                        height: 20px;
                    }
                    span {
                        font-size: 14px;
                        line-height: 16px;
                        position: absolute;
                        top: 8px;
                        left: 38px;
                        color: $button_primary;
                    }

                    transition:
                        padding 0.3s ease,
                        background-color 0.3s ease;
                    &:hover {
                        background: #383b46;

                        body.light & {
                            background-color: #F5F5F5;
                        }
                    }
                }
                &--active {
                    box-shadow: 0px 0px 19px 5px rgba(0, 0, 0, 0.25);

                    & p {
                        padding: 27px 10px 14px 10px;
                    }
                }
                &--custom {
                    width: 632px;
                }
                &__active {
                    background: #383b46;

                    body.light & {
                        background-color: #F5F5F5;
                    }
                }
                &__left {
                    border-right: 1px solid #383b46;

                    body.light & {
                        border-color: #c2c1c1;
                    }
                    & > .component__header__right__account__dropdown__active {
                        padding-top: 20px;
                        padding-bottom: 20px;
                    }
                    & > p {
                        padding: 20px 20px !important;
                    }
                }
                &__right {
                    width: 50%;
                    overflow-y: auto;
                    &__search {
                        border: 1px solid #373a43;
                        background-color: transparent;
                        outline: none;
                        box-sizing: border-box;
                        padding: 16px;
                        border-radius: 2px;
                        font-family: Inter;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 17px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #ffffff;
                        margin: 5px 10px;
                        width: calc(100% - 20px);

                        body.light & {
                            color: black;
                            background-color: #F5F5F5;
                            border-color: #E2E2E2;
                        }
                    }
                }
            }
        }
        &__icon {
            width: 24px;
            height: 24px;
            position: relative;

            background-size: 100% 100%;
            background-position: center;
            cursor: pointer;

            @include button-hover-animation(36px);
            & > spam {
                position: absolute;
                top: -10px;
                right: -10px;
                border-radius: 50%;
                background-color: #ff450d;
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: Inter;
                font-size: 12px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: center;
                color: white;
            }
        }
        &__notifications {
            width: 300px;
            height: auto;
            max-height: 0;
            overflow: hidden;
            position: fixed;
            top: 50px;
            left: 80px;
            border-radius: 5px;
            background-color: #2c2e38;
            box-shadow: none;
            transition: all 0.3s ease;

            body.light & {
                background-color: #fff;
            }
            &-active{
                max-height: 600px;
                overflow: scroll;
                box-shadow: 0px 0px 19px 5px #00000040;
            }
            &__single {
                padding: 10px 20px 20px 20px;
                border-bottom: 1px solid #373A43;

                body.light & {
                    border-color: #E2E2E2;
                }
                &__head {
                    font-family: Inter;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 15px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #808191;
                    margin-bottom: 10px;
                }
                &__notification {
                    position: relative;
                    margin-bottom: 10px;
                    cursor: default;

                    &__name {
                        font-family: Inter;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 17px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #ffffff;
                        margin-bottom: 5px;
                        text-transform: capitalize;

                        body.light & {
                            color: black;
                        }
                        &::before {
                            content: "";
                            display: inline-block;
                            width: 7px;
                            height: 7px;
                            -moz-border-radius: 5px;
                            -webkit-border-radius: 5px;
                            border-radius: 7.5px;
                            background-color: #808191;
                            margin-right: 10px;
                        }
                    }
                    &__content {
                        font-family: Inter;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 15px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #ffffff;
                        body.light & {
                            color: black;
                        }
                    }
                    &__time {
                        top: 0;
                        position: absolute;
                        right: 0;
                        font-family: Inter;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 15px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #ffffff;
                        body.light & {
                            color: black;
                        }
                    }
                    &>button{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 10px;
                        color: #FFF;
                        font-size: 12px;
                        font-family: Inter;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        cursor: pointer;
                        border-radius: 5px;
                        border: 1px solid transparent;
                        transition: 0.3s ease;
                        background-color: #6C5DD3;
                        border-color: #6C5DD3;
                        margin-top: 5px;
                    }
                    &--error &__name, body.light &--error &__name  {
                        color: #FF450D;
                        
                        &::before {
                            background-color: #FF450D;
                        }
                    }
                    &--error &__content, body.light &--error &__content {
                        color: #FF450D;
                    }
                }
            }
            &__head {
                font-family: Inter;
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: 0em;
                text-align: left;
                color: #ffffff;
                padding: 20px 20px 10px 20px;

                body.light & {
                    color: black;
                }
            }
        }
    }
    &__logo {
        position: relative;
        width: 100%;
        height: auto;
        margin-bottom: 35px;

        display: flex;
        align-items: center;
        gap: 20px;

        & > img {
            width: 42px;
            height: auto;
        }
        & > p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 33px;
            
            color: #FFFFFF;

            body.light & {
                color: #6C5DD3;
            }
        }
        &__x{
            display: none;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 24px;
            margin-left: auto;
            cursor: pointer;
            margin-right: 20px;

            transition: 0.3s ease;

            &__line{
                width: 24px;
                height: 1px;
                background-color: white;
                transform: rotate(45deg);
                position: absolute;
            }
            &__line-2{
                width: 24px;
                height: 1px;
                background-color: white;
                transform: rotate(-45deg);
                position: absolute;
            }
        }
        &--notify &__unread {
            opacity: 1;
        }
        &__unread {
            position: absolute;
            bottom: 0px;
            left: 24px;

            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            background-color: #ff450d;
            border-radius: 50%;
            opacity: 0;

            transition: opacity 0.3s ease;
        }
    }
    &__item {
        width: 100%;
        height: 56px;
        padding: 0 10px;
        margin-bottom: 10px;
        border-radius: 5px;
        position: relative;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 30px;
        cursor: pointer;
        
        transition: background-color 0.3s ease;

        & > img {
            width: 24px;
            height: 24px;
        }
        & > p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            
            color: #808191;

            transition: color 0.3s ease, font-weight 0.3s ease;

            
        }
        & > svg * {
            transition: fill 0.3s ease;
        }
        &:hover {
            & > p {
                color: white;

                body.light & {
                    color: #6C5DD3;
                }
            }
            & > svg .foreground {
                fill: white;

                body.light & {
                    fill: #6C5DD3;
                }
            }
        }
        &--active {
            background-color: #6C5DD3;


            & > p {
                color: white !important;
                font-weight: 500px;
            }
            & > svg .foreground {
                fill: white !important;
            }
        }
        & .customComponents__buttonWithDropdown {
            position: absolute;
            top: 50%;
            left: 30px;
            width: auto;
            height: auto;
            transform: translate(-0%, -50%);
            z-index: 2;

            & .customComponents__buttonWithDropdown__dropdown {
                right: unset;
                left: 0;
            }
        }
    }
    &__line {
        // width: calc(100% - 20px);
        width: 100%;
        height: 1px;
        margin: 30px 0;
        position: relative;
        left: 0px;

        background-color: #373A43;

        body.light & {
            background-color: #E2E2E2;
        }
    }

    &__text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        margin-left: 0px;
        margin-bottom: 30px;
        
        color: #808191;

        white-space: nowrap;

        span{
            display: inline-block;
            transition: 0.3s ease;
        }

        &--short{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
    }
    &__href {
        width: 300px;
        white-space: nowrap;
        height: auto;
        margin: 5px;
        color: gray;
        text-decoration: underline;
        cursor: pointer;
        opacity: 0;

        transition: opacity 0.3s ease;
    }
    &--opened &__href {
        opacity: 1;
    }
}
.component_sidebar_hamburger{
    display: none;
}
.component__sidebar__cron {
    width: 100%;
    min-width: 216px;
    height: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;

    color: gray;
    transition: opacity 0.3s ease;

    & > span {
        word-break: keep-all;
    }
    body.dark & {
        color: gray;
    }
}

.component__sidebar--opened {
    width: 256px;
    padding: 38px 20px;
    z-index: 99;

    & .component__sidebar__head__notifications {
        top: 200px;
        left: 250px;
    }
}

.component__sidebar__popups {
    position: fixed;
    bottom: 20px;
    right: 20px;
    margin: -10px;
    padding: 10px;

    width: 400px;
    max-width: 100%;
    height: max-content;
    max-width: 100%;

    display: flex;
    flex-direction: column;

    overflow-y: auto;
    z-index: 999999;

    &__popup {
        height: auto;
        max-height: 0;
        margin: 0;
        position: relative;
        right: 0;
        display: flex;
        flex-direction: column;

        padding: 0 10px;
        background-color: #2c2e38;
        color: white;
        border-radius: 5px;

        box-shadow: none;
        overflow: hidden;

        &__head {
            width: 100%;
            margin-bottom: 10px;

            display: flex;
            justify-content: space-between;
            align-items: center;

            & > div {
                width: 24px;
                height: 24px;

                background-size: 100% 100%;
                cursor: pointer;

                @include button-hover-animation(30px);
            }
            & > h3 {
                display: flex;
                align-items: center;
                gap: 10px;

                & > span {
                    font-size: 12px;
                    color: gray;
                }
            }
        }
    }
}