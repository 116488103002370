@import "../../../scss/mixins.scss";

.route__userOfferCopies {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &:not(&--list) > .customComponents__table {
        & > .customComponents__table__data {
            cursor: pointer;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: rgb(55, 51, 81);
            }
            body.light &:hover {
                background-color: rgb(196, 191, 227);
            }
        }
    }
    &--list {
        & .customComponents__table__data__groupNewline {
            margin-top: 20px;
        }
    }
    &__filters {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        gap: 10px;
        color: white;

        body.light & {
            color: black;
        }
        & > p {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
        }
    }
    &__buttons {
        margin-bottom: 10px;

        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;

        &__button {
            width: auto;
            height: auto;
            padding: 10px 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            grid-gap: 8px;
            gap: 8px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #ffffff;
            background-color: $button-primary;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s ease;

            & > img {
                width: 20px;
                height: 20px;
            }
            &:hover {
                background-color: $button-primary-hover;
            }
        }
    }
}