@import "../../scss/mixins.scss";

.component__header {
    width: 100%;
    height: 100%;
    padding: 20px 50px;
    z-index: 50;

    position: sticky;
    top: 0;

    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    border-bottom: 1px solid #373a43;
    background-color: #232630;

    @media screen and (max-width: 1300px) {
        padding: 20px 25px;
    }
    body.light & {
        background-color: #F8F9FA;
        border-bottom: 1px solid #E2E2E2;
    }

    &__left,
    &__right {
        height: 54px;

        display: flex;
        align-items: center;
    }
    &__left {
        font-family: Inter;
        font-size: 22px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0px;
        text-align: left;
        

        color: #ffffff;

        body.light & {
            color: #6C5DD3;
        }
        &__customComponents {
            height: 100%;
            width: auto;

            display: grid;
            grid-template-columns: auto;
            grid-auto-columns: auto;
            grid-template-rows: 100%;

            align-items: center;
            column-gap: 20px;
            grid-auto-flow: column;
        }
        @media screen and (max-width: 1150px) {
            font-size: 18px;
        }
    }
    &__right {
        width: auto;

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        @media screen and (max-width: 1150px) {
            gap: 15px;
        }
        &__balance {
            width: auto;
            min-width: 46px;
            height: 46px;
            padding: 5px 10px;

            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;

            display: flex;
            justify-content: center;
            align-items: center;

            background: #2c2e38;
            border: 1px solid #373a43;
            border-radius: 5px;

            body.light & {
                border-color: #E2E2E2;
                background: #FFFFFF;
            }
        }
        &__account {
            display: grid;
            grid-template-rows: 1fr;
            grid-template-columns: 44px max-content auto;
            column-gap: 14px;
            padding: 5px;
            position: relative;
            background: #2c2e38;
            border: 1px solid #373a43;
            border-radius: 5px;

            align-items: center;

            body.light & {
                border-color: #E2E2E2;
                background-color: #FFFFFF;
                color: black;
            }
            &:hover {
                cursor: pointer;
            }

            &__img {
                width: 34px;
                height: 34px;
            }
            &__user {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: auto;

                &__top {
                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;

                    color: #ffffff;
                }
                &__bottom {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;

                    & > img {
                        width: 16px;
                        height: 16px;
                    }
                    & > p {
                        font-family: "Inter";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;

                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        line-clamp: 1;
                        -webkit-box-orient: vertical;

                        width: 140px;

                        color: #808191;
                    }
                }
            }
            &__arrow {
                width: 13px;
                height: 22px;
                position: relative;
                margin-left: 35px;
                margin-right: 20px;

                background-size: 100% 100%;
                background-position: center;
                cursor: pointer;

                transition: 0.3s ease-in-out;

                @include button-hover-animation(26px);
            }
            &__dropdown {
                display: flex;
                background: #2c2e38;
                box-shadow: none;
                border-radius: 5px;
                width: 100%;
                position: absolute;
                top: calc(100% + 9px);
                z-index: 99;
                height: auto;
                max-height: 0;
                left: 0;
                overflow: hidden;
                // margin-left: -50%;
                transition:
                    max-height 0.3s ease,
                    box-shadow 0.3s ease,
                    all 0.3s ease;
                gap: 1px;

                body.light & {
                    background-color: #FFFFFF;
                }
                p {
                    padding: 0 20px;
                    display: flex;
                    align-items: center;
                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #ffffff;
                    position: relative;

                    body.light & {
                        color: black;
                    }
                    img {
                        margin-right: 10px;
                        width: 20px;
                        height: 20px;
                    }
                    span {
                        font-size: 14px;
                        line-height: 16px;
                        position: absolute;
                        top: 8px;
                        left: 38px;
                        color: $button_primary;
                    }

                    transition:
                        padding 0.3s ease,
                        background-color 0.3s ease;
                    &:hover {
                        background: #383b46;

                        body.light & {
                            background-color: #F5F5F5;
                        }
                    }
                }
                &--active {
                    box-shadow: 0px 0px 19px 5px rgba(0, 0, 0, 0.25);

                    & p {
                        padding: 27px 10px 14px 10px;
                    }
                }
                &--custom {
                    left: -50%;
                    width: 200%;
                }
                &__active {
                    background: #383b46;

                    body.light & {
                        background-color: #F5F5F5;
                    }
                }
                &__left {
                    border-right: 1px solid #383b46;

                    body.light & {
                        border-color: #c2c1c1;
                    }
                    & > .component__header__right__account__dropdown__active {
                        padding-top: 20px;
                        padding-bottom: 20px;
                    }
                    & > p {
                        padding: 20px 20px !important;
                    }
                }
                &__right {
                    width: 50%;
                    &__search {
                        border: 1px solid #373a43;
                        background-color: transparent;
                        outline: none;
                        box-sizing: border-box;
                        padding: 16px;
                        border-radius: 2px;
                        font-family: Inter;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 17px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #ffffff;
                        margin: 5px 10px;
                        width: calc(100% - 20px);

                        body.light & {
                            color: black;
                            background-color: #F5F5F5;
                            border-color: #E2E2E2;
                        }
                    }
                }
            }
        }
        &__icon {
            width: 24px;
            height: 24px;
            position: relative;

            background-size: 100% 100%;
            background-position: center;
            cursor: pointer;

            @include button-hover-animation(36px);
            & > spam {
                position: absolute;
                top: -10px;
                right: -10px;
                border-radius: 50%;
                background-color: #ff450d;
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: Inter;
                font-size: 12px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: center;
                color: white;
            }
            &__notMenu {
                width: 300px;
                height: auto;
                max-height: 0;
                overflow: hidden;
                position: absolute;
                top: calc(100% + 25px);
                right: 0;
                border-radius: 5px;
                background-color: #2c2e38;
                box-shadow: 0px 0px 19px 5px #00000040;
                transition: all 0.3s ease;

                body.light & {
                    background-color: #fff;
                }
                &-active{
                    max-height: 600px;
                    overflow: scroll;
                }
                &__single {
                    padding: 10px 20px 20px 20px;
                    border-bottom: 1px solid #373A43;

                    body.light & {
                        border-color: #E2E2E2;
                    }
                    &__head {
                        font-family: Inter;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 15px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #808191;
                        margin-bottom: 10px;
                    }
                    &__notification {
                        position: relative;
                        margin-bottom: 10px;
                        cursor: default;

                        &__name {
                            font-family: Inter;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 17px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: #ffffff;
                            margin-bottom: 5px;
                            text-transform: capitalize;

                            body.light & {
                                color: black;
                            }
                            &::before {
                                content: "";
                                display: inline-block;
                                width: 7px;
                                height: 7px;
                                -moz-border-radius: 5px;
                                -webkit-border-radius: 5px;
                                border-radius: 7.5px;
                                background-color: #808191;
                                margin-right: 10px;
                            }
                        }
                        &__content {
                            font-family: Inter;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 15px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: #ffffff;
                            body.light & {
                                color: black;
                            }
                        }
                        &__time {
                            top: 0;
                            position: absolute;
                            right: 0;
                            font-family: Inter;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 15px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: #ffffff;
                            body.light & {
                                color: black;
                            }
                        }
                        &>button{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 10px;
                            color: #FFF;
                            font-size: 12px;
                            font-family: Inter;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            cursor: pointer;
                            border-radius: 5px;
                            border: 1px solid transparent;
                            transition: 0.3s ease;
                            background-color: #6C5DD3;
                            border-color: #6C5DD3;
                            margin-top: 5px;
                        }
                        &--error &__name, body.light &--error &__name  {
                            color: #FF450D;
                            
                            &::before {
                                background-color: #FF450D;
                            }
                        }
                        &--error &__content, body.light &--error &__content {
                            color: #FF450D;
                        }
                    }
                }
                &__head {
                    font-family: Inter;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 19px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #ffffff;
                    padding: 20px 20px 10px 20px;

                    body.light & {
                        color: black;
                    }
                }
            }
        }
    }

    &__userModal {
        position: fixed;
        top: 84px;
        right: 50px;
        width: 300px;
        height: auto;
        max-height: 0;
        padding: 0px 0px;
        overflow: hidden;
        z-index: 99;

        background: #2c2e38;
        border: 0px solid #373a43;
        border-radius: 5px;
        filter: drop-shadow(0px 0px 26px rgba(0, 0, 0, 0.25));
        overflow: hidden;


        transition:
            padding 0.3s ease,
            max-height 0.3s ease,
            border 0.3s ease,
            left 0.3s ease,
            top 0.3s ease;

        body.light & {
            background-color: #fff;
            border-color: #E2E2E2;
            color: white;
        }
        & .component__customComponent__advancedDropdown {
            width: calc(100% - 20px);
            margin: 5px 10px;
        }
        &--active {
            padding: 11px 0px;
            max-height: 260px;
            border: 1px solid #373a43;
            overflow: visible;

            body.light & {
                border-color: #E2E2E2;
            }
        }
        &__spinner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(35, 38, 48, 0.8);

            pointer-events: none;
            opacity: 0;
            transition: opacity 0.3s ease;
            z-index: 999;

            body.light & {
                background-color: rgba(35, 38, 48, 0.3);
            }
            & > svg {
                pointer-events: none;
            }
            &--active {
                opacity: 1;
                pointer-events: all;

                & > svg {
                    pointer-events: all;
                }
            }
        }
        &__first {
            display: flex;
            align-items: center;
            gap: 18px;

            padding: 0px 13px;
            padding-bottom: 10px;
            border-bottom: 2px solid #373a43;

            body.light & {
                border-color: #E2E2E2;
            }
            & > p {
                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;

                color: #808191;
            }
        }
        &__button {
            width: 100%;
            height: 45px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 18px;
            padding: 0 13px;
            cursor: pointer;

            transition: background-color 0.3s ease;

            & > svg {
                width: 24px;
                height: 24px;

                & > * {
                    transition: fill 0.3s ease;
                }
            }
            & > p {
                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;

                color: #808191;

                transition: color 0.3s ease;
            }
            &:hover {
                background-color: #454856;

                body.light & {
                    background-color: #F5F5F5;
                }
                & > svg .foreground {
                    fill: white;

                    body.light & {
                        fill: #6C5DD3;
                    }
                }
                & > p {
                    color: white;

                    body.light & {
                        color: #6C5DD3;
                    }
                }
            }
        }
    }
}

@media (max-width: $breakpoint-medium) {
    .component__header {
        height: 92%;
        padding: 0 20px;
        padding-bottom: 13px;
        padding-left: 80px;
        position: relative;
    }
    .component__header__left__hamburger {
        position: absolute;
        top: 36px;
        left: 20px;
        display: block;
        z-index: 50;
        &__box {
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            flex-direction: column;
            overflow: hidden;
            &:hover {
                cursor: pointer;
            }
            &__line {
                width: 100%;
                max-width: 30px;
                height: 2px;
                background-color: #fff;
                border-radius: 5px;
                transition: 0.3s ease-in-out;
                &--first {
                    transform: rotate(0deg);
                    width: 100%;
                    position: relative;
                }
                &--middle {
                    opacity: 1;
                }
                &--last {
                    transform: rotate(0deg);
                    width: 100%;
                    position: relative;
                }
            }
        }
    }
}
