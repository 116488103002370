.component__customComponent__advancedDropdown{
    width: 100%;
    height: 54px;
    padding: 0 20px;
    position: relative;

    background: #2C2E38;
    border: 1px solid #373A43;
    border-radius: 5px;

    cursor: pointer;

    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr;
    column-gap: 20px;
    justify-content: space-between;
    align-items: center;

    transition: border 0.3s ease;

    body.light & {
        background-color: #fff;
        border-color: #E2E2E2;
    }
    &:hover, body.light &:hover {
        border-color: #6C5DD3;
    }

    &__dropdown {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        z-index: 99;
        overflow: hidden;
        overflow-y: scroll;

        background: #2C2E38;
        box-shadow: none;
        border-radius: 5px;

        display: flex;
        flex-direction: column;

        transition: max-height 0.3s ease, box-shadow 0.3s ease, padding 0.3s ease;

        body.light & {
            background-color: #fff;
        }
        &--padding {
            box-shadow: 0px 0px 19px 5px rgba(0, 0, 0, 0.25);
        }
        &__search{
            padding: 8px;
        }
        &__item {
            height: auto;
            min-height: 50px;
            display: grid;
            grid-template-columns: auto 1fr;
            grid-template-rows: auto;
            gap: 10px;
            padding: 10px 12px;
            overflow: hidden;
            border-bottom: 1px solid #373A43;
            color: #fff;

            transition: background-color 0.3s ease;

            body.light & {
                border-color: #E2E2E2;
                color: black;
            }
            &:last-child {
                border-bottom: none;
            }
            &__left {
                width: 16px;
                height: 16px;
                place-self: center;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                gap: 5px;

                & > img {
                    width: 100%;
                    height: 100%;
                }
            }
            &__right {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 12px;

                &__head {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                }
                &__text {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15px;
                }
            }
            &--active, &:hover {
                background-color: #383B46;

                body.light & {
                    background-color: #F5F5F5;
                }
            }
        }
        &__nodata{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;

            padding: 16px 20px;
            
            color: #FFFFFF;

            body.light & {
                color: black;
            }
        }
    }
    &__dropdown--padding {
        padding: 5px 0px;
    }
    &__left {
        width: auto;
        height: 100%;
        padding: 8px 0;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        overflow: hidden;

        &__top {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            
            color: #FFFFFF;

            body.light & {
                color: black;
            }
        }
        &__bottom {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;

            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            
            color: #FFFFFF;

            body.light & {
                color: black;
            }
            & > img {
                width: 16px;
                height: 16px;
            }
            &__placeholder{
                color: #999595;
            }
        }
    }
    &__right {
        width: 14px;
        height: 19px;

        & > img {
            width: 100%;
            height: 100%;
            transition: 0.2s ease;
        }
    }
    &__right--active{
        transform: rotate(180deg);
    }
}