@import "../../../../../scss/mixins.scss";

.route__user__integrations__addGoogle {
    width: 100%;
    height: 100%;
    position: relative;
    cursor: default;
    background: rgba(35, 38, 48, 0.80); 

    &__wrap {
        position: absolute;
        top: 0;
        right: -100%;
        width: 600px;
        height: 100%;

        display: grid;
        grid-template-rows: 102px 1fr;
        grid-template-columns: 1fr;

        background: #2C2E38;
        box-shadow: 0px 0px 22px 4px rgba(0, 0, 0, 0.25);

        body.light & {
            background-color: white;
        }
        &__infoP {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #FF450D;
        }
        &__head {
            width: 100%;
            height: 100%;

            padding: 0 40px;

            display: flex;
            justify-content: space-between;
            align-items: center;

            border-bottom: 1px solid #373A43;

            body.light & {
                border-color: #E2E2E2;
            }
            &__text {
                color: #FFF;
                font-size: 22px;
                font-family: Inter;

                body.light & {
                    color: black;
                }
            }
            &__close {
                width: 30px;
                height: 30px;
                background-size: 100% 100%;
                position: relative;
                cursor: pointer;

                @include button-hover-animation(34px);

                body.light & {
                    filter: invert(1) hue-rotate(180deg);
                }
            }
        }
        &__data {
            width: 100%;
            height: auto;
            max-height: 100%;
            overflow-y: auto;
            overflow-x: hidden;

            padding: 0 50px;
            padding-top: 40px;

            &__steps {
                width: 100%;
                height: auto;
                margin-bottom: 30px;

                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 12px;

                &__step {
                    width: auto;
                    height: auto;
                    padding: 12px 24px;

                    display: flex;
                    gap: 10px;

                    border-radius: 5px;
                    transition: background-color 0.3s ease;

                    &__num {
                        width: 18px;
                        height: 18px;
                        border-radius: 50%;

                        display: flex;
                        justify-content: center;
                        align-items: center;

                        font-size: 12px;
                        font-family: Inter;
                        font-weight: 600;

                        background-color: #808191;
                        color: #FFF;

                        transition: background-color 0.3s ease, color 0.3s ease;
                    }
                    &__text {
                        color: #808191;
                        font-size: 14px;
                        font-family: Inter;

                        transition: color 0.3s ease;
                    }

                    &--active {
                        background: #6C5DD3;
                    }
                    &--active &__num {
                        background-color: white;
                        color: #6C5DD3;
                    }
                    &--active &__text {
                        color: #FFF;
                    }
                }
            }
            &__step1 {
                width: 100%;
                height: auto;
                position: relative;
                left: 200%;

                display: flex;
                flex-direction: column;
                gap: 40px;
                color: white;

                transition: left 0.3s ease;

                body.light & {
                    color: black;
                }
                &__text {
                    width: 100%;
                    height: auto;
                    padding: 24px;

                    color: #FFF;
                    text-align: center;
                    font-size: 18px;
                    font-family: Inter;
                    line-height: 24px;

                    border-radius: 5px;
                    background: #373351;

                    body.light & {
                        background-color: #c9c4e4;
                        color: black;
                    }
                    &--error, body.light &--error {
                        background-color: #513333;
                    }
                }
                &__btn {
                    width: 206px;
                    height: 45px;
                    margin: 0 auto;
                    border-radius: 5px;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    background-color: $button-primary;
                    color: #FFF;
                    font-size: 14px;
                    font-family: Inter;
                    cursor: pointer;

                    transition: background-color 0.3s ease;

                    &:hover {
                        background-color: $button-primary-hover;
                    }
                }
            }
            &__step2 {
                width: 100%;
                height: auto;
                position: relative;
                left: 200%;

                color: #FFF;
                text-align: center;
                font-size: 18px;
                font-family: Inter;
                line-height: 24px;

                display: flex;
                flex-direction: column;
                gap: 40px;
                
                transition: left 0.3s ease;

                body.light & {
                    color: black;
                }
            }
            &__step3 {
                width: 100%;
                height: auto;
                position: relative;
                left: 200%;

                color: #FFF;
                text-align: center;
                font-size: 18px;
                font-family: Inter;
                line-height: 24px;

                display: flex;
                flex-direction: column;
                gap: 40px;
                
                transition: left 0.3s ease;

                body.light & {
                    color: black;
                }
                &__close {
                    width: 132px;
                    height: 45px;
                    margin: 0 auto;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    font-size: 14px;
                    font-family: Inter;
                    color: #FFF;

                    border-radius: 5px;
                    background-color: $button-primary;
                    border: none;
                    outline: none;
                    cursor: pointer;

                    transition: background-color 0.3s ease;

                    &:hover {
                        background-color: $button-primary-hover;
                    }
                }
                &__tableUsername {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 2px;

                    & > span:nth-child(1) {
                        color: $button-primary;
                    }
                }
                &__btn {
                    width: 206px;
                    height: 45px;
                    margin: 0 auto;
                    border-radius: 5px;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    background-color: $button-primary;
                    color: #FFF;
                    font-size: 14px;
                    font-family: Inter;
                    cursor: pointer;

                    transition: background-color 0.3s ease;

                    &:hover {
                        background-color: $button-primary-hover;
                    }
                }
                &__accType {
                    text-align: left;
                    margin-bottom: -20px;
                }
                &__code {
                    padding: 24px;
                    border-radius: 5px;
                    background: #373351;
                    user-select: all;

                    body.light & {
                        background-color: #c9c4e4;
                        color: black;
                    }
                }
                &__note {
                    color: #f75656;
                }
                &__campaignWithImg {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 16px;
                    
                    & > img {
                        max-width: 56px;
                        max-height: 56px;
                    }
                    & > p {
                        width: 95%;
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
        
                        & > span:last-child {
                            color: #808191;
                            font-size: 12px;
                            font-family: Inter;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 18px;
        
                            display: flex;
                            align-items: center;
                            gap: 5px;
        
                            & > img {
                                opacity: 0;
                                transition: opacity 0.3s ease;
                            }
                        }
                        & > span:first-child {
                            color: #FFF;
                            font-family: Inter;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 18px;
                            transition: color 0.3s ease;

                            body.light & {
                                color: black;
                            }
                        }
                    }
                }
                &__tabs {
                    width: 100%;
                    height: auto;
                    margin-bottom: 20px;
            
                    display: flex;
                    align-items: center;
                    gap: 20px;
            
                    &__tab {
                        padding: 10px 18px;
            
                        color: #808191;
                        font-family: Inter;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
            
                        border: 1px solid transparent;
                        border-radius: 5px;
            
                        transition: border-color 0.3s ease, background-color 0.3s ease, color 0.3s ease;
                        user-select: none;
                        cursor: pointer;
            
                        &:hover {
                            border-color: #6C5DD3;
                        }
                        &--active {
                            background-color: #6C5DD3;
                            border-color: #6C5DD3;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}