.components__filters__filterBySearch {
    width: 100%;
    height: 54px;

    background: #2C2E38;
    border: 1px solid #373A43;
    border-radius: 5px;

    overflow: hidden;
    position: relative;

    body.light & {
        border-color: #E2E2E2;
        background-color: #fff;
    }
    &__img {
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
    }
    & > input {
        width: 100%;
        height: 100%;
        padding: 0 40px;

        border: 1px solid #373A43;
        outline: none;

        background-color: #2C2E38;
        color: white;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;

        transition: border 0.3s ease;

        body.light & {
            border-color: #E2E2E2;
            background-color: #fff;
            color: black;
        }
        &:focus, body.light &:focus {
            border-color: #6C5DD3;
        }
    }
}