.customComponents__radio {
    width: 34px;
    height: 16px;
    position: relative;

    border-radius: 9999px;
    background: #373A43;
    border: 1px solid #5E626D;
    cursor: pointer;

    transition: border 0.3s ease, background-color 0.3s ease;

    body.light & {
        border-color: #6C5DD3;
        background-color: #E4E4E4;
    }
    &:hover, body.light &:hover {
        border-color: #6C5DD3;
    }
    &::after, body.light &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 12px;
        height: 12px;
        margin: 1px;

        background: #FFFFFF;
        border-radius: 50%;

        transition: left 0.3s ease;
    }
    &--active, body.light &--active {
        background-color: #6C5DD3;

        &::after {
            left: calc(100% - 14px);
        }
    }
}