@import "../../scss/mixins.scss";

.route__termsOfService {
    width: 100%;
    height: auto;
    max-width: 1000px;
    margin: 50px auto;
    padding: 20px;
    padding-top: 118px;

    color: white;
    font-family: "Inter";

    body.light & {
        color: black;
    }
    & > h1 {
        margin-bottom: 20px;
    }
    & > ul {
        margin: 20px 0;
        margin-left: 30px;
    }
    & > p {
        margin-bottom: 5px;

        & > a {
            color: $button-primary;
        }
    }
    &__moveToRight {
        margin-left: 10px;
    }
    &__underline {
        color: gray;
        text-decoration: underline;
        margin-top: 40px;
    }
}