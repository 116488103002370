.route__dashboard {
    width: 100%;
    height: 100%;

    color: white;
    body.light & {
        color: black;
    }
    &__top {
        width: 100%;
        height: auto;
        margin-bottom: 20px;

        display: flex;
        align-items: center;
        gap: 50px;

        &__head {
            font-size: 24px;
            font-weight: bold;

            display: flex;
            align-items: center;
            gap: 20px;

            & > span {
                font-size: 14px;
                color: gray;
            }
        }
    }
    &__grid {
        width: 100%;
        height: auto;

        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;
        grid-auto-rows: auto;
        gap: 20px;

        &__box {
            width: 100%;
            height: auto;
            box-shadow: 0 0 4px 2px rgba(0,0,0,0.3);
            border-radius: 5px;

            display: flex;
            flex-direction: column;
            overflow: hidden;

            &__head {
                width: 100%;
                height: auto;
                padding: 20px;

                font-size: 16px;
                font-weight: bold;

                color: white;

                &--blue {
                    background-color: #20355d;
                }
                &--green {
                    background-color: #293c34;
                }
                &--yellow {
                    background-color: #534436;
                }
                &--cyan {
                    background-color: #253244;
                }
            }
            &__error {
                color: #FF450D;

                body.light & {
                    color: red;
                }
            }
            &__content2 {
                width: 100%;
                height: auto;
                padding: 20px;

                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: auto;
                grid-auto-rows: auto;
                gap: 20px;

                & > div {
                    p {
                        font-size: 12px;
                        margin-bottom: 5px;
                    }
                    span {
                        font-size: 20px;
                        font-weight: bold;
                    }
                }
            }
            &__content {
                width: 100%;
                height: auto;
                padding: 20px;

                display: flex;
                flex-direction: column;
                gap: 30px;

                & > div {
                    width: 100%;
                    height: auto;

                    display: grid;
                    grid-template-columns: 1fr repeat(2, max-content);
                    grid-template-rows: auto;
                    grid-auto-rows: auto;
                    row-gap: 5px;
                    column-gap: 20px;

                    p {
                        font-size: 12px;
                        margin-bottom: 5px;
                    }
                    span {
                        display: block;
                        font-size: 14px;
                        font-weight: bold;
                    }
                }
            }
            &__content5 {
                width: 100%;
                height: auto;
                padding: 20px;

                display: flex;
                flex-direction: column;
                gap: 20px;

                & > div {
                    width: 100%;
                    height: auto;

                    display: grid;
                    grid-template-columns: repeat(5, 1fr);
                    grid-template-rows: auto;
                    grid-auto-rows: auto;
                    row-gap: 5px;
                    column-gap: 20px;

                    p {
                        font-size: 12px;
                        margin-bottom: 5px;
                    }
                    span {
                        display: block;
                        font-size: 12px;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}