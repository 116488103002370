@keyframes share_progress_anim {
    0% {left: -50%};
    100% {left: 120%};
}

.route__liveShare {
    width: 100%;
    height: 100%;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    overflow: auto;

    & > * {
        flex-grow: 0;
        flex-shrink: 0;
    }
    .customComponents__table__head {
        position: sticky;
        top: 0;
    }
    .customComponents__table__data {
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: rgb(55, 51, 81);
        }
    }
    &__box {
        width: 300px;
        height: auto;
        padding: 20px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;

        background-color: #1d202b;
        color: white;
        box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.3);
    }
    &__wrap {
        width: 100%;
        height: auto;
        padding: 20px;

        color: white;
        font-family: "Inter";

        &__heading {
            display: grid;
            grid-template-columns: max-content auto;
            grid-template-rows: auto;
            grid-auto-rows: auto;

            &__head  {
                width: 100%;
                grid-row: 1;
                grid-column: 1;
                word-break: break-all;
            }
            &__sub {
                width: 100%;
                margin-bottom: 20px;
                color: gray;
                grid-row: 2;
                grid-column: 1;
                word-break: break-all;
            }
            & .component__filters__filterByDate {
                grid-row: 1 / span 2;
                grid-column: 2;
                max-width: 300px;
                margin-left: auto;
            }

            @media screen and (max-width: 900px) {
                grid-template-columns: 1fr;
                .component__filters__filterByDate {
                    grid-row: 3;
                    grid-column: 1;
                    width: 100%;
                    max-width: 100%;
                    margin-bottom: 20px;
                }
            }
        }
        &__comment {
            padding: 20px;
            background-color: #373351;
            border-radius: 5px;
            box-shadow: 0 0 2px 1px rgba(0,0,0,0.5);
            margin-bottom: 20px;
            word-break: break-all;
        }
        &__kpi {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-template-rows: auto;
            grid-auto-rows: auto;

            @media screen and (max-width: 1130px) {
                grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
            }
            @media screen and (max-width: 480px) {
                justify-content: center;
                grid-template-columns: 1fr;
            }
            &__item {
                padding: 24px 40px;   
                outline: 1px solid #373A43;

                &__top {
                    margin-bottom: 18px;

                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 16px;
                    text-align: left;
                }
                &__bottom {
                    font-family: Inter;
                    font-size: 28px;
                    font-weight: 600;
                    line-height: 33.89px;
                    letter-spacing: 1px;
                    text-align: left;
                }
            }
        }
        &__table {
            margin-top: 20px;

            & > h3 {
                margin-bottom: 10px;

                @media screen and (max-width: 450px) {
                    display: flex;
                    flex-direction: column;

                    & > * {
                        margin: 0 !important;
                    }
                }
            }
        }
    }
    &__progress {
        width: 100%;
        height: 5px;

        position: sticky;
        top: 0;
        left: 0;

        overflow: hidden;

        &__item {
            height: 100%;
            background-color: #6C5DD3;

            transition: width 1s linear;
        }
        &--animate &__item {
            position: absolute;
            top: 0;
            left: 0;
            animation-name: share_progress_anim;
            animation-duration: 1s;
            animation-timing-function: linear;
            animation-fill-mode: both;
            animation-iteration-count: infinite;
            animation-direction: normal;
        }
    }
}