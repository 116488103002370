@import url('https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.158);
  border-radius: 5px;
  border: transparent;

  transition: background-color 0.3s ease;
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(155, 155, 155, 0.5);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  font-family: "Inter";
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  background-color: #232630;
}
body.light #root {
  background-color: #F8F9FA;
}

#root {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  position: relative;
}
.root__content {
  padding: 0 20px;
  padding-bottom: 0;
  padding-top: 30px;
  width: 100%;
  height: 100%;
  min-width: 0;
}
.root__content.root__content--noSticky {
  height: auto;
  min-height: 0;
}

iframe {
  display: none;
}

@media only screen and (max-width: 768px) {
  .root__content {
    padding: 0 20px;   
    padding-bottom: 0;
  }
 
}

.windowManager-contextMenu {
  width: auto;
  min-width: 200px;
  padding: 10px 0;
  height: auto;
  display: grid;
  grid-template-rows: 30px;
  grid-auto-rows: 30px;
  grid-template-columns: 1fr;
  row-gap: 10px;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  background-color: #2c2e38;
  z-index: 999999999;
  box-shadow: 0px 0px 15px 5px #00000040;
  overflow: hidden;
}

.windowManager-contextMenu-item {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 30px max-content;
  background-color: #2c2e38;
  user-select: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.windowManager-contextMenu-item.windowManager-contextMenu-item-withChildren {
  grid-template-columns: 30px 1fr 30px;
}

.windowManager-contextMenu-item:hover {
  background-color: #383B46;
}

.windowManager-contextMenu-item-text {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 20px;
}

.windowManager-contextMenu-item-icon {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.windowManager-contextMenu-item-arrow {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 10px;
}