.route__reports_breakdown {
    width: 100%;
    height: auto;

    &__progress {
        position: fixed;
        top: 0;
        left: 86px;
        width: calc(100% - 86px);
        height: 5px;

        &__line {
            position: absolute;
            top: 0;
            left: 0;
            width: auto;
            height: 100%;

            background-color: #6c5dd3;
            transition: width 0.3s linear;
        }
    }
    &__breakdowns {
        display: grid;
        grid-template-columns: repeat(auto-fit, 300px);
        grid-template-rows: auto;
        grid-auto-rows: auto;
        gap: 20px;
        padding-bottom: 20px;

        border-bottom: 1px solid gray;

        .component__customComponent__advancedDropdown {
            &__left__top {
                width: 100%;

                & > p {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
    }
    &__filters {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        &__img {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 20px;

            & > img {
                width: 16px;
                height: 16px;
            }
        }
        & > input {
            width: 300px;
        }
    }
    &__applyFilters {
        margin-top: 20px;
    }
    &__table {
        width: 100%;
        margin-top: 20px;

        color: black;        
        overflow: auto;

        body.dark & {
            color: white;
        }
        &--ok {
            box-shadow: 0 0 4px 2px rgba(0,0,0,0.2);
        }
    }
}