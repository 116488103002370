@import "../../../../../scss/mixins.scss";

.route__automations__fbWatcher {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    gap: 20px;
    color: white;

    body.light & {
        color: black;
    }
    &__filters {
        width: 100%;
        height: auto;

        display: grid;
        grid-template-columns: 1fr max-content;
        grid-template-rows: auto;
        column-gap: 20px;

        & > * {
            min-height: 100%;
        }
    }
    &__filters2 {
        width: 100%;
        height: auto;

        display: flex;
        align-items: center;
        gap: 20px;

        & > div {
            display: flex;
            align-items: center;
            gap: 5px;
        }
    }
    &__history {
        width: 100%;
        height: 100%;
        position: relative;
    
        cursor: default;
        background-color: rgba(35, 38, 48, 0.8);
    
        &__wrap {
            position: absolute;
            width: calc(100% - 86px);
            height: 100%;
            top: 0;
            right: -100%;
            padding: 20px;
    
            display: grid;
            grid-template-rows: auto 1fr;
            grid-template-columns: 1fr;
    
            background-color: #2C2E38;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
    
            body.light & {
                background-color: #fff;
            }
            &__top {
                width: 100%;
                height: auto;
                padding-bottom: 25px;
    
                display: flex;
                justify-content: space-between;
                align-items: center;
    
                border-bottom: 1px solid #373A43;
    
                body.light & {
                    border-color: #E2E2E2;
                }
                &__left {
                    color: #FFF;
                    font-family: Inter;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
    
                    body.light & {
                        color: black;
                    }
                }
                &__right {
                    background-color: $button-primary;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 3px;
    
                    cursor: pointer;
                    transition: background-color 0.3s ease;
    
                    &:hover {
                        background-color: $button-primary-hover;
                    }
                }
            }
            &__content {
                width: 100%;
                height: 100%;
                overflow: auto;
                color: white;
    
                padding: 15px 0;
    
                &__ad {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                }
            }
        }
    }
}