@import "./mixins.scss";

.genericModal {
    all: initial;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    background-color: rgba(35, 38, 48, 0.7);

    &__wrap {
        width: 500px;
        height: auto;
        max-height: 100%;
        overflow: auto;
        position: relative;
        padding: 40px;
        color: #fff;
        background-color: #2C2E38;
        box-shadow: 0px 0px 19px 5px rgba(0, 0, 0, 0.18);
        border-radius: 5px;

        body.light & {
            background-color: #fff;
            color: black;
        }
        &__progress {
            width: 100%;
            height: 10px;
            margin-top: 10px;
            
            border: 1px solid gray;
            position: relative;
            border-radius: 2px;

            &__item {
                position: absolute;
                top: 0;
                left: 0;
                width: 0%;
                height: 100%;
                background-color: #5A49CE;

                transition: width 0.3s linear;
            }
        }
        &__spinner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            display: flex;
            justify-content: center;
            align-items: center;

            background-color: rgba(35, 38, 48, 0.7);
            pointer-events: none;
            opacity: 0;
            z-index: 999;

            transition: opacity 0.3s ease;

            &:hover {
                pointer-events: all;
            }
        }
        &__head {
            width: 100%;
            height: auto;
            margin-bottom: 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__left {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                letter-spacing: 1px;
                color: #FFFFFF;

                body.light & {
                    color: black;
                }
            }
            &__right {
                width: 30px;
                height: 30px;
                
                cursor: pointer;
                @include button-hover-animation(34px);

                body.light & {
                    filter: invert(1) hue-rotate(180deg);
                }
            }
        }
        &__checkbox {
            width: auto;
            height: auto;
            margin-bottom: 20px;

            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
        }
        &__input {
            width: 100%;
            height: auto;
            margin-bottom: 20px;

            display: flex;
            flex-direction: column;
            gap: 15px;

            & > p {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                
                color: #FFFFFF;

                body.light & {
                    color: black;
                }
            }
            & > input  {
                width: 100%;
                height: 54px;
                padding: 0 20px;

                font-family: 'Inter';
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 19px;

                outline: none;
                color: white;
                background: #373A43;
                border-radius: 5px;
                border: 1px solid transparent;

                transition: border 0.3s ease;

                body.light & {
                    background-color: #fff;
                    border-color: #E2E2E2;
                    color: black;

                    &:focus {
                        border: 1px solid #6C5DD3;
                    }
                }
                &:focus {
                    border: 1px solid #6C5DD3;
                }
            }
            & .component__customComponent__dropbox {
                border: 1px solid transparent;
                transition: border 0.3s ease;

                body.light & {
                    border-color: #E2E2E2;
                }
            }
            &--text {
                & > textarea {
                    width: 100%;
                    height: auto;
                    min-height: 100px;
                    padding: 18px 20px;
                    resize: vertical;

                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 19px;

                    outline: none;
                    color: white;
                    background: #373a43;
                    border-radius: 5px;
                    border: 1px solid transparent;

                    transition: border 0.3s ease;

                    body.light & {
                        color: black;
                        background-color: #e9e8e8;

                        &:focus {
                            border: 1px solid #6C5DD3;
                        }
                    }
                    &:focus {
                        border: 1px solid #6c5dd3;
                    }
                }
            }
            &--split {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: auto;
                gap: 10px;

                & > p {
                    grid-column: 1 / span 2;
                }
            }
            &--error > input, &--error > textarea, &--error .component__customComponent__dropbox {
                border: 1px solid #FF754C;

                body.light & {
                    border: 1px solid #FF754C;
                }
            }
            &__dropItem {
                width: 100%;
                height: auto;

                display: flex;
                align-items: center;
                gap: 10px;

                & > img {
                    width: auto;
                    height: auto;
                    max-height: 48px;
                    max-width: 48px;
                }
                &__risks {
                    width: auto !important;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    width: 100%;
                    height: 2px;

                    &__risk {
                        width: 20px;
                        height: 100%;
                        border-radius: 999px;
                    }
                }
            }
            &__profileSelect {
                display: flex;
                align-items: center;
                gap: 10px;

                & > img {
                    width: 16px;
                    height: 16px;
                }
            }
        }
        &__textBlock {
            width: 100%;
            height: auto;
            padding: 20px;
            margin-bottom: 20px;
            background-color: #373351;

            body.light & {
                background-color: #b9aef0;
                color: black;
            }
        }
        &__buttons {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 20px;

            &__btn {
                width: auto;
                height: 43px;
                padding: 0 40px;

                display: flex;
                justify-content: center;
                align-items: center;

                color: #fff;
                background-color: $button-primary;
                border-radius: 5px;
                cursor: pointer;

                transition: background-color 0.3s ease;

                &:hover {
                    background-color: $button-primary-hover;
                }
                &--secondary {
                    background-color: $button-secondary;

                    &:hover {
                        background-color: $button-secondary-hover;
                    }
                }
            }
        }
        &__infoP {
            margin-top: 20px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #FF450D;
            margin-bottom: 20px;
            opacity: 0;
            transition: opacity 0.3s ease;
        }
        &__summary {
            width: 100%;
            height: auto;
            padding: 20px;
            background-color: #373351;
            margin-bottom: 20px;

            body.light & {
                background-color: #b9aef0;
                color: black;
            }
            &__green {
                color: #69f978;

                body.light & {
                    color: green;
                }
            }
            &__red {
                color: rgb(255, 136, 136);

                body.light & {
                    color: red;
                }
            }
        }
    }
}

.genericTabs {
    width: 100%;
    height: auto;
    margin-bottom: 20px;

    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;

    &__tab {
        padding: 10px 18px;

        color: #808191;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        border: 1px solid transparent;
        border-radius: 5px;

        transition: border-color 0.3s ease, background-color 0.3s ease, color 0.3s ease;
        user-select: none;
        cursor: pointer;

        &:hover {
            border-color: #6C5DD3;
        }
        &--active {
            background-color: #6C5DD3;
            border-color: #6C5DD3;
            color: #fff;
        }
        &--disabled {
            pointer-events: none;
            background-color: #373a43;
        }
    }
}

.genericTableWrap {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    padding-bottom: 5px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    overflow-y: auto;

    & .customComponents__table__head__item--border:nth-child(1)::after, & .customComponents__table__data--border > :nth-child(1)::after {
        background-color: transparent !important;
        border: none !important;
        box-shadow: -4px 0px 3px 1px rgba(0, 0, 0, 0.25);
    }
    &--sticky .customComponents__table {
        & .customComponents__table__head {
            padding: 0;

            &__item {
                &:nth-child(1) {
                    padding-left: 40px;
                    width: 100%;
                    position: sticky;
                    left: 0;
                    padding-right: 25px;

                    & .route__user__campaigns__tableWrap__campaignWithImg__headingPadded {
                        background-color: #242731;
                    }

                    z-index: 2;
                }
                &:nth-child(1) {
                    z-index: 4;
                    min-width: unset;
                }
            }
        }
        & .customComponents__table__data {
            padding-left: 0 !important;

            & > * {
                &:nth-child(1) {
                    min-width: 100%;
                    height: 100%;
                    position: sticky;
                    left: 0;
                    padding-left: 40px;

                    z-index: 2;

                    & .genericTableWrap__campaignWithImg__padded {
                        background-color: #2c2e38;

                        body.light & {
                            background-color: #fff;
                        }
                    }
                }
                &:nth-child(1) {
                    z-index: 4;
                    width: 100%;
                    height: 100%;
                    min-width: unset;
                }
            }

            &0 {
                & > * {
                    &:nth-child(1) {
                        & .genericTableWrap__campaignWithImg__padded {
                            background-color: #242731;

                            body.light & {
                                background-color: #F5F5F5;
                            }
                        }
                    }
                }
            }
            &--footer {
                & > * {
                    &:nth-child(1) {
                        & > * {
                            & .genericTableWrap__campaignWithImg__padded {
                                background-color: #4B4282;

                                body.light & {
                                    background-color: #b9aef0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &__paginate {
        width: 1px;
        height: 1px;
    }
    &__campaignWithImg {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
        
        &__padded {
            width: calc(100% + 30px);
            height: calc(100% + 20px);
            position: absolute;
            top: -10px;
            left: -10px;
            z-index: -1;

            &__fill {
                width: 100%;
                height: 100%;
                position: relative;

                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        &__headingPadded {
            width: calc(100% + 30px) !important;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            background-color: #242731;

            body.light & {
                background-color: #fff;
            }
        }
        & > img {
            max-width: 56px;
            max-height: 56px;
        }
        & > p {
            width: 95%;
            display: flex;
            flex-direction: column;
            gap: 5px;

            & > span:last-child {
                color: #808191;
                font-size: 12px;
                font-family: Inter;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;

                display: flex;
                align-items: center;
                gap: 5px;

                & > img {
                    opacity: 0;
                    transition: opacity 0.3s ease;
                }
            }
            & > span:first-child {
                color: #FFF;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                transition: color 0.3s ease;

                body.light & {
                    color: black;
                }
            }
        }
        &:hover {
            cursor: pointer;
            
            & > p {
                & > span:first-child {
                    color: #9390FF;
                }
                & > span:last-child {
                    & > img {
                        opacity: 1;
                    }
                }
            }
        }
    }
    &__delivery {
        display: flex;
        align-items: center;
        gap: 10px;

        & > span:first-child {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: #808191;
        }
        &--active > span:first-child {
            background-color: #7FBA7A;
        }
        &--warning > span:first-child {
            background-color: #f0ca32;
        }
        &--error > span:first-child {
            background-color: #df2020;
        }
    }
    &__values {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 5px;

        &__value {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 5px;

            & > img {
                width: 12px;
                height: 12px;
            }
            &--warning {
                color: gray;
            }
        }
    }
}