@import "../../../scss/mixins.scss";

.route__user__sites {
    width: 100%;
    height: auto;

    &__filters{
        position: sticky;
        top: 0;
        z-index: 21;
        background-color: #232630;

        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 54px;
        column-gap: 30px;
        row-gap: 30px;
        padding: 20px 1px;

        body.light & {
            background-color: #f7f8f9;
        }
    }

    &__buttons {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: left;
        align-items: center;
        gap: 20px;

        margin-top: 16px;

        &__left {
            display: flex;
            align-items: center;
            gap: 10px;

            &__item {
                display: flex;
                align-items: center;
                gap: 5px;
                
                color: #FFF;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                body.light & {
                    color: black;
                }
            }
        }
        &__right {
            width: auto;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: auto;

            gap: 10px;

            &__btn {
                width: auto;
                height: 43px;
                padding: 0 40px;

                display: flex;
                justify-content: center;
                align-items: center;

                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                
                color: #FFFFFF;
                background: $button-primary;
                border-radius: 5px;
                cursor: pointer;

                transition: background-color 0.3s ease;

                &:hover {
                    background-color: $button-primary-hover;
                }
            }
        }
    }
    &__cards{
        width: 100%;
        height: auto;
        margin-top: 30px;
        display: grid;
        grid-template-columns: repeat(auto-fill, 370px);
        column-gap: 30px;
        row-gap: 20px;
        justify-items: flex-start;
        place-items: flex-start;
        position: relative;
        padding: 1px;

        &__spinner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
    
            background: rgba(35, 38, 48, 0.70); 
    
            z-index: 99;
            opacity: 0;
            pointer-events: none;
    
            transition: opacity 0.3s ease;

            & .customComponents__spinner {
                position: fixed;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    &__add {
        width: 100%;
        height: 100%;
        cursor: default;
        
        display: flex;
        justify-content: center;
        align-items: center;
        
        color: white;
        background: rgba(35, 38, 48, 0.7);

        &__wrap {
            width: 800px;
            height: auto;
            max-height: 100%;
            overflow: auto;
            position: relative;

            padding: 40px;

            background: #2C2E38;
            box-shadow: 0 0 2px 4px rgba(0,0,0,0.25);
            border-radius: 10px;

            body.light & {
                background-color: #fff;
            }
            &__spinner {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                pointer-events: none;

                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 2;
                
                background-color: rgba(38, 41, 53, 0.8);

                transition: opacity 0.3s ease;

                body.light & {
                    background-color: rgba(38, 41, 53, 0.3);
                }
                &--active {
                    opacity: 1;
                    pointer-events: all;
                }
            }
            &__head {
                width: 100%;
                height: auto;
                margin-bottom: 24px;

                display: flex;
                justify-content: space-between;
                align-items: center;

                &__left {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                    letter-spacing: 1px;
                    
                    color: #FFFFFF;

                    body.light & {
                        color: black;
                    }
                }
                &__right {
                    width: 30px;
                    height: 30px;

                    background-size: 100%;
                    cursor: pointer;

                    @include button-hover-animation(34px);

                    body.light & {
                        filter: invert(1) hue-rotate(180deg);
                    }
                }
            }
            &__input {
                width: 100%;
                height: auto;
                margin-bottom: 20px;

                display: flex;
                flex-direction: column;
                gap: 14px;

                & > p {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    
                    color: #FFFFFF;

                    body.light & {
                        color: black;
                    }
                }
                & > input {
                    width: 100%;
                    height: 54px;
                    padding: 0 20px;

                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 19px;
                    
                    color: #fff;
                    background: #373A43;
                    border-radius: 5px;
                    border: 1px solid transparent;
                    outline: none;

                    transition: border 0.3s ease;

                    body.light & {
                        background-color: #fff;
                        color: black;
                        border-color: #E2E2E2;
                    }
                    &:focus, body.light &:focus {
                        border-color: #6C5DD3;
                    }
                }
                &__dropItem {
                    width: 100%;
                    height: auto;

                    display: flex;
                    align-items: center;
                    gap: 10px;

                    & > img {
                        width: auto;
                        height: auto;
                        max-height: 48px;
                        max-width: 48px;
                    }
                    &__risks {
                        width: auto !important;
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        width: 100%;
                        height: 2px;

                        &__risk {
                            width: 20px;
                            height: 100%;
                            border-radius: 999px;
                        }
                    }
                }
                &__sources {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 20px;

                    &__source {
                        display: flex;
                        align-items: center;
                        gap: 5px;

                        body.light & {
                            color: black;
                        }
                    }
                }
                &__risks {
                    display: grid;
                    grid-template-rows: max-content;
                    grid-template-columns: auto 1fr;
                    column-gap: 10px;

                    &__items {
                        align-self: center;
                        display: flex;                        
                        align-items: center;
                        gap: 5px;
                        width: 100%;
                        height: 2px;

                        &__item {
                            width: 20px;
                            height: 100%;
                            border-radius: 999px;
                        }
                    }
                }
                &--inset {
                    padding-left: 20px;
                    margin-left: 10px;
                    width: calc(100% - 10px);

                    border-left: 1px solid gray;

                    :first-child {
                        margin-bottom: 0;
                    }
                }
                &--error > input {
                    border-color: #FF754C;
                }
                &--dropdown &__withButton {
                    display: grid;
                    grid-template-rows: auto;
                    grid-template-columns: 1fr 150px;
                    column-gap: 30px;

                    &__button {
                        width: 100%;
                        height: 100%;
                        display: flex;

                        justify-content: center;
                        align-items: center;
                        gap: 13px;

                        background-color: $button-primary;
                        border-radius: 5px;
                        cursor: pointer;

                        transition: background-color 0.3s ease;

                        &:hover {
                            background-color: $button-primary-hover;
                        }
                        & > img {
                            width: 30px;
                            height: 30px;
                        }
                        & > p {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 17px;
                            
                            color: #FFFFFF;
                            
                        }
                    }
                }
            }
            &__buttons {
                width: 100%;
                height: auto;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 10px;
                margin-top: 30px;

                &__btn {
                    width: 150px;
                    height: 53px;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    
                    color: #FFFFFF;
                    border-radius: 5px;
                    background-color: $button-primary;
                    cursor: pointer;

                    transition: background-color 0.3s ease;

                    &:hover {
                        background-color: $button-primary-hover;
                    }
                    &--secondary {
                        background-color: $button-secondary;
    
                        &:hover {
                            background-color: $button-secondary-hover;
                        }
                    }
                }
            }
            &__infoP {
                margin-top: 20px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #FF450D;
                opacity: 0;
                transition: opacity 0.3s ease;
            }
            &__expectedLinks {
                width: 100%;
                height: auto;
                padding: 20px;
                background-color: #373351;

                body.light & {
                    filter: invert(1) hue-rotate(180deg);
                }
            }
        }
    }
    &__siteChecker {
        &__step {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 10px;
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid gray;

            &:last-child {
                border-bottom: none;
            }
            & > * {
                max-width: 100%;
            }
            &__btn {
                width: auto;
                height: auto;
                padding: 5px 10px;
                border-radius: 5px;
                color: white;
                background-color: #6C5DD3;
                cursor: pointer;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: #5A49CE;
                }
            }
            &--completed {
                color: gray;
            }
            &--completed &__btn:not(.route__user__sites__siteChecker__step__btn--noDisable) {
                background-color: gray;
                pointer-events: none;
            }
        }
    }
}