@import "../../scss/mixins.scss";

.route__landingFooter{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #0E0C15;
    z-index: 2;
    position: relative;
    &__top{
        width: 100%;
        height: 107px;
        border-top: 1px solid #302C46;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 20px;
        &__wrapper{
            width: 100%;
            max-width: 1216px;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &__left{
                &:hover{
                    cursor: pointer;
                }
            }
            &__right{
                a{
                    color: rgba(255, 255, 255, 0.50);
                    font-size: 14px;
                    font-family: Inter;
                    line-height: 20px;
                    text-transform: uppercase; 
                    margin-left: 60px;
                    text-decoration: none;
                    cursor: pointer;
                    z-index: 5;
                    transition: 0.3s ease-in-out;
                    &:hover{
                        cursor: pointer;
                        color: #FFF;
                    }
                }
            }
        }
    }
    &__bot{
        width: 100%;
        height: max-content;
        border-top: 1px solid #302C46;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        &__wrapper{
            width: 100%;
            max-width: 1216px;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &__left{
                color: rgba(255, 255, 255, 0.50);
                font-size: 14px;
                font-family: Inter;
                line-height: 20px;
                text-transform: uppercase; 
            }
            &__right{
                &__top {
                    display: flex;
                    justify-content: center;
                    gap: 30px;
                }
                &__bottom {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 30px;
                }
                a, p{
                    margin: 0 !important;
                    display: inline-block;
                    color: white;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }
}

@media (max-width: $breakpoint-large) {
    .route__landingFooter{
        &__top{
            &__wrapper{
                &__right{
                    a{
                        margin-left: 30px;
                    }
                }
            }
        }
    }
}

@media (max-width: $breakpoint-medium) {
    .route__landingFooter{
        &__top{
            display: none;
        }
    }
}

@media (max-width: 824px) {
    .route__landingFooter{
        &__bot{
            &__wrapper{
                flex-direction: column;
                justify-content: space-evenly;
                align-items: flex-start;
                &__right{
                    margin: 0 auto;
                    margin-top: 20px;
                    a{
                        margin-left: 0px;
                        margin-right: 30px;
                    }
                }
            }
        }
    }
}