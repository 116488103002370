@import "../../../scss/mixins.scss";

.modals__campaignCustomColumns {
    width: 100%;
    height: 100%;
    position: relative;

    cursor: default;
    background-color: rgba(35, 38, 48, 0.8);

    &__example {
        width: 100%;
        height: auto;
        border-radius: 5px;
        padding: 5px;
        margin: 5px 0;
        margin-bottom: 20px;

        color: white;
        background-color: #373351;

        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        body.light & {
            color: black;
            background-color: #b9aef0;
        }
    }
    &__wrap {
        position: absolute;
        width: calc(100% - 86px);
        height: 100%;
        top: 0;
        right: -100%;
        padding: 20px;

        display: grid;
        grid-template-rows: auto 1fr;
        grid-template-columns: 1fr;

        background-color: #2C2E38;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);

        body.light & {
            background-color: #fff;
        }
        &__top {
            width: 100%;
            height: auto;
            padding-bottom: 25px;

            display: flex;
            justify-content: space-between;
            align-items: center;

            border-bottom: 1px solid #373A43;

            body.light & {
                border-color: #E2E2E2;
            }
            &__left {
                color: #FFF;
                font-family: Inter;
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                body.light & {
                    color: black;
                }
            }
            &__right {
                width: 30px;
                height: 30px;
                background-color: $button-primary;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 3px;

                cursor: pointer;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: $button-primary-hover;
                }
            }
        }
        &__content {
            width: 100%;
            height: 100%;
            overflow: auto;
            color: white;

            padding: 15px 0;

            body.light & {
                color: black;
            }
            &__checkbox {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
            }
            &__split {
                display: grid;
                grid-template-rows: auto;
                grid-template-columns: 1fr 180px;
                align-items: center;
                gap: 10px;
                margin-top: 20px;

                & > .component__filters__filterColumn {
                    padding: 10px 5px;
                    height: 37px;
                }
                & > .route__user__offers__settings__wrap__content__input {
                    margin: 0;
                }
            }
            &__input {
                width: 100%;
                height: auto;
                padding: 10px 5px;
                border-radius: 5px;
                margin-top: 10px;

                display: flex;
                justify-content: center;
                align-items: center;
                
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                
                color: #FFF;
                background-color: $button-primary;

                transition: background-color 0.3s ease;
                cursor: pointer;

                &:hover {
                    background-color: $button-primary-hover;
                }
            }
            &__info {
                margin-top: 20px;

                color: #B2B3BD;
                font-family: Inter;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                text-align: center;
            }
        }
    }
    &__add {
        &__profileDropdown {
            display: flex;
            align-items: center;
            gap: 10px;

            & > img {
                width: 20px;
                height: 20px;

                border-radius: 50%;
            }
        }
    }
}