.route__reports__marketing {
    width: 100%;
    height: 100%;

    &__calculator {
        & .genericModal__wrap__input > p {
            line-height: 30px;
            font-size: 18px;
        }
        & input {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 19px;

            outline: none;
            color: white;
            background: #373A43;
            border-radius: 5px;
            border: 1px solid gray;
            opacity: 0.5;

            transition: border 0.3s ease, opacity 0.3s ease;

            &:focus {
                border: 1px solid #6C5DD3;
            }
            &:hover, &:focus {
                opacity: 1;
            }
        }
    }
    &__filters {
        width: 100%;
        height: auto;

        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        column-gap: 30px;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 20px;

        &--adsets {
            grid-template-columns: repeat(3, 1fr) !important;

            &.route__reports__marketing__filters--additional {
                grid-template-columns: repeat(4, 1fr) !important;
            }
        }
        &--additional {
            grid-template-columns: 2fr 1fr 1fr;
        }
    }
    &__kpi {
        width: 100%;
        height: auto;
        margin-bottom: 32px;

        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: auto;
        grid-auto-rows: auto;
        row-gap: 20px;

        &__item {
            width: 100%;
            height: 120px;

            display: flex;
            flex-direction: column;
            justify-content: space-between;

            padding: 24px 40px;
            border: 1px solid #373A43;

            &__top {
                color: #FFF;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
            }
            &__bottom {
                color: #FFF;
                font-family: Inter;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 1px;
            }
        }
    }
    &__chart {
        width: 100%;
        height: auto;
        position: relative;
        margin-bottom: 32px;

        &__spinner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            display: flex;
            justify-content: center;
            align-items: center;

            background-color: rgba(35, 38, 48, 0.8);

            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s ease;

            &--active {
                opacity: 1;
                pointer-events: all;
            }
        }
    }

}