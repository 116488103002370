.route__user__additionalSpents {
    width: 100%;
    height: auto;

    &__wrap {
        width: 100%;
        height: auto;
        margin-bottom: 20px;

        opacity: 0;

        &__spent {
            width: 100%;
            height: auto;
            
            &__filters {
                width: 100%;
                height: auto;
                margin-bottom: 20px;
        
                display: flex;
                justify-content: space-between;
                align-items: center;
        
                &__left, &__right {
                    width: auto;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }
                &__left {
                    &__radio {
                        width: auto;
                        height: auto;
        
                        display: flex;
                        align-items: center;
                        gap: 5px;
        
                        cursor: pointer;
                        color: white;
                        body.light & {
                            color: black;
                        }
                    }
                }
            }
        }
    }
}