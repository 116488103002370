@import "../../../scss/mixins.scss";

.modal__importCampaignsFromAccount {
    width: 100%;
    height: 100%;
    position: relative;

    cursor: default;
    background-color: rgba(35, 38, 48, 0.8);

    &__wrap {
        position: absolute;
        width: 450px;
        height: 100%;
        top: 0;
        right: -100%;
        padding: 20px;

        display: grid;
        grid-template-rows: auto 1fr;
        grid-template-columns: 1fr;

        background-color: #2C2E38;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
        
        transition: width 0.3s ease;

        body.light & {
            background-color: #fff;
        }
        &__top {
            width: 100%;
            height: auto;
            padding-bottom: 25px;

            display: flex;
            justify-content: space-between;
            align-items: center;

            border-bottom: 1px solid #373A43;

            body.light & {
                border-color: #E2E2E2;
            }
            &__left {
                color: #FFF;
                font-family: Inter;
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                body.light & {
                    color: black;
                }
            }
            &__right {
                background-color: $button-primary;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 3px;

                cursor: pointer;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: $button-primary-hover;
                }
            }
        }
        &__content {
            width: 100%;
            height: 100%;
            overflow: auto;
            color: white;

            padding: 15px 0;

            body.light & {
                color: black;
            }
            &__progress {
                position: relative;
                width: 100%;
                height: 10px;
                border: 1px solid gray;
                margin: 20px 0;
                display: flex;
                flex-direction: column;
                gap: 10px;

                & > div {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 0px;
                    height: 100%;
                    background-color: #5A49CE;

                    transition: width 0.3s linear;
                }
                & > span {
                    color: gray;
                    font-size: 12px;
                    margin-top: 10px;
                }
            }
        }
    }
}