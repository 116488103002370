.styledComponent__input {
    width: 100%;

    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    border-radius: 5px;
    background: #373A43;
    outline: none;
    padding: 17px 19px;
    border: 1px solid transparent;

    transition: border-color 0.3s ease;

    &:hover, &:focus {
        border-color: #6C5DD3 !important;
    }
    &[data-error="1"] {
        border-color: #FF754C;
    }
    body.light & {
        background-color: #fff;
        border-color: #E2E2E2;
        color: black;

        &:hover, &:focus {
            border-color: #6C5DD3 !important;
        }
        &[data-error="1"] {
            border-color: #FF754C;
        }
    }
}