.route__automations__fbAdGen {
    width: 100%;
    height: auto;

    color: white;
    body.light & {
        color: black;
    }
    &__title {
        margin-bottom: 20px;
    }
    &__configItems {
        width: 100%;
        height: auto;
        margin-top: 20px;
        margin-bottom: 20px;

        display: grid;
        grid-template-columns: repeat(auto-fit, 300px);
        grid-template-rows: auto;
        grid-auto-rows: auto;
        gap: 20px;
        overflow: visible;

        & > div {
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            flex-direction: column;
            gap: 10px;

            & > *:first-child {
                font-size: 14px;
            }
            & *:last-child {
                height: 54px;
            }
        }
    }
    &__text {
        display: flex;
        flex-direction: column;
        gap: 10px;

        & > textarea {
            width: 100%;
            max-width: 940px;
            height: 300px;
            resize: none;

            padding: 18px 20px;
            resize: vertical;

            font-family: "Inter";
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 19px;

            outline: none;
            color: white;
            background: #373a43;
            border-radius: 5px;
            border: 1px solid transparent;

            transition: border 0.3s ease;

            body.light & {
                color: black;
                background-color: #e9e8e8;

                &:focus {
                    border: 1px solid #6C5DD3;
                }
            }
            &:focus {
                border: 1px solid #6c5dd3;
            }
        }
    }
}