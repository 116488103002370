.route__mediaBuyer {
    width: 100%;
    height: auto;
    color: white;

    body.light & {
        color: black;
    }
    & > h3 {
        margin-bottom: 20px;
    }
    &__list {
        width: 100%;
        height: auto;

        display: flex;
        align-items: center;
        gap: 10px;

        &__manager {
            padding: 10px 18px;
            
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.94px;
            text-align: left;
            color: #808191;
            border-radius: 5px;
    
            border: 1px solid #6C5DD3;
            cursor: pointer;
            transition: background-color 0.3s ease, color 0.3s ease;

            &:hover {
                background-color: #6C5DD3;
                color: white;
            }
        }
    }
}