.route__admin__reportedURLs {
    width: 100%;
    height: auto;

    &__url {
        margin-bottom: 10px;

        display: flex;
        flex-direction: column;
        justify-content: center;

        & > span:last-child {
            color: gray;
        }
    }
}