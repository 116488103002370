@import "../../../scss/mixins.scss";

.route__admin__dataStore {
    width: 100%;
    height: auto;

    color: black;

    body.dark & {
        color: white;
    }
    &__head {
        display: grid;
        grid-template-columns: 1fr 200px;
        column-gap: 20px;
        margin-bottom: 40px;

        &--active {
            grid-template-columns: 1fr repeat(2, 200px);
        }
    }
    &__btn {
        width: 20px;
        height: 20px;

        background-size: 100% 100%;
        cursor: pointer;
        
        @include button-hover-animation(24px);

        body.light & {
            filter: invert(1) hue-rotate(180deg);
        }
    }
}