@import "../../../scss/mixins.scss";

.route__userCpf {
    width: 100%;
    height: 100%;
    overflow: hidden;

    display: grid;
    grid-template-rows: max-content 1fr;
    grid-template-columns: 1fr;

    &__filters {
        position: sticky;
        top: 0;
        left: 0;
        margin-bottom: 30px;

        display: grid;
        grid-template-columns: repeat(5, 1fr) 52px;
        gap: 20px;

        z-index: 10;

        & > * {
            height: 100%;
        }
    }
    & .customComponents__table__data, & .customComponents__table__head {
        & > p {
            position: relative;
        }
        & > p::after {
            content: "";
            position: absolute;
            top: -15px;
            right: -20px;
            width: 1px;
            height: 100%;
            background: #3f3f3f;
            z-index: 99;
            padding: 15px 0;

            body.light & {
                background-color: #D0D0D0;
            }
        }
        & > p:last-child::after {
            display: none;
        }
    }
    &__integration {
        display: flex;
        align-items: center;
        gap: 5px;

        & > img {
            width: 16px;
            height: 16px;
            border-radius: 50%;
        }
    }
}

.route__userCpf__details {
    width: 100%;
    height: 100%;
    position: relative;

    cursor: default;
    background-color: rgba(35, 38, 48, 0.8);

    &__wrap {
        position: absolute;
        width: calc(100% - 86px);
        height: 100%;
        top: 0;
        right: -100%;
        padding: 20px;

        display: grid;
        grid-template-rows: auto 1fr;
        grid-template-columns: 1fr;

        background-color: #2C2E38;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);

        body.light & {
            background-color: #fff;
        }
        &__top {
            width: 100%;
            height: auto;
            padding-bottom: 25px;

            display: flex;
            justify-content: space-between;
            align-items: center;

            border-bottom: 1px solid #373A43;

            body.light & {
                border-color: #E2E2E2;
            }
            &__left {
                color: #FFF;
                font-family: Inter;
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                body.light & {
                    color: black;
                }
            }
            &__right {
                background-color: $button-primary;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 3px;

                cursor: pointer;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: $button-primary-hover;
                }
            }
        }
        &__content {
            width: 100%;
            height: 100%;
            overflow: auto;
            color: white;

            padding: 15px 0;

            body.light & {
                color: black;
            }
            &__kpi {
                width: 100%;
                height: auto;
                margin-bottom: 20px;
        
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-template-rows: auto;
                grid-auto-rows: auto;
        
                &__item {
                    width: 100%;
                    height: 90px;
        
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
        
                    padding: 20px;
                    border: 1px solid #4f535f;
        
                    body.light & {
                        border-color: #adadad;
                    }
                    &--noBorder {
                        border-top: none;
                    }
                    &__top {
                        color: #FFF;
                        font-family: Inter;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 16px;
        
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
        
                        body.light & {
                            color: black;
                        }
                    }
                    &__bottom {
                        color: #FFF;
                        font-family: Inter;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        letter-spacing: 1px;
        
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        
                        flex-wrap: wrap;
        
                        & span {
                            margin: 3px 0;
                        }
                        body.light & {
                            color: black;
                        }
                    }
                }
            }
            &__description {
                margin-bottom: 20px;
                
                & > span {
                    font-weight: bold;
                }
            }
        }
    }
    &__add {
        &__profileDropdown {
            display: flex;
            align-items: center;
            gap: 10px;

            & > img {
                width: 20px;
                height: 20px;

                border-radius: 50%;
            }
        }
    }
}