@import "../../../scss/mixins.scss";

.route__user__accountManager {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;

    color: white;
    body.light & {
        color: black;
    }
    &__kpi {
        width: 100%;
        height: auto;
        margin-bottom: 32px;

        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;
        grid-auto-rows: auto;
        row-gap: 20px;

        &__item {
            width: 100%;
            height: 120px;

            display: flex;
            flex-direction: column;
            justify-content: space-between;

            padding: 24px 40px;
            border: 1px solid #373A43;

            &__top {
                color: #FFF;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
            }
            &__bottom {
                color: #FFF;
                font-family: Inter;
                font-size: 28px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 1px;
            }
        }
    }
    &__btn {
        padding: 8px 16px;

        font-family: Inter;
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;        

        border: 1px solid #6C5DD3B2;
        border-radius: 4px;
        cursor: pointer;

        transition: background-color 0.3s ease;

        &--gray {
            border-color: #5C7582;
        }
        &--red {
            border-color: #FF754CB2;
        }
        &:hover {
            background-color: #625c5c45;
        }
    }
    &__split {
        width: 100%;
        height: auto;
        margin-bottom: 20px;

        display: grid;
        grid-template-columns: repeat(3, 1fr) max-content;
        grid-template-rows: auto;
        gap: 20px;

        & > * {
            height: 52px;
        }
    }
}

.route__user__accountManager__spentChange {
    width: 100%;
    height: 100%;
    position: relative;

    cursor: default;
    background-color: rgba(35, 38, 48, 0.8);

    &__wrap {
        position: absolute;
        width: calc(100% - 86px);
        height: 100%;
        top: 0;
        right: -100%;
        padding: 20px;

        display: grid;
        grid-template-rows: auto 1fr;
        grid-template-columns: 1fr;

        background-color: #2C2E38;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);

        body.light & {
            background-color: #fff;
        }
        &__top {
            width: 100%;
            height: auto;
            padding-bottom: 25px;

            display: flex;
            justify-content: space-between;
            align-items: center;

            border-bottom: 1px solid #373A43;

            body.light & {
                border-color: #E2E2E2;
            }
            &__left {
                color: #FFF;
                font-family: Inter;
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                body.light & {
                    color: black;
                }
            }
            &__right {
                background-color: $button-primary;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 3px;

                cursor: pointer;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: $button-primary-hover;
                }
            }
        }
        &__content {
            width: 100%;
            height: 100%;
            overflow: auto;
            color: white;

            display: flex;
            flex-direction: column;
            gap: 20px;

            padding: 15px 0;
        }
    }
}

.route__user__accountManager__activityAds {
    width: 100%;
    height: 100%;
    position: relative;

    cursor: default;
    background-color: rgba(35, 38, 48, 0.8);

    &__wrap {
        position: absolute;
        width: calc(100% - 86px);
        height: 100%;
        top: 0;
        right: -100%;
        padding: 20px;

        display: grid;
        grid-template-rows: auto 1fr;
        grid-template-columns: 1fr;

        background-color: #2C2E38;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);

        body.light & {
            background-color: #fff;
        }
        &__top {
            width: 100%;
            height: auto;
            padding-bottom: 25px;

            display: flex;
            justify-content: space-between;
            align-items: center;

            border-bottom: 1px solid #373A43;

            body.light & {
                border-color: #E2E2E2;
            }
            &__left {
                color: #FFF;
                font-family: Inter;
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                body.light & {
                    color: black;
                }
            }
            &__right {
                background-color: $button-primary;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 3px;

                cursor: pointer;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: $button-primary-hover;
                }
            }
        }
        &__content {
            width: 100%;
            height: 100%;
            overflow: auto;
            color: white;

            padding: 15px 0;

            &__ad {
                display: flex;
                flex-direction: column;
                gap: 5px;
            }
        }
    }
}
.route__user__accountManager__mediaBuyerCampaigns {
    &__icons {
        display: flex;
        align-items: center;
        gap: 20px;

        &__icon {
            width: 16px;
            height: 16px;

            display: flex;
            justify-content: center;
            align-items: center;

            cursor: pointer;
            
            @include button-hover-animation(24px);

            & > img {
                width: auto;
                max-width: 100%;
                height: auto;
                max-height: 100%;

                body.light & {
                    filter: invert(1);
                }
            }
        }
    }
}