@import "../../../scss/mixins.scss";

.modals__adsAgeBreakdownModal {
    all: initial;
    display: block;

    width: 100%;
    height: 100%;
    cursor: default;
    position: relative;
    background-color: rgba(35, 38, 48, 0.8);

    &__wrap {
        position: absolute;
        top: 0;
        right: -100%;
        width: calc(100% - 84px);
        height: 100vh;

        background-color: #2c2e38;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);

        &__header {
            width: 100%;
            height: 102px;
            padding: 0 50px;
            padding-bottom: 25px;

            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            border-bottom: 1px solid #373a43;

            &__left {
                color: #fff;
                font-family: Inter;
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                & > span {
                    color: $button-primary;
                }
            }
            &__right {
                width: 30px;
                height: 30px;
                cursor: pointer;

                @include button-hover-animation(34px);
            }
        }
        &__content {
            width: 100%;
            height: 100%;

            padding: 25px 50px;

            display: grid;
            grid-template-columns: 1fr auto;
            grid-template-rows: auto;
            overflow: hidden;
            max-height: calc(100% - 102px);
            
            transition: all 0.3s ease;

            &__left {
                width: 100%;
                height: 100%;
                overflow: scroll;
            }
        }
    }
}