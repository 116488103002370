@import "../../../../scss/mixins.scss";

.route__socialManager__engage {
    width: 100%;
    height: auto;
    max-height: 100%;
    overflow: auto;
    padding: 0 25px;
    padding-bottom: 10px;

    & .customComponents__table__data__groupNewline {
        min-width: 0;
    }
    &__links {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #13213C;
        word-break: break-all;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;
        flex-wrap: wrap;
        width: 100%;

        & > span {
            align-items: center;
            background-color: #434295;
            border-radius: 5px;
            display: flex;
            font-size: 12px;
            height: 20px;
            justify-content: center;
            padding: 10px;
            width: auto;
            background-color: rgb(55, 58, 67);

            body.light & {
                color: white;
            }
        }
        & > span:first-child {
            font-weight: normal;
            color: white;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            background-color: transparent;

            body.light & {
                color: black;
            }
        }
    }
    &__kpi {
        display: grid;
        grid-template-columns: repeat(auto-fit, 250px);
        grid-template-rows: auto;
        grid-auto-rows: auto;

        &__item {
            width: 100%;
            height: auto;
            padding: 24px 40px;
            border: 1px solid #373A43;

            display: flex;
            flex-direction: column;
            gap: 20px;

            body.light & {
                border-color: #E2E2E2;
            }
            &__top {
                color: #FFF;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px; /* 114.286% */

                body.light & {
                    color: black;
                }
            }
            &__bottom {
                color: #FFF;
                font-family: Inter;
                font-size: 28px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 1px;

                body.light & {
                    color: black;
                }
            }
        }
    }
    &__head {
        position: sticky;
        top: 94px;
        z-index: 20;

        width: 100%;
        height: auto;
        padding: 30px 0;

        display: flex;
        justify-content: space-between;
        align-items: center;

        background-color: #232630;
         
        body.light & {
            background-color: #f8f9fa;
        }
        &__left {
            width: auto;
            height: auto;

            display: flex;
            justify-content: flex-start;
            align-items: center;

            &__showAll {
                display: flex;
                align-items: center;
                gap: 10px;
                color: white;
            }
            &__tabs {
                width: auto;
                height: auto;

                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;

                &__tab {
                    width: auto;
                    height: 60px;
                    padding: 9px 17px;

                    display: flex;
                    align-items: center;

                    color: #FFF;
                    font-size: 14px;
                    font-family: Inter;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    border-radius: 5px; 
                    border: 1px solid transparent;
                    cursor: pointer;
                    
                    transition: background-color 0.3s ease, border 0.3s ease;

                    &--active {
                        background-color: #6C5DD3;
                        border-color: #6C5DD3;
                        cursor: default;
                    }
                    &:hover {
                        border-color: #6C5DD3;
                    }
                }
            }
            &__selected {
                width: auto;
                max-width: 0;
                height: auto;
                padding: 10px 0;
                margin-left: 0px;
                overflow: hidden;
                word-break: keep-all;
                white-space: nowrap;

                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;

                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                
                color: #FFFFFF;
                background: transparent;
                border: 1px solid transparent;
                border-radius: 5px;

                transition: padding 0.3s ease, max-width 0.3s ease, border 0.3s ease, margin 0.3s ease, background 0.3s ease;

                &__btn {
                    width: 18px;
                    height: 18px;
                    background-size: 100%;
                    position: relative;
                    cursor: pointer;

                    @include button-hover-animation(22px);
                }
                &--active {
                    max-width: 300px;
                    padding: 10px 18px;
                    border-color: #6C5DD3;
                    background-color: #232630;
                    margin-left: 10px;
                }
            }
        }
        &__right {
            width: auto;
            height: auto;

            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 30px;

            &__btn {
                width: auto;
                height: auto;
                padding: 10px 16px;

                display: flex;
                justify-content: center;
                align-items: center;
                gap: 8px;

                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                
                color: #FFFFFF;
                background-color: $button-primary;
                border-radius: 5px;
                cursor: pointer;

                transition: background-color 0.3s ease;

                &:hover {
                    background-color: $button-primary-hover;
                }
                & > img {
                    width: 20px;
                    height: 20px;
                }
            };
        }
    }
}