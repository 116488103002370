@import "../../../scss/mixins.scss";

.route__teamLead__userGroups {
    width: 100%;
    height: auto;

    &__tableWrap {
        width: auto;
        max-width: 100%;
        height: auto;
        padding-bottom: 5px;
        overflow-y: auto;
        filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.25));
    }

    &__btns {
        width: 100%;
        height: auto;
        margin-bottom: 20px;

        display: flex;
        justify-content: space-between;

        &__left {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            &__selected {
                width: auto;
                max-width: 0;
                height: auto;
                padding: 10px 0;
                overflow: hidden;
                word-break: keep-all;
                white-space: nowrap;

                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;

                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                
                color: #FFFFFF;
                background: #232630;
                border: 1px solid transparent;
                border-radius: 5px;

                transition: padding 0.3s ease, max-width 0.3s ease, border 0.3s ease;

                body.light & {
                    background-color: #f8f9fa;
                    color: black;
                }
                &__btn {
                    width: 18px;
                    height: 18px;
                    background-size: 100%;
                    position: relative;
                    cursor: pointer;

                    @include button-hover-animation(22px);

                    body.light & {
                        filter: invert(1) hue-rotate(180deg);
                    }
                }
                &--active {
                    max-width: 300px;
                    padding: 10px 18px;
                    border-color: #6C5DD3;
                }
            }
        }
        &__right {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;

            &__btn {
                width: auto;
                height: auto;
                padding: 10px 16px;
    
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 8px;
    
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                
                color: #FFFFFF;
                background-color: $button-primary;
                border-radius: 5px;
                cursor: pointer;
    
                transition: background-color 0.3s ease;
    
                &:hover {
                    background-color: $button-primary-hover;
                }
                & > img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
    &__accounts {
        display: flex;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;

        & > p {
            color: #FFF;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;

            body.light & {
                color: black;
            }
        }
        & > span {
            align-items: center;
            background-color: #434295;
            color: white;
            border-radius: 5px;
            display: flex;
            font-size: 12px;
            height: 20px;
            justify-content: center;
            margin: 0 5px;
            padding: 10px;
            width: auto;
        }
    }
    &__add {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: default;

        background: rgba(35, 38, 48, 0.8);

        &__wrap {
            width: 500px;
            height: auto;
            max-height: 100%;
            padding: 40px;
            position: relative;
            overflow: auto;

            background: #2C2E38;
            border-radius: 10px;

            body.light & {
                background-color: #fff;
            }
            &__spinner {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                display: flex;
                justify-content: center;
                align-items: center;
                background-color: rgba(38, 41, 53, 0.8);
                opacity: 0;
                pointer-events: none;

                transition: opacity 0.3s ease;

                body.light & {
                    background-color: rgba(38, 41, 53, 0.3);
                }
                & svg {
                    pointer-events: none;
                }
                &--active {
                    pointer-events: all;
                    opacity: 1;
                }
            }
            &__head {
                width: 100%;
                height: auto;
                margin-bottom: 24px;

                display: flex;
                justify-content: space-between;
                align-items: center;

                &__left {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                    
                    color: #FFFFFF;

                    body.light & {
                        color: black;
                    }
                }
                &__right {
                    width: 30px;
                    height: 30px;
                    background-size: contain;
                    position: relative;

                    @include button-hover-animation(34px);

                    body.light & {
                        filter: invert(1) hue-rotate(180deg);
                    }
                }
            }
            &__input {
                width: 100%;
                height: auto;
                margin-bottom: 20px;

                display: flex;
                flex-direction: column;
                gap: 14px;

                & > p {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    
                    color: #FFFFFF;

                    body.light & {
                        color: black;
                    }
                }
                & > input, & > textarea {
                    width: 100%;
                    height: 54px;
                    padding: 0 20px;

                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 19px;
                    
                    color: #FFFFFF;
                    background: #373A43;
                    border: 1px solid #373A43;
                    outline: none;
                    border-radius: 5px;

                    transition: border 0.3s ease;

                    body.light & {
                        background-color: #fff;
                        color: black;
                        border-color: #E2E2E2;   
                    }
                    &:focus, body.light &:focus {
                        border-color: #6C5DD3;
                    }
                }
                &--error > input {
                    border-color: #FF754C;

                    body.light & {
                        border-color: #FF754C;
                    }
                }
                & > textarea {
                    padding-top: 18px;
                    padding-bottom: 18px;
                    height: 200px;
                    resize: vertical;

                    body.light & {
                        background-color: #e9e8e8;
                    }
                }
            }
            &__users {
                width: 100%;
                height: auto;
                margin-bottom: 20px;

                display: flex;
                flex-direction: row;
                gap: 14px;
                align-items: center;

                & > p {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    
                    color: #FFFFFF;

                    body.light & {
                        color: black;
                    }
                }
                &__btn {
                    width: auto;
                    height: auto;
                    padding: 5px 10px;

                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 19px;
                    border-radius: 5px;

                    color: white;
                    background-color: $button-primary;
                    cursor: pointer;

                    transition: background-color 0.3s ease;

                    &:hover {
                        background-color: $button-primary-hover;
                    }
                }
            }
            &__checkbox {
                width: auto;
                height: auto;
                margin-bottom: 20px;

                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
            }
            &__buttons {
                width: 100%;
                height: auto;

                display: flex;
                justify-content: flex-end;
                gap: 20px;

                &__btn {
                    width: auto;
                    height: 43px;
                    padding: 0px 40px;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    
                    color: #FFFFFF;
                    background-color: $button-primary;
                    border-radius: 5px;
                    cursor: pointer;

                    transition: background-color 0.3s ease;

                    &:hover {
                        background-color: $button-primary-hover;
                    }
                    &--secondary {
                        background-color: $button-secondary;

                        &:hover {
                            background-color: $button-secondary-hover;
                        }
                    }
                }
            }
            &__infoP {
                margin-top: 20px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #FF450D;
                opacity: 0;
                transition: opacity 0.3s ease;
            }
        }
    }
    &__groupMembers {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: default;

        background: rgba(35, 38, 48, 0.8);

        &__wrap {
            width: 600px;
            height: auto;
            max-height: 100%;
            padding: 40px;
            position: relative;
            overflow: auto;

            background: #2C2E38;
            border-radius: 10px;
            box-shadow: 0 0 4px 2px rgba(0,0,0,0.25);

            body.light & {
                background-color: #fff;
                color: white;
            }
            &__tableImg {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;

                & > img {
                    width: 24px;
                    height: 24px;
                }
            }
            &__drop {
                width: 100%;
                height: auto;
                margin-bottom: 20px;

                display: grid;
                grid-template-columns: 1fr max-content;
                grid-template-rows: auto;
                column-gap: 20px;

                &__button {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px 15px;

                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;

                    color: #FFFFFF;
                    background-color: $button-primary;
                    cursor: pointer;
                    border-radius: 5px;

                    transition: background-color 0.3s ease;

                    &:hover {
                        background-color: $button-primary-hover;
                    }
                }
            }
        }
    }
}