@import "../../../scss/mixins.scss";

.component__filters__filterColumn {
    width: auto;
    height: auto;
    padding: 10px 16px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    
    color: #FFFFFF;
    background-color: $button-primary;
    border-radius: 5px;
    cursor: pointer;

    transition: background-color 0.3s ease;

    &:hover {
        background-color: $button-primary-hover;
    }

    &__sidePanel {
        width: 100%;
        height: 100%;
        position: relative;

        display: flex;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;

        background: rgba(35, 38, 48, 0.8);

        &__wrap {
            position: absolute;
            top: 0;
            left: unset;
            right: -100%;
            width: 462px;
            height: 100%;
            overflow: auto;
            overflow-x: hidden;
            padding: 30px 0px;
            cursor: default;

            background: #2C2E38;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);

            body.light & {
                background-color: #fff;
            }
            &__head {
                width: 100%;
                height: auto;
                padding: 0 20px;

                padding-bottom: 15px;

                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;

                &::after {
                    content: '';
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background-color: #373A43;

                    body.light & {
                        background-color: #E2E2E2;
                    }
                }
                &__left {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 27px;
                    
                    color: #FFFFFF;

                    body.light & {
                        color: black;
                    }
                }
                &__right {
                    width: 30px;
                    height: 30px;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    background-color: $button-primary;
                    cursor: pointer;
                    
                    transition: background-color 0.3s ease;

                    &:hover {
                        background-color: $button-primary-hover;
                    }
                }
            }
            & .components__filters__filterBySearch {
                margin-top: 30px;
                width: calc(100% - 40px);
                margin-bottom: 20px;
                margin-left: 20px;
            }
            &__buttons {
                width: 100%;
                height: auto;
                margin-bottom: 30px;
                padding: 0 20px;

                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: auto;
                column-gap: 12px;

                &__button {
                    width: 100%;
                    height: 44px;
                    padding: 0 16px;

                    display: flex;
                    align-items: center;
                    gap: 8px;

                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    
                    color: #FFFFFF;
                    background-color: $button-primary;
                    border-radius: 5px;
                    cursor: pointer;

                    transition: background-color 0.3s ease;

                    &:hover {
                        background-color: $button-primary-hover;
                    }
                }
            }
            &__columns {
                width: 100%;
                height: auto;

                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;

                &__item {
                    width: 100%;
                    height: auto;
                    padding: 12px 20px;
                    padding-bottom: 10px;

                    display: grid;
                    grid-template-rows: auto;
                    grid-template-columns: 24px 1fr repeat(2, 20px);
                    column-gap: 20px;
                    
                    background-color: transparent;
                    border-bottom: 2px solid transparent;

                    transition: background-color 0.3s ease, border-color 0.3s ease;

                    &:hover {
                        background-color: #454856;

                        body.light & {
                            background-color: #F5F5F5;
                        }
                    }
                    & > img {
                        width: 20px;
                        height: 20px;
                    }
                    & > p {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 18px;
                        
                        color: #FFFFFF;

                        body.light & {
                            color: black;
                        }
                    }
                    &.dragging {
                        background-color: #454856;

                        body.light & {
                            background-color: #F5F5F5;
                        }
                    }
                    &.hint {
                        border-bottom-color: $button-primary-hover;
                    }
                }
            }
            &__spinner {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                display: flex;
                justify-content: center;
                align-items: center;

                background-color: rgba(35, 38, 48, 0.8);
                opacity: 0;
                pointer-events: none;
                z-index: 99;

                transition: opacity 0.3s ease;

                &--active {
                    pointer-events: all;
                    opacity: 1;
                }
            }
            &__presets {
                width: 100%;
                height: auto;
                margin-bottom: 30px;
                padding: 12px 20px;

                border-top: 1px solid #373A43;
                border-bottom: 1px solid #373A43;

                body.light & {
                    border-color: #E2E2E2;
                }
                &__text {
                    margin-bottom: 20px;

                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    &__left {
                        color: #FFF;
                        font-family: Inter;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 140%;
                        letter-spacing: 0.7px;
                    }
                    &__right {
                        &__button {
                            width: 100%;
                            height: 44px;
                            padding: 0 16px;
        
                            display: flex;
                            align-items: center;
                            gap: 8px;
        
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 17px;
                            
                            color: #FFFFFF;
                            background-color: $button-secondary;
                            border-radius: 5px;
                            cursor: pointer;
        
                            transition: background-color 0.3s ease;
        
                            & > img {
                                width: 24px;
                                height: 24px;
                            }
                            &:hover {
                                background-color: $button-secondary-hover;
                            }
                        }
                    }
                }
                &__items {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    &__item {
                        width: 100%;
                        height: auto;

                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        background-color: $button-secondary;
                        border-radius: 5px;
                        font-size: 12px;
                        padding: 10px;

                        cursor: pointer;
                        transition: background-color 0.3s ease;

                        &:hover {
                            background-color: $button-secondary-hover;
                        }
                        &__name {
                            color: #FFF;
                            font-family: Inter;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 140%;
                            letter-spacing: 0.7px;
                        }
                        &__close {
                            width: 20px;
                            height: 20px;

                            background-size: 100% 100%;

                            @include button-hover-animation(30px);
                        }
                    }
                }
            }
        }
    }
}