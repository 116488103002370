@import "../../../scss/mixins.scss";

.route__user__integrations {
    width: 100%;
    height: auto;

    &__search {
        width: 100%;
        height: auto;

        margin-bottom: 30px;
    }
    &__list {
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: repeat(auto-fit, 280px);
        grid-template-rows: 350px;
        grid-auto-rows: 350px;
        column-gap: 40px;
        row-gap: 40px;

        &__item {
            width: 100%;
            height: 100%;

            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            border-radius: 16px;
            background: #242731;
            box-shadow: 0px 0px 25px 2px rgba(0, 0, 0, 0.14);

            body.light & {
                background-color: #fff;
            }
            &__img {
                width: 60px;
                height: 60px;
                margin-bottom: 24px;

                body.light & {
                    filter: invert(1) hue-rotate(180deg);
                }
            }
            &__head {
                margin-bottom: 20px;
                color: #FFF;
                font-size: 22px;
                font-family: Inter;
                font-weight: 600;
                letter-spacing: 0.5px;

                body.light & {
                    color: black;
                }
            }
            &__text {
                margin-bottom: 24px;
                color: #808191;
                font-size: 16px;
                font-family: Inter;
                font-weight: 500;
                line-height: 24px;
            }
            &__btns {
                width: 100%;
                height: auto;

                display: flex;
                justify-content: center;
                align-items: center;
                gap: 20px;

                &__btn {
                    width: 50px;
                    height: 50px;

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    border-radius: 50%;
                    background-color: #FF754C;
                    box-shadow: 0px 0px 12px transparent;

                    transition: box-shadow 0.3s ease;

                    &--info {
                        background-color: #504b73;
                    }
                    &--connected {
                        background-color: #7FBA7A;
                    }
                    &--reconnect {
                        background-color: #859000;
                    }
                    &:hover {
                        box-shadow: 0px 0px 12px $button-primary;
                    }
                }
            }
        }
    }
}