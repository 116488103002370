@import "../../../../scss/mixins.scss";

.route__user__sites__card {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  height: auto;
  min-height: 100%;
  padding: 24px;
  background: #242731;
  border-radius: 10px;
  filter: drop-shadow(0px 0px 36px rgba(0, 0, 0, 0.25));

  body.light & {
    background-color: #fff;
    color: black;
  }
  &__head {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-template-rows: auto;
    justify-content: space-between;

    margin-bottom: 10px;

    & .customComponents__buttonWithDropdown {
      max-height: 40px;
    }
    &__name {
      width: 100%;

      display: flex;
      align-items: center;

      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;

      body.light & {
        color: black;
      }
      & > p {
        max-width: 100%;
      }
    }

    &__status {
      display: flex;
      align-items: center;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 1px;
      color: #FFFFFF;

      body.light & {
        color: black;
      }
      p {
        margin-left: 10px;
      }

      &__notactive {
        display: flex;
        position: relative;
        overflow: hidden;

        &--moreinfo {
          margin-left: 14px;

          &:hover {
            cursor: pointer;
          }
        }

        &__info {
          width: 270px;
          height: auto;
          position: absolute;
          padding: 20px;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #808191;
          background: #2C2E38;
          box-shadow: 0px 0px 19px 5px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
          top: calc(100% + 13px);
          right: 0;
          transition: 0.3s ease;
          opacity: 0;
          z-index: 1;
        }
      }
    }
  }

  &__country {
    width: 100%;
    height: auto;

    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;

    color: #fff;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    
    body.light & {
      color: black;
    }
  }
  &__flags {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  &__url {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 20px;

    a {
      display: flex;
      width: 70%;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-decoration: none;
      color: #808191;
      word-break: break-all;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      /* number of lines to show */
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    img {
      width: 13px;
      height: 17px;

      &:hover {
        cursor: pointer;
      }

      body.light & {
        filter: invert(1) hue-rotate(180deg);
      }
    }
  }

  &__img {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin-top: auto;

    img {
      width: auto;
      height: 300px;
    }
  }

  &__analytics {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(3, 1fr);
    margin: 10px 0;
    margin-bottom: 20px;

    border-radius: 5px;
    border: 1px solid #515967;
    background: #2D313E;
    color: white;

    body.light & {
      background-color: #e9e8e8;
      color: black;
    }
    &__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 10px 0;

      & > span:first-child {
        color: #FFF;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;

        body.light & {
          color: black;
        }
      }
      & > span:last-child {
        color: #FFF;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1px;

        body.light & {
          color: black;
        }
      }
      &:not(:last-child) {
        border-right: 1px solid gray;

        body.light & {
          background-color: #E2E2E2;
        }
      }
    }
  }

  &__buttons {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;

    &__preview {
      background: $button-secondary;
      border-radius: 5px;
      padding: 12px 40px;
      margin-right: 20px;
      transition: 0.3s ease;

      &--script {
        padding: 12px;

        &>p>img {
          height: 100%;
          width: auto;
        }
      }

      &:hover {
        cursor: pointer;
        background: $button-secondary-hover;
      }
    }

    &__edit {
      background: $button-primary;
      padding: 12px 40px;
      transition: 0.3s ease;
      border-radius: 5px;

      &:hover {
        cursor: pointer;
        background: $button-primary-hover;
      }
    }
  }

  &__tracking {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;

    background: rgba(35, 38, 48, 0.8);

    &__wrap {
      width: 500px;
      height: auto;
      max-height: 100%;
      overflow: auto;
      padding: 40px;

      background: #2C2E38;
      box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.25);
      border-radius: 10px;

      body.light & {
        background: #fff;
      }
      &__head {
        width: 100%;
        height: auto;

        display: flex;
        justify-content: space-between;
        align-items: center;

        &__left {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
          letter-spacing: 1px;

          color: #FFFFFF;

          body.light & {
            color: black;
          }
        }

        &__right {
          width: 30px;
          height: 30px;
          position: relative;
          cursor: pointer;

          @include button-hover-animation(34px);

          body.light & {
            filter: invert(1) hue-rotate(180deg);
          }
        }
      }

      &__code {
        width: 100%;
        height: auto;
        min-height: 250px;
        padding: 20px;
        margin-top: 20px;
        overflow: auto;
        overflow-wrap: normal;
        white-space: pre;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        border: none;
        outline: none;

        color: white;
        background: #373A43;
        border-radius: 5px;

        .string { color: #8bff8b; }
        .number { color: darkorange; }
        .boolean { color: #c3c3ff; }
        .null { color: #f78af7; }
        .key { color: #fd8a8a; }

        body.light & {
          background-color: #e9e8e8;
          color: black;

          .string { color: green; }
          .number { color: darkorange; }
          .boolean { color: #9f9fcf; }
          .null { color: #a35aa3; }
          .key { color: #7e4040; }
        }
      }
    }
  }
}