.route__dataImports__midasAds {
    width: 100%;
    height: auto;

    color: black;

    body.dark & {
        color: white;
    }
    &__line {
        width: 100%;
        height: 2px;
        margin: 20px 0;
        background-color: gray;
    }
    &__import {
        width: 100%;
        height: auto;


        display: flex;
        flex-direction: column;
        gap: 10px;

        & > p {
            display: grid;
            grid-template-columns: max-content 1fr;
            grid-template-rows: auto;
            align-items: center;
            gap: 10px;
        }
        &__pill {
            align-items: center;
            background-color: #1B1D21;
            color: white;
            display: flex;
            font-size: 16px;
            justify-content: flex-start;
            padding: 10px;
            margin-bottom: 5px;
            width: auto;

            cursor: pointer;
            transition: background-color 0.3s ease;
            
            &:hover {
                background-color: #0D0F11;
            }
        }
    }
}